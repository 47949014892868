import { Box, Button, Dialog, IconButton, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { S, D, pipe } from "@mobily/ts-belt";
import { CloseRounded } from "@material-ui/icons";
import PropTypes from "prop-types";
import * as React from "react";
import { useCurrentUser } from "../hooks";
import { Form } from "../theme";
import { realisticConfetti } from "../util";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledForm = styled("form")(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.9)",
  backdropFilter: "blur(10px)",
  borderRadius: theme.spacing(2),
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  padding: theme.spacing(4, 8),
  display: "flex",
  alignItems: "center",
  position: "relative",
  justifyContent: "center",
  maxWidth: 522,
  width: "100%",

  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(3, 3),
    "&.MuiDialog-paper": {
      margin: theme.spacing(1),
    },
  },

  "& .iconButton": {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },

  '& a[href="/privacy-policy"]': {
    color: "inherit",
  },
}));

const modalTimeout = 1000 * 10; // 10 seconds

function EmailModal({ onClose, checkBlocked }) {
  const timerRef = React.useRef();
  const didSubmitRef = React.useRef(false);
  const [transitionActive, setTransitionActive] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const transitionEnd = React.useCallback(() => {
    if (!didSubmitRef.current) {
      window
        .fetch("/api/newsletterEmail/closeModal", {
          body: JSON.stringify({}),
          headers: { "content-type": "application/json" },
          method: "POST",
        })
        .catch(console.error);
    }

    onClose(); // remove dialog from ui
  }, [onClose]);

  const closeDialog = () => {
    setTransitionActive(false);
  };

  const onSubmit = async (evt) => {
    evt.preventDefault();

    const body = pipe(
      Object.fromEntries(new FormData(evt.target)),
      D.map(S.trim),
      D.merge({
        source: "email_modal",
        location: window.location.toString(),
      }),
    );

    setLoading(true);

    window
      .fetch("/api/newsletterEmail", {
        body: JSON.stringify(body),
        headers: { "content-type": "application/json" },
        method: "POST",
      })
      .then(() => realisticConfetti())
      .catch(console.error)
      .finally(() => {
        didSubmitRef.current = true;
        setLoading(false);
        setTransitionActive(false);
      });
  };

  React.useEffect(() => {
    const ref = setTimeout(() => {
      if (checkBlocked()) onClose(); // dialog not visible, but in memory
      else setTransitionActive(true);
    }, [modalTimeout]);

    timerRef.current = ref;

    return () => {
      clearTimeout(ref);
    };
  }, [checkBlocked, onClose]);

  return (
    <Dialog
      open={transitionActive}
      onClose={closeDialog}
      PaperComponent={StyledForm}
      PaperProps={{ onSubmit }}
      TransitionComponent={Transition}
      transitionDuration={300}
      TransitionProps={{ onExited: transitionEnd }}
    >
      <IconButton className="iconButton" onClick={closeDialog}>
        <CloseRounded />
      </IconButton>

      <Typography gutterBottom align="center" variant="h4">
        Get First Access
      </Typography>

      <Typography
        align="center"
        color="textSecondary"
        gutterBottom
        variant="body1"
      >
        Be the very first to know about new products and Talent on Streamily
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        gridGap="12px"
        maxWidth={404}
        pb={1}
        pt={2}
        width="100%"
      >
        <Form.Input
          noAsterisk
          fullWidth
          label="Email Address"
          name="email"
          required
          type="email"
          variant="outlined"
          disabled={loading}
        />

        <Button
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={loading}
        >
          Sign Up
        </Button>

        <Typography variant="caption" color="textSecondary" align="center">
          By entering your email address, you agree to our{" "}
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </Typography>
      </Box>
    </Dialog>
  );
}

EmailModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  checkBlocked: PropTypes.func.isRequired,
};

const emailModalContext = React.createContext();

export function useEmailModal() {
  return React.useContext(emailModalContext);
}

export default function EmailModalProvider({ children }) {
  const [isBlocked, setIsBlocked] = React.useState(false);
  const [showEmailModal, setShowEmailModal] = React.useState(false);
  const currentUser = useCurrentUser();

  const value = React.useMemo(
    () => ({
      block() {
        setIsBlocked(true);
      },
      unblock() {
        setIsBlocked(false);
      },
    }),
    [],
  );

  const checkBlocked = React.useCallback(() => {
    const showNotShowToUser = currentUser.roles.some(
      (role) => role.key !== "is_customer" && role.active,
    );

    const hasCookie = document.cookie.includes("email_modal=true");

    return (
      currentUser.isLoggedIn || isBlocked || showNotShowToUser || hasCookie
    );
  }, [currentUser, isBlocked]);

  React.useEffect(() => {
    if (checkBlocked()) return;
    setShowEmailModal(true);
  }, [currentUser, checkBlocked]);

  return (
    <emailModalContext.Provider value={value}>
      {children}

      {showEmailModal && (
        <EmailModal
          onClose={() => {
            setShowEmailModal(false);
          }}
          checkBlocked={checkBlocked}
        />
      )}
    </emailModalContext.Provider>
  );
}

EmailModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

import * as React from "react";
import useSWR, { preload } from "swr";
import { identity } from "../util";
import { fetcher } from "../hooks";

const baseEndpoint = "/api/influencer/list";
const fiveMinutes = 1000 * 60 * 5; // 5 minutes

export default function useInfluencers(enricher = identity) {
  const { data, error, mutate } = useSWR(baseEndpoint, fetcher, {
    focusThrottleInterval: fiveMinutes,
  });

  const influencers = React.useMemo(() => {
    if (!data) {
      return;
    }

    return enricher(data);
  }, [data, enricher]);

  return {
    influencers,
    isLoading: !data && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}

export function prefetchInfluencers() {
  preload(baseEndpoint, fetcher);
}

import * as React from "react";

const analyticsContext = React.createContext();

const gtag = (function init() {
  window.dataLayer = window.dataLayer || [];

  return function gtag() {
    if (!process.env.NODE_ENV === "production") {
      return;
    }

    window.dataLayer.push(arguments);
  };
})();

export function useGtag() {
  return React.useContext(analyticsContext);
}

export default function GoogleAnalyticsProvider({ children }) {
  return (
    <analyticsContext.Provider value={gtag}>
      {children}
    </analyticsContext.Provider>
  );
}

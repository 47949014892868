import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { prefetchInfluencers } from "./hooks/useInfluencers";
import "./index.css";
import "./keyframes.css";

if (window.location.pathname === "/") {
  prefetchInfluencers();
}

ReactDOM.render(<App />, document.getElementById("root"));

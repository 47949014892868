import React from "react";
import PropTypes from "prop-types";
import "./popular_franchise.css";
import { withRouter } from "react-router-dom";
import SectionWrapper from "../commonComponent/SectionWrapper";
import { FranchiseBlock } from "../commonComponent";

const PopularFrancise = React.memo(function PopularFrancise({
  data,
  screenWidth,
  isLoading = true,
}) {
  return (
    <SectionWrapper
      data={data}
      screenWidth={screenWidth}
      isLoading={isLoading}
      blockWidth={200}
      blockHeight={100}
      margin={20}
      title="Popular Franchises"
    >
      {FranchiseBlock}
    </SectionWrapper>
  );
});

PopularFrancise.propTypes = {
  data: PropTypes.array,
  screenWidth: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default withRouter(PopularFrancise);

import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Form, DarkSmallHeading, ErrorUI, LoadingUI } from "../../../../theme";
import { useCart, useCurrentUser } from "../../../../hooks";
import * as utils from "../../utils";

const VipSelection = React.memo(function VipSelection({
  onClick,
  selected,
  disabled,
  shipmentRequired,
}) {
  const { cart, isLoading, isError } = useCart();
  const currentUser = useCurrentUser();

  if (isLoading) {
    return <LoadingUI />;
  }

  if (isError) {
    return <ErrorUI message="Unable to load cart" />;
  }

  const promoText = utils.vipPromoTextFor({
    cart,
    currentUser,
  });

  const getLabelText = () => {
    if (disabled && shipmentRequired) {
      return "Streamily Family Member Shipping";
    }

    if (!disabled && shipmentRequired) {
      return `I Want ${promoText} Shipping`;
    }

    return "Join Streamily Family Membership";
  };

  const subtext = shipmentRequired
    ? "Join Streamily Family Membership"
    : "Become part of a growing online community";

  return (
    <StyledButton
      onClick={onClick}
      className={selected ? "selected" : ""}
      disabled={disabled}
    >
      <Box pr={1.5} alignSelf="flex-start">
        <Form.Radio color="default" checked={selected || disabled} />
      </Box>

      <Grid container alignItems="center" spacing={1}>
        <Grid item xs="auto">
          <StyledVIPLabel>VIP</StyledVIPLabel>
        </Grid>

        <Grid item container direction="column" xs>
          <DarkSmallHeading>{getLabelText()}</DarkSmallHeading>

          {!disabled && (
            <StyledTypography component="span">{subtext}</StyledTypography>
          )}
        </Grid>

        {!disabled && (
          <Grid item xs={12}>
            <Typography variant="caption" component="p">
              <strong>{promoText} Shipping</strong> on your first order!
            </Typography>

            <Typography variant="caption" component="p">
              <strong>
                Your items guaranteed to be signed LIVE on stream!
              </strong>
            </Typography>

            <Typography variant="caption" component="p">
              <strong>Text Notifications</strong> when your items are about to
              be signed Live!
            </Typography>

            <Typography variant="caption" component="p">
              Be the <strong>First To Know</strong> about signing and get your
              items signed Before Anyone Else!
            </Typography>

            <Typography variant="caption" component="p">
              <strong>Recurring $9.99/mo.</strong> Cancel anytime.
            </Typography>
          </Grid>
        )}

        {disabled && (
          <Grid item>
            <StyledBox>Applied</StyledBox>
          </Grid>
        )}
      </Grid>
    </StyledButton>
  );
});

VipSelection.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  shipmentRequired: PropTypes.bool,
};

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: theme.gradient.pink,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  textAlign: "left",
  transition: "background 0.2s ease",
  "&.selected": {
    boxShadow: `0 0 0 3px ${theme.palette.grey.main}`,
  },
  "&:disabled": {
    background: "transparent",
    boxShadow: `0 0 0 1px ${theme.palette.grey[300]}`,
  },
}));

const StyledVIPLabel = styled(Typography)(({ theme }) => ({
  padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  borderRadius: theme.spacing(1),
  background: "#000",
  color: "#fff",
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(16),
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
  background: theme.palette.grey[500],
  color: "#fff",
  borderRadius: theme.spacing(1),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  lineHeight: 1.2,
}));

export default VipSelection;

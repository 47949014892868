import { dateFormatter, dateTimeFormatter, formatDate } from "../../util";
import moment from "moment-timezone";

const registry = new Set();

const RANGE_TYPE = "Range";
const DAY_AND_TIME_TYPE = "Day & Time";
const DEFAULT_TIMEZONE = "US/Pacific";
const DEFAULT_TIMEZONE_ABBR = "PST";
const NON_LIVE_SIGNING = "Non-live Signing";

export default class InfluencerEvent {
  #rawEvent;

  static TYPES = Object.freeze(
    Object.seal({
      RANGE: RANGE_TYPE,
      DAY_AND_TIME: DAY_AND_TIME_TYPE,
    }),
  );

  static formattedNextEventTime(obj, withTime = false) {
    const localTimeZone = moment.tz.guess(true);
    const moDate = moment.tz(obj.value, "UTC").tz(localTimeZone);

    if (!obj.value) {
      return "";
    }

    const dateFormatter = new Intl.DateTimeFormat("en", {
      dateStyle: "medium",
    });

    if (!withTime) {
      return dateFormatter.format(moDate);
    }

    const timeFormatter = new Intl.DateTimeFormat("en", {
      timeStyle: "short",
    });

    return `${dateFormatter.format(moDate)} ${timeFormatter.format(moDate)}`;
  }

  /*
   * withTimezoneOffset returns a date with the UTC offset applied
   *
   * @param {string} dateString
   */
  static withTimezoneOffset(dateString) {
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset() * 60 * 1000;

    return new Date(date.getTime() + offset);
  }

  static register(eventType) {
    registry.add(eventType);
    return eventType;
  }

  /*
   * from creates a new Event from a rawEvent
   *
   * @param {object} rawEvent
   * @returns {InfluencerEvent}
   */
  static from(rawEvent) {
    for (const eventType of registry) {
      if (eventType.canHandle(rawEvent)) {
        return new eventType(rawEvent);
      }
    }

    return new InfluencerEvent(rawEvent);
  }

  static isDayAndTime(event) {
    return event instanceof DayAndTimeEvent;
  }

  static isRange(event) {
    return event instanceof RangeEvent;
  }

  static isNonLiveSigning(event = {}) {
    return (
      event &&
      (event.eventType === NON_LIVE_SIGNING ||
        event.event_type === NON_LIVE_SIGNING)
    );
  }

  /*
   * creates a new Event from a rawEvent
   *
   * @param {object} rawEvent
   * @returns {InfluencerEvent}
   */
  constructor(rawEvent) {
    const rawEventCopy = { ...rawEvent };
    this.#rawEvent = rawEventCopy;
  }

  get isNonLiveSigning() {
    return Boolean(this.#rawEvent.event_type === NON_LIVE_SIGNING);
  }

  get printBoxDisclaimer() {
    return this.#rawEvent.print_box_disclaimer || "";
  }

  get influencerEventId() {
    return this.#rawEvent.influencer_event_id;
  }

  get influencerId() {
    return this.#rawEvent.influencer_id;
  }

  get influencerName() {
    return this.#rawEvent.influencer_name;
  }

  get influencerRoute() {
    return this.#rawEvent.influencer_route;
  }

  get influencerAvatarUrl() {
    return this.#rawEvent.influencer_avatar_url;
  }

  get hasInfluencer() {
    return Boolean(this.#rawEvent.influencer_route);
  }

  get dateType() {
    return this.#rawEvent.date_type;
  }

  get eventUrl() {
    return this.#rawEvent.event_url;
  }

  get eventTitle() {
    return this.#rawEvent.event_title;
  }

  get eventType() {
    return this.#rawEvent.event_type;
  }

  get eventDescription() {
    return this.#rawEvent.event_description;
  }

  get url() {
    if (!this.eventUrl) return null;
    try {
      return new URL(`https://${this.eventUrl}`);
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  get value() {
    if (this.#rawEvent.next_event_time) {
      return new Date(this.#rawEvent.next_event_time);
    }

    return void 0;
  }

  get startDate() {
    if (this.#rawEvent.event_start_date) {
      return InfluencerEvent.withTimezoneOffset(
        this.#rawEvent.event_start_date,
      );
    }

    return void 0;
  }

  get endDate() {
    if (this.#rawEvent.event_end_date) {
      return InfluencerEvent.withTimezoneOffset(this.#rawEvent.event_end_date);
    }

    return void 0;
  }

  get hasTime() {
    return Boolean(this.#rawEvent.has_time);
  }

  formattedNextEventTime(withTime = this.hasTime) {
    return InfluencerEvent.formattedNextEventTime(this, withTime);
  }

  get timeZone() {
    if (!this.#rawEvent.time_zone) {
      return DEFAULT_TIMEZONE;
    }

    return this.#rawEvent.time_zone;
  }

  get timeZoneAbbr() {
    if (!this.#rawEvent.time_zone_abbr) {
      return DEFAULT_TIMEZONE_ABBR;
    }

    return this.#rawEvent.time_zone_abbr;
  }

  get platform() {
    return this.#rawEvent.platform ?? "";
  }

  get hasSigningRoomFlag() {
    return Boolean(this.#rawEvent.signing_room_flag);
  }

  get eventNotificationSent() {
    return Boolean(this.#rawEvent.event_notification_sent);
  }

  get created() {
    if (this.#rawEvent.created) {
      return new Date(this.#rawEvent.created);
    }

    return void 0;
  }

  get browserTimezone() {
    const localTimeZone = moment.tz.guess(true);
    const moDate = moment.tz(new Date(), "UTC").tz(localTimeZone);
    return moDate.zoneAbbr();
  }

  get updated() {
    if (this.#rawEvent.updated) {
      return new Date(this.#rawEvent.updated);
    }

    return void 0;
  }

  /*
   * mutate returns a new InfluencerEvent with key set to value
   *
   * @param {string} key
   * @param {any} value
   *
   * @returns {InfluencerEvent}
   */
  mutate(key, value) {
    const updatedRawEvent = { ...this.#rawEvent, [key]: value };
    return new InfluencerEvent(updatedRawEvent);
  }

  raw() {
    return { ...this.#rawEvent };
  }
}

export const DayAndTimeEvent = InfluencerEvent.register(
  class DayAndTimeEvent extends InfluencerEvent {
    static canHandle(rawEvent) {
      return (
        rawEvent.next_event_time !== null &&
        (!rawEvent.event_start_date || !rawEvent.event_end_date)
      );
    }

    static is(event) {
      return event instanceof DayAndTimeEvent;
    }

    get type() {
      return DAY_AND_TIME_TYPE;
    }

    get formattedValue() {
      const localTimeZone = moment.tz.guess(true);
      const moDate = moment.tz(this.value, "UTC").tz(localTimeZone);

      if (this.value && this.hasTime) {
        return `${dateTimeFormatter.format(moDate)} ${moDate.zoneAbbr()}`;
      }

      if (this.value && !this.hasTime) {
        return `${dateFormatter.format(moDate)}`;
      }

      return "";
    }

    get formattedTitleWithDateValue() {
      const localTimeZone = moment.tz.guess(true);
      const moDate = moment.tz(this.value, "UTC").tz(localTimeZone);
      if (this.value) {
        const formattedDate = formatDate(moDate);
        return `${formattedDate.monthNum}/${formattedDate.dayStr}`;
      }

      return "";
    }

    get searchFormattedValue() {
      const localTimeZone = moment.tz.guess(true);
      const moDate = moment.tz(this.value, "UTC").tz(localTimeZone);

      if (this.value && this.hasTime) {
        const date = formatDate(moDate);
        return `${date.monthStr} ${date.dayStr} at ${date.time}`;
      }

      if (this.value && !this.hasTime) {
        const date = formatDate(moDate);
        return `${date.monthStr} ${date.dayStr}`;
      }

      return "";
    }
  },
);

export const RangeEvent = InfluencerEvent.register(
  class RangeEvent extends InfluencerEvent {
    static canHandle(rawEvent) {
      return rawEvent.event_start_date && rawEvent.event_end_date;
    }

    static is(event) {
      return event instanceof RangeEvent;
    }

    get type() {
      return RANGE_TYPE;
    }

    get formattedStartDate() {
      if (this.startDate) {
        return dateFormatter.format(this.startDate);
      }

      return "";
    }

    get formattedEndDate() {
      if (this.endDate) {
        return dateFormatter.format(this.endDate);
      }

      return "";
    }
    get formattedTitleWithStartDate() {
      if (this.startDate) {
        return formatDate(this.startDate);
      }

      return "";
    }

    get formattedTitleWithEndDate() {
      if (this.endDate) {
        return formatDate(this.endDate);
      }

      return "";
    }

    get formattedValue() {
      return `${this.formattedStartDate} - ${this.formattedEndDate} ${this.timeZone}`;
    }

    get searchFormattedValue() {
      return `${this.formattedStartDate} - ${this.formattedEndDate} ${this.timeZoneAbbr}`;
    }

    get formattedTitleWithDateValue() {
      return `${this.formattedTitleWithStartDate.monthNum}/${this.formattedTitleWithStartDate.dayStr} - ${this.formattedTitleWithEndDate.monthNum}/${this.formattedTitleWithEndDate.dayStr}`;
    }
  },
);

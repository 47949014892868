import * as React from "react";
import PropTypes from "prop-types";
import Section from "./Section";
import * as constants from "../constants";

function SectionWrapper({
  influencers,
  isLoading,
  isMobile,
  section,
  updateFavouriteStatus,
  href,
}) {
  return (
    <Section
      href={href}
      key={section.title}
      title={section.title}
      look={lookFor({ section })}
      updateFavouriteStatus={
        section.updateFavouriteStatus ? updateFavouriteStatus : null
      }
      isLoading={isLoading}
      isMobile={isMobile}
      {...dataFor({ section, influencers })}
    />
  );
}

SectionWrapper.propTypes = {
  href: PropTypes.string,
  influencers: PropTypes.object,
  section: PropTypes.shape({
    type: PropTypes.oneOf([constants.PRODUCT_TYPE, constants.INFLUENCER_TYPE]),
    look: PropTypes.oneOf([
      constants.LOOK_TALL,
      constants.LOOK_WIDE,
      constants.LOOK_PRODUCT,
    ]),
    title: PropTypes.string,
    updateFavouriteStatus: PropTypes.bool,
    modelName: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  updateFavouriteStatus: PropTypes.func,
  look: PropTypes.oneOf([
    constants.LOOK_TALL,
    constants.LOOK_WIDE,
    constants.LOOK_PRODUCT,
  ]),
};

function dataFor({ section, influencers }) {
  if (section.type === constants.PRODUCT_TYPE && influencers) {
    return { products: influencers[section.modelName] };
  }

  if (influencers) {
    return { influencers: influencers[section.modelName] };
  }

  return {};
}

function lookFor({ section }) {
  if (section.look) {
    return section.look;
  }

  if (section.type === constants.PRODUCT_TYPE) {
    return constants.LOOK_PRODUCT;
  }

  return constants.LOOK_TALL;
}

export default React.memo(SectionWrapper);

import { Box, Button, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { D, G } from "@mobily/ts-belt";
import { useAtom, useAtomValue } from "jotai";
import PropTypes from "prop-types";
import React from "react";
import { generateId } from "../../util";
import Row from "./Row";

function toList(characters) {
  const keys = D.keys(characters);

  return keys.flatMap((key) => {
    const franchise = characters[key];
    const characterKeys = D.keys(franchise.characters);

    return characterKeys.map((characterKey) => {
      const {
        name,
        titleCharacterId,
        franchiseCharacterId,
        influencerId,
        influencerName,
        influencerAvatarUrl,
      } = franchise.characters[characterKey];

      return {
        franchise: { franchiseId: Number(key), name: franchise.name },
        character: {
          characterId: characterKey,
          franchiseCharacterId,
          name,
          titleCharacterId,
        },
        influencer: {
          influencerId,
          name: influencerName,
          avatarUrl: influencerAvatarUrl,
        },
      };
    });
  });
}

export default function FranchiseCharacterInputs({
  charactersAtom,
  onAddingRowChange,
  onChange,
  signersAtom,
}) {
  const signers = useAtomValue(signersAtom);
  const [characters, setCharacters] = useAtom(charactersAtom);
  const [addingRows, setAddingRows] = React.useState({});

  React.useEffect(() => {
    if (G.isFunction(onAddingRowChange)) {
      onAddingRowChange(D.keys(addingRows).length);
    }
  }, [addingRows, onAddingRowChange]);

  const onRemove = React.useCallback(
    ({ franchise, character }) => {
      setCharacters((state) => {
        const existingFranchise = state[franchise.franchiseId];

        if (!existingFranchise) {
          onChange?.(state, "onRemove a");
          return state;
        }

        const characters = D.deleteKey(
          existingFranchise.characters,
          character.characterId,
        );

        if (D.isEmpty(characters)) {
          const newState = D.deleteKey(state, franchise.franchiseId);
          onChange?.(newState, "onRemove b");
          return newState;
        }

        const newState = D.set(
          state,
          franchise.franchiseId,
          D.set(existingFranchise, "characters", characters),
        );

        onChange?.(newState, "onRemove c");
        return newState;
      });
    },
    [setCharacters, onChange],
  );

  const onAdd = React.useCallback(
    ({ id, franchise, character, influencer }) => {
      setCharacters((state) => {
        const newState = D.update(
          state,
          franchise.franchiseId,
          (existingFranchise) => {
            if (!existingFranchise) {
              return {
                name: franchise.name,
                characters: {
                  [character.characterId]: D.merge(character, influencer),
                },
              };
            }

            return D.update(
              existingFranchise,
              "characters",
              D.merge({
                [character.characterId]: D.merge(character, influencer),
              }),
            );
          },
        );

        onChange?.(newState, "onAdd a");
        return newState;
      });

      setAddingRows(D.deleteKey(id));
    },
    [setCharacters, onChange],
  );

  return (
    <Box display="flex" flexDirection="column">
      {D.isEmpty(characters) && (
        <Typography variant="body2" color="textSecondary">
          No characters added yet.
        </Typography>
      )}

      {toList(characters).map(({ character, franchise, influencer }, index) => (
        <Row.Display
          character={character}
          franchise={franchise}
          influencer={influencer}
          key={`character-${index}`}
          onRemove={onRemove}
        />
      ))}

      <Box
        display="flex"
        flexDirection="column"
        mb={2}
        mt={D.isNotEmpty(addingRows) ? 1 : 0}
      >
        {D.keys(addingRows).map((id) => (
          <Row.Add
            id={id}
            key={id}
            onAdd={onAdd}
            onCancel={() => {
              setAddingRows(D.deleteKey(id));
            }}
            signers={signers}
          />
        ))}
      </Box>

      <div>
        <Button
          style={{ fontWeight: "normal" }}
          onClick={() => {
            setAddingRows(D.set(generateId(), true));
          }}
          variant="outlined"
          size="small"
          startIcon={<AddIcon />}
        >
          Add Character
        </Button>
      </div>
    </Box>
  );
}

FranchiseCharacterInputs.propTypes = {
  charactersAtom: PropTypes.object.isRequired,
  onAddingRowChange: PropTypes.func,
  onChange: PropTypes.func,
  signersAtom: PropTypes.object.isRequired,
};

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import "./Browse.css";

import Live from "../../components/Browse/live/Live";
import Featured from "../../components/Browse/featured/Featured";
import PopularFrancise from "../../components/Browse/popular-franchise/Popular_Francise";
import PopularCharacters from "../../components/Browse/PopularCharacters";
import PopularPresigned from "../../components/Browse/PopularPresigned";
import PopularSignAnytime from "../../components/Browse/PopularSignAnytime";
import PopularProduct from "../../components/Browse/popular-product/Popular_Product";
import EvenMore from "../../components/Browse/even-more/Even_more";
import Banner from "../../components/Browse/Banner";
import SpecialShops from "../../components/Browse/special-shops/Special_Shops";
import useInfluencers from "../../hooks/useInfluencers";
import { featureFlags } from "../../util";

const SPECIAL_SHOPS_TITLE = "Screamily";

// eslint-disable-next-line complexity
const Browse = React.memo(function Browse({ isHomePage = false }) {
  const { influencers, isLoading, isError } = useInfluencers();
  const [liveData, setLive] = useState([]);
  const [featuredData, setFeatured] = useState([]);
  const [franchiseList, setPopularFranchise] = useState([]);
  const [productList, setPopularProduct] = useState([]);
  const [specialShopsList, setSpecialShops] = useState([]);
  const [popularCharacters, setPopularCharacters] = useState([]);
  const [popularPresigned, setPopularPresigned] = useState([]);
  const [popularSignAnytime, setPopularSignAnytime] = useState([]);
  const [evenMoreData, setEvenMore] = useState([]);
  const [screenWidth, setWidth] = useState(window.innerWidth);

  React.useEffect(() => {
    if (!influencers) return;
    setLive(influencers.liveAndUpcoming);

    if (
      featureFlags.FEATURED_SHOPS &&
      Array.isArray(influencers.featured) &&
      influencers.featured.length > 0
    ) {
      setFeatured(influencers.featured);
    }

    setPopularFranchise(influencers.popularFranchise);
    setPopularProduct(influencers.popularProducts);
    setEvenMore(influencers.evenMore);
    setSpecialShops(influencers.specialShops);
    setPopularCharacters(influencers.popularCharacters);
    setPopularPresigned(influencers.popularPresigned);
    setPopularSignAnytime(influencers.popularSignAnytime);
  }, [influencers]);

  const resize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [resize]);

  return (
    <>
      <Banner isHomePage={isHomePage} />
      <Container className="browse-container">
        {Boolean(liveData.length) && (
          <Live
            data={liveData}
            screenWidth={screenWidth}
            isLoading={isLoading || isError}
          />
        )}
        {featureFlags.FEATURED_SHOPS && Boolean(featuredData.length) && (
          <Featured
            data={featuredData}
            screenWidth={screenWidth}
            isLoading={isLoading || isError}
          />
        )}
        {featureFlags.SPECIAL_SHOPS && Boolean(specialShopsList.length) && (
          <SpecialShops
            data={specialShopsList}
            title={SPECIAL_SHOPS_TITLE}
            screenWidth={screenWidth}
            isLoading={isLoading || isError}
          />
        )}
        {Boolean(franchiseList.length) && (
          <PopularFrancise
            data={franchiseList}
            screenWidth={screenWidth}
            isLoading={isLoading || isError}
          />
        )}
        {Boolean(popularCharacters.length) && (
          <PopularCharacters
            data={popularCharacters}
            screenWidth={screenWidth}
            isLoading={isLoading || isError}
          />
        )}
        {Boolean(productList.length) && (
          <PopularProduct
            data={productList}
            screenWidth={screenWidth}
            isLoading={isLoading || isError}
          />
        )}
        {Boolean(popularPresigned.length) && (
          <PopularPresigned
            data={popularPresigned}
            screenWidth={screenWidth}
            isLoading={isLoading || isError}
          />
        )}
        {Boolean(popularSignAnytime.length) && (
          <PopularSignAnytime
            data={popularSignAnytime}
            screenWidth={screenWidth}
            isLoading={isLoading || isError}
          />
        )}
        {Boolean(evenMoreData.length) && (
          <EvenMore
            data={evenMoreData}
            screenWidth={screenWidth}
            isLoading={isLoading || isError}
          />
        )}
      </Container>
    </>
  );
});

Browse.propTypes = {
  isHomePage: PropTypes.bool,
};

export default Browse;

const Container = styled.div`
  flex: 1;
  background: rgb(215, 215, 215);
  background: white;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

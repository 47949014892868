import * as React from "react";
import { Box, Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CartProductsSection from "../CartProductsSection";
import TotalsSection from "../TotalsSection";
import { useMatchesLgUp, useMatchesXlUp } from "../../../../hooks";

const SharedSummary = React.memo(function SharedSummary() {
  const matchesLg = useMatchesLgUp();
  const matchesXl = useMatchesXlUp();

  return (
    <StyledBox
      pt={matchesXl ? 12 : matchesLg ? 8 : 4}
      pl={matchesXl ? 8 : matchesLg ? 4 : 0}
      mt={matchesLg ? 0 : 1}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CartProductsSection />
        </Grid>

        <Grid item xs={12}>
          <TotalsSection />
        </Grid>
      </Grid>
    </StyledBox>
  );
});

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
}));

export default SharedSummary;

const isProduction = process.env.NODE_ENV === "production";
const prefix = "Invariant failed";

/*
 * invariant asserts a condition, and provides a helpful error message when
 * process.env.NODE_ENV is not production.
 *
 * @param {boolean} condition
 * @param {string | () => string} message
 */
export default function invariant(condition, message) {
  if (condition) {
    return;
  }

  if (isProduction) {
    throw new Error(prefix);
  }

  const provided = typeof message === "function" ? message() : message;
  const value = provided ? `${prefix}: ${provided}` : prefix;
  throw new Error(value);
}

/*
 * assertNotEmpty throws if any object value is 'empty'
 *
 * @param {object} input object
 * @param {string?} message
 */
export function assertNotEmpty(obj, message) {
  const keys = Reflect.ownKeys(obj);

  for (let i = 0; i < keys.length; i++) {
    const value = keys[i];

    if (typeof value === "undefined" || value === null || value === "") {
      throw new Error(
        message ?? `Expected input[${keys[i]}] not be to empty (got: ${value})`,
      );
    }
  }
}

import * as React from "react";
import CartProduct from "./CartProduct";
import { useCart } from "../../../../hooks";
import { ErrorUI, LoadingUI } from "../../../../theme";

const CartProducts = React.memo(function CartProducts() {
  const { cart, isError, isLoading } = useCart();

  if (isLoading) {
    return <LoadingUI />;
  }

  if (isError) {
    return <ErrorUI message="Unable to load cart products" />;
  }

  return cart.cartProducts.map((cartProduct) => (
    <CartProduct key={cartProduct.productId} cartProduct={cartProduct} />
  ));
});

export default CartProducts;

import * as React from "react";
import PropTypes from "prop-types";
import { Form } from "../../theme";
import { useGooglePlaces } from "../../services/GooglePlacesProvider";
import inputValidation from "../../util/inputValidation";
import { diffLengths } from "../../util";
import { fieldsShape } from "./propTypes";
import InputOption from "./InputOption";

const AddressInput = React.memo(function AddressInput({
  fields,
  address,
  country,
  onChange,
  onBlur,
  inputRef,
}) {
  const { getPlacePredictions, getPlace } = useGooglePlaces();
  const [value, setValue] = React.useState(null);
  const lastInputValue = React.useRef("");
  const [inputValue, setInputValue] = React.useState(
    fields.address.defaultValue ?? "",
  );
  const [options, setOptions] = React.useState([]);

  const onValueChange = (_, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
  };

  const onInputChange = (_, newInputValue) => {
    lastInputValue.current = inputValue;
    setInputValue(newInputValue);
  };

  const wrappedOnBlur = () => {
    if (inputValue !== address) {
      onChange(inputValue);
    }

    onBlur();
  };

  React.useEffect(() => {
    let active = true;

    if (inputValue === "" && inputValue !== fields.address.defaultValue) {
      setOptions(value ? [value] : []);
      return;
    }

    if (diffLengths(lastInputValue.current, inputValue) > 1) {
      setValue(inputValue);
      setOptions([]);
      return;
    }

    getPlacePredictions({ input: inputValue, country }).then((results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [
    value,
    inputValue,
    country,
    fields.address.defaultValue,
    getPlacePredictions,
  ]);

  React.useEffect(() => {
    if (value && typeof value !== "string") {
      getPlace({ placeId: value.place_id }).then((result) => {
        onChange(result);
      });
    } else if (value && typeof value === "string") {
      onChange(value);
    }
  }, [value, getPlace, onChange]);

  React.useEffect(() => {
    setInputValue(address);
  }, [address]);

  return (
    <Form.Autocomplete
      selectOnFocus
      handleHomeEndKeys
      autoComplete
      freeSolo
      includeInputInList
      filterSelectedOptions
      id="google-places-input"
      getOptionLabel={getOptionLabel}
      filterOptions={(x) => x}
      options={options}
      value={value}
      onBlur={wrappedOnBlur}
      onChange={onValueChange}
      onInputChange={onInputChange}
      renderInput={(params) => (
        <Form.Input
          {...params}
          inputProps={{
            ...params.inputProps,
            ...inputValidation.address,
          }}
          inputRef={inputRef}
          fullWidth
          required={fields.address.required}
          label={`Address${fields.address.required ? "" : " (optional)"}`}
        />
      )}
      renderOption={(option) => <InputOption option={option} />}
    />
  );
});

AddressInput.propTypes = {
  fields: fieldsShape,
  country: PropTypes.string,
  address: PropTypes.string,
  inputRef: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  matchesXs: PropTypes.bool,
};

function getOptionLabel(option) {
  if (typeof option === "string") {
    return option;
  }

  if (option.structured_formatting) {
    return option.structured_formatting.main_text;
  }

  if (option.description) {
    const [firstPart] = option.description.split(",");
    return firstPart;
  }

  return "";
}

export default AddressInput;

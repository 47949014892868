import * as React from "react";
import PropTypes from "prop-types";
import AddressForm from "./AddressForm";
import { fieldsShape } from "./propTypes";
import GooglePlacesProvider from "../../services/GooglePlacesProvider";

/*
 * AddressForm provides a unified way of entering a user's address details. This
 * component expects as props:
 *
 * onChange: a function which receives the updated state on each change
 * onBlur: a function which receives the updated state on input blurs
 * disabled: a boolean whether to disable the form
 * title: the form title text'
 * fields: an object of fields to show, ie:
 *
 *   {
 *       firstName: {
 *           required: true,
 *           disabled: false,
 *           defaultValue: "Bob Smith"
 *       }
 *   }
 *
 */
export default function WrappedAddressForm({
  onChange,
  onBlur,
  title,
  disabled,
  fields,
}) {
  return (
    <GooglePlacesProvider>
      <AddressForm
        onChange={onChange}
        onBlur={onBlur}
        fields={fields}
        title={title}
        disabled={disabled}
      />
    </GooglePlacesProvider>
  );
}

WrappedAddressForm.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  fields: fieldsShape,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

import React from "react";
import PropTypes from "prop-types";
import "./live.css";
import SectionWrapper from "../commonComponent/SectionWrapper";
import { LiveBlock } from "../commonComponent";

const Live = React.memo(function Live({
  data,
  getShops,
  screenWidth,
  isLoading = true,
}) {
  return (
    <SectionWrapper
      data={data}
      screenWidth={screenWidth}
      isLoading={isLoading}
      title={`Live & Upcoming`}
    >
      {(props) => <LiveBlock getShops={getShops} {...props} showLive />}
    </SectionWrapper>
  );
});

Live.propTypes = {
  data: PropTypes.array,
  getShops: PropTypes.func,
  screenWidth: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default Live;

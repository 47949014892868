import * as React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import ShippingAddressSection from "./ShippingAddressSection";
import ChooseShippingSection from "./ChooseShippingSection";
import EmailSection from "./EmailSection";
import track from "../../../services/UserTrackingActions";
import { useCart } from "../../../hooks";
import { useCheckoutAddress } from "../CheckoutProvider";
import { Cart } from "../../../domain";
import { conventionAddress } from "../state";
import { mapObjectAll } from "../../../util";
import * as utils from "../utils";

const CheckoutForm = React.memo(function CheckoutForm({ dispatch }) {
  const sentTrackingRef = React.useRef(false);
  const { address } = useCheckoutAddress();
  const { cart, refresh } = useCart();

  const pickupAtConvention = Boolean(cart?.pickupAtConvention);
  const pickupRef = React.useRef(pickupAtConvention);

  const { shipmentRequired, showPickupChoice } = React.useMemo(
    () => utils.shipmentOptionsFor(cart),
    [cart],
  );

  React.useEffect(() => {
    if (pickupAtConvention && pickupRef.current !== pickupAtConvention) {
      Cart.updateAddress(conventionAddress).then(() => refresh());
    } else if (
      !pickupAtConvention &&
      pickupRef.current !== pickupAtConvention
    ) {
      Cart.updateAddress(
        mapObjectAll((value) => value || undefined, address),
      ).then(() => refresh());
    }

    pickupRef.current = pickupAtConvention;
  }, [pickupAtConvention, address, refresh]);

  React.useEffect(() => {
    if (sentTrackingRef.current) {
      return;
    }

    sentTrackingRef.current = true;

    track({
      userId: cart?.userId,
      actionType: "checkout-step",
      actionMetaData: { step: 1 },
    }).catch(() => {
      sentTrackingRef.current = false;
    });
  }, [cart?.userId]);

  return (
    <>
      <Grid item>
        <EmailSection dispatch={dispatch} />
      </Grid>

      {shipmentRequired && !pickupAtConvention && (
        <Grid item>
          <ShippingAddressSection dispatch={dispatch} />
        </Grid>
      )}

      <Grid item>
        <ChooseShippingSection
          shipmentRequired={shipmentRequired}
          showPickupChoice={showPickupChoice}
        />
      </Grid>
    </>
  );
});

CheckoutForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  shipmentRequired: PropTypes.bool,
  showPickupChoice: PropTypes.bool,
};

export default CheckoutForm;

import * as React from "react";
import PropTypes from "prop-types";
import { Box, Button, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { LoadingUI, ErrorUI, Form } from "../../../../theme";
import { useCurrentUser, useCart } from "../../../../hooks";
import * as utils from "../../utils";

const NonVipSelection = React.forwardRef(function NonVipSelection(
  { onClick, selected, disabled, shipmentRequired },
  ref,
) {
  const { cart, isLoading, isError } = useCart();
  const currentUser = useCurrentUser();

  if (isLoading) {
    return <LoadingUI />;
  }

  if (isError) {
    return <ErrorUI message="Unable to load cart" />;
  }

  if (disabled) {
    return null;
  }

  const promoText = utils.vipPromoTextFor({
    cart,
    currentUser,
  });

  const labelText = shipmentRequired
    ? `I Don't Want ${promoText} Shipping`
    : "No Thank You";

  return (
    <StyledButton
      ref={ref}
      onClick={onClick}
      className={selected ? "selected" : ""}
    >
      <Box pr={1.5} width="fit-content">
        <Form.Radio color="default" checked={selected} />
      </Box>

      <DarkSmallHeading>{labelText}</DarkSmallHeading>
    </StyledButton>
  );
});

NonVipSelection.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  shipmentRequired: PropTypes.bool,
};

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.grey[300]}`,
  width: "100%",
  padding: `${theme.spacing(0.75)}px ${theme.spacing(2)}px`,
  textAlign: "left",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  transition: "background 0.2s ease",
  "&.selected": {
    boxShadow: `0 0 0 3px ${theme.palette.grey.main}`,
  },
}));

const DarkSmallHeading = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  fontWeight: theme.typography.regular,
  fontSize: "1rem",
  fontFamily: theme.typography.interFontStack,
  color: "#000",
  marginTop: 0,
  marginBottom: 0,
  "&.disabled": {
    color: theme.palette.grey[400],
  },
}));

export default NonVipSelection;

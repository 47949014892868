import React, { useRef, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

import Logo from "../../assets/streamily-logo-full-without-tagline.png";

const logos = [
  { imageUrl: "netflix.png", title: "Netflix" },
  { imageUrl: "fox.png", title: "Fox" },
  { imageUrl: "cw.png", title: "The CW" },
  { imageUrl: "showtime.png", title: "Showtime" },
  { imageUrl: "paramount.png", title: "Paramount+", height: 35 },
  { imageUrl: "disney.png", title: "Disney+", height: 35 },
].map(({ imageUrl, title, height }, i) => (
  <img
    src={`https://d3qdxs9s8vjwda.cloudfront.net/misc/${imageUrl}`}
    alt={title}
    key={i}
    height={height ?? 30}
    width={80}
  />
));

const Hero = () => {
  const unmounted = useRef(false);
  const delayTimeout = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    delayTimeout.current = setTimeout(() => {
      if (unmounted.current) return;
      setShow(true);
    }, 1);
    return () => {
      unmounted.current = true;
      clearTimeout(delayTimeout.current);
    };
  }, []);

  return (
    <Container>
      <InnerContainer show={show}>
        <LogoContainer>
          <LogoImage src={Logo} alt="Streamily" width={300} height={125} />
          <div style={{ margin: "0 auto" }}>
            <div>Autographs Signed Live</div>
            <div>From Their Door To Yours</div>
          </div>

          <Logos>
            <span>Featuring Talent Seen On</span>
            {logos}
          </Logos>
        </LogoContainer>

        <VideoContainer>
          <Video
            src={`https://d3qdxs9s8vjwda.cloudfront.net/misc/homepage-phone-video.mp4`}
            autoPlay
            muted
            loop
            playsInline
          />
        </VideoContainer>
      </InnerContainer>
    </Container>
  );
};

export default Hero;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const InnerContainer = styled.div`
  opacity: 1;
  display: flex;
  flex-flow: row wrap;
  min-height: 500px;
  max-width: 900px;
  @media only screen and (max-width: 920px) {
    padding-top: 1rem;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    min-height: 100px;
  }
  ${(props) =>
    !props.show &&
    `
        opacity: 0;
    `}
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5rem;
  min-width: 380px;
  flex-basis: 50%;
  div {
    font-size: 24px;
    max-width: 400px;
    color: rgb(80, 80, 80);
    position: relative;
    animation: ${keyframes`
            0%{left:-10px; opacity: 0;}
            25%{left: -10px; opacity: 0;}
            75%{left: 0px;}
            100%{opacity: 1;}
        `} 2s;
  }
  @media only screen and (max-width: 920px) {
    flex-basis: unset;
    padding-top: 0;
    align-items: center;
    div {
      text-align: center;
      animation: ${keyframes`
            0%{opacity: 0;}
            25%{opacity: 0;}
            100%{opacity: 1;}
        `} 2s;
    }
  }
  @media only screen and (max-width: 500px) {
    min-width: 200px;
  }
`;

const Logos = styled.p`
  display: flex;
  flex-flow: row wrap;
  position: relative;
  align-items: center;
  gap: 1rem;
  margin-top: 3rem;
  font: 0.8rem / 1.5 Inter, Roboto, sans-serif;
  font-weight: 600;

  @media (max-width: 920px) {
    justify-content: center;

    & span {
      text-align: center;
    }
  }

  & span {
    flex-basis: 100%;
  }

  & div {
    font: inherit;
  }

  & img {
    object-fit: contain;
  }
`;

const LogoImage = styled.img`
  position: relative;
  animation: fadeIn 2s;
  object-fit: contain;
  @media only screen and (max-width: 920px) {
    padding-left: 60px;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  background: white;
  flex-basis: 50%;
  height: 100%;
  -webkit-filter: brightness(108.5%);

  @media (max-width: 920px) {
    flex-basis: unset;
  }
`;

const Video = styled.video`
  width: 100%;
  padding: 20px 20px 0 20px;
  padding-right: 0;
  box-sizing: border-box;
  height: auto;
  pointer-events: none;
  position: relative;
  animation: ${keyframes`
      0%{opacity: 0;}
      33%{opacity: 0;}
      100%{opacity: 1;}
    `} 2.5s;
  @media only screen and (max-width: 920px) {
    max-width: 400px;
    animation: ${keyframes`
            0%{opacity: 0;}
            100%{opacity: 1;}
        `} 1s;
  }
`;

import { Typography, Box } from "@material-ui/core";
import { G } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import React from "react";
import { Form } from "../../theme";

export default function SignerSearchBox({
  signers,
  selectedValue,
  setSelectedValue,
}) {
  const onSelect = React.useCallback(
    (_, newValue) => {
      setSelectedValue(newValue);
    },
    [setSelectedValue],
  );

  const renderInput = (params) => (
    <Form.Input
      {...params}
      inputProps={{ maxLength: 45, ...params.inputProps }}
      fullWidth
      label="Signer"
      size="small"
    />
  );

  return (
    <Box flex="1">
      <Form.Autocomplete
        autoComplete
        clearOnBlur={!selectedValue?.name}
        freeSolo
        handleHomeEndKeys
        includeInputInList
        options={signers}
        value={selectedValue?.name ?? ""} // This is required to clear the input
        selectOnFocus
        size="small"
        onChange={onSelect}
        getOptionLabel={(selectedValue) => {
          if (G.isString(selectedValue)) {
            return selectedValue;
          }
          return selectedValue.name;
        }}
        renderInput={renderInput}
        renderOption={(option) => (
          <Typography variant="body2">{option.name}</Typography>
        )}
      />
    </Box>
  );
}

SignerSearchBox.propTypes = {
  signers: PropTypes.array.isRequired,
  selectedValue: PropTypes.object,
  setSelectedValue: PropTypes.func.isRequired,
};

import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Form, DarkSmallHeading } from "../../../../theme";

const VipSelectionPickup = React.memo(function VipSelectionPickup({
  onClick,
  selected,
}) {
  return (
    <StyledButton onClick={onClick} className={selected ? "selected" : ""}>
      <Box pr={1.5} display="flex" alignItems="flex-start">
        <Form.Radio color="default" checked={selected} />
      </Box>

      <Grid container alignItems="center" spacing={1}>
        <Grid item xs="auto">
          <StyledVIPLabel>VIP</StyledVIPLabel>
        </Grid>

        <Grid item container direction="column" xs>
          <DarkSmallHeading>Streamily Family Member Shipping</DarkSmallHeading>
        </Grid>

        {selected && (
          <Grid item>
            <StyledBox>Applied</StyledBox>
          </Grid>
        )}
      </Grid>
    </StyledButton>
  );
});

VipSelectionPickup.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  textAlign: "left",
  background: "transparent",
  transition: "background 0.2s ease",
  "&.selected": {
    boxShadow: `0 0 0 3px ${theme.palette.grey.main}`,
  },
}));

const StyledVIPLabel = styled(Typography)(({ theme }) => ({
  padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  borderRadius: theme.spacing(1),
  background: "#000",
  color: "#fff",
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(16),
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
  background: theme.palette.grey[500],
  color: "#fff",
  borderRadius: theme.spacing(1),
}));

export default VipSelectionPickup;

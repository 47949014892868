import * as React from "react";
import { Hidden } from "@material-ui/core";
import SharedSummary from "./SharedSummary";
import SummaryHeader from "./SummaryHeader";

/*
 * CheckoutSummary is the right-hand pane of the checkout page (on desktop) or
 * the top pane (on mobile)
 */
const CheckoutSummary = React.memo(function CheckoutSummary() {
  return (
    <>
      <Hidden lgUp>
        <SummaryHeader />
      </Hidden>

      <Hidden mdDown>
        <SharedSummary />
      </Hidden>
    </>
  );
});

export default CheckoutSummary;

import { Collapse, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import * as React from "react";
import { PaymentMethod } from "../../../../domain";
import { useCurrentUser, useCart } from "../../../../hooks";
import StripeProvider from "../../../../services/StripeProvider";
import track from "../../../../services/UserTrackingActions";
import { Form } from "../../../../theme";
import { useCheckout } from "../../CheckoutProvider";
import {
  paymentMethodUpdate,
  PAYMENT_METHODS,
  savedCardUpdate,
} from "../../state";
import * as utils from "../../utils";
import * as icons from "./icons";
import PaymentElement from "./PaymentElement";

const paymentMethodTrials = new Set();

export default function CardElementWrapper({
  onSubmit,
  setOnSubmit,
  clientSecret,
}) {
  const currentUser = useCurrentUser();
  const [onUpdate, setOnUpdate] = React.useState(null);
  const { cart } = useCart();
  const { state, dispatch } = useCheckout();

  const handleUpdatePaymentMethod = React.useCallback(
    async (paymentMethod) => {
      const { paymentIntentId, clientSecret } = state?.purchase ?? {};

      if (!clientSecret || !paymentIntentId) {
        return;
      }

      try {
        await PaymentMethod.updatePaymentMethod({
          paymentMethod,
          paymentIntentId,
          clientSecret,
        });

        if (typeof onUpdate === "function") {
          const { error } = await onUpdate();

          if (error) {
            console.error(error);
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    [state.purchase, onUpdate],
  );

  if (!clientSecret) {
    return null;
  }

  const hasDefaultSource = Boolean(state.purchase?.defaultSource);

  return (
    <StripeProvider clientSecret={clientSecret}>
      <Form.StyledGrid container direction="column">
        {hasDefaultSource && (
          <Form.StyledGridItem position="bottom" item>
            <Form.Option
              color="default"
              label={utils.labelForDefaultCard(state.purchase.defaultSource)}
              checked={
                state.paymentMethod === PAYMENT_METHODS.STRIPE &&
                state.useSavedCard &&
                hasDefaultSource
              }
              onClick={() => {
                dispatch(savedCardUpdate(true));
                dispatch(paymentMethodUpdate(PAYMENT_METHODS.STRIPE));
                handleUpdatePaymentMethod(state.purchase.defaultSource.id);
                track({
                  userId: currentUser.userId,
                  actionType: "payment-method",
                  actionMetaData: { type: PAYMENT_METHODS.SAVED_CARD },
                });
              }}
              endIcon={icons.paymentIcon}
            />
          </Form.StyledGridItem>
        )}

        {paymentMethodTrials.has(PAYMENT_METHODS.PAYPAL) && (
          <Form.StyledGridItem position="bottom">
            <Form.Option
              color="default"
              label={icons.paypalIcon}
              endIcon={
                state.paymentMethod === PAYMENT_METHODS.PAYPAL ? (
                  <Typography color="error" align="center" variant="body2">
                    <strong>
                      Sorry, PayPal is not yet available. Please use a Credit
                      Card
                    </strong>
                  </Typography>
                ) : undefined
              }
              checked={state.paymentMethod === PAYMENT_METHODS.PAYPAL}
              onClick={() => {
                dispatch(paymentMethodUpdate(PAYMENT_METHODS.PAYPAL));
                track({
                  userId: currentUser.userId,
                  actionType: "payment-method",
                  actionMetaData: { type: PAYMENT_METHODS.PAYPAL },
                });
              }}
            />
          </Form.StyledGridItem>
        )}

        {paymentMethodTrials.has(PAYMENT_METHODS.GOOGLE_PAY) && (
          <Form.StyledGridItem position="bottom">
            <Form.Option
              color="default"
              label={icons.googlePayIcon}
              checked={state.paymentMethod === PAYMENT_METHODS.GOOGLE_PAY}
              onClick={() => {
                dispatch(paymentMethodUpdate(PAYMENT_METHODS.GOOGLE_PAY));
                track({
                  userId: currentUser.userId,
                  actionType: "payment-method",
                  actionMetaData: { type: PAYMENT_METHODS.GOOGLE_PAY },
                });
              }}
              endIcon={
                state.paymentMethod === PAYMENT_METHODS.GOOGLE_PAY ? (
                  <Typography color="error" align="center" variant="body2">
                    <strong>
                      Sorry, Google Pay is not yet available. Please use a
                      Credit Card
                    </strong>
                  </Typography>
                ) : undefined
              }
            />
          </Form.StyledGridItem>
        )}

        {paymentMethodTrials.has(PAYMENT_METHODS.AFFIRM) && (
          <Form.StyledGridItem position="bottom">
            <Form.Option
              color="default"
              label={icons.affirmIcon}
              checked={state.paymentMethod === PAYMENT_METHODS.AFFIRM}
              onClick={() => {
                dispatch(paymentMethodUpdate(PAYMENT_METHODS.AFFIRM));
                track({
                  userId: currentUser.userId,
                  actionType: "payment-method",
                  actionMetaData: { type: PAYMENT_METHODS.AFFIRM },
                });
              }}
              endIcon={
                state.paymentMethod === PAYMENT_METHODS.AFFIRM ? (
                  <Typography color="error" align="center" variant="body2">
                    <strong>
                      Sorry, Affirm is not yet available. Please use a Credit
                      Card
                    </strong>
                  </Typography>
                ) : undefined
              }
            />
          </Form.StyledGridItem>
        )}

        <Form.StyledGridItem
          position={!state.useSavedCard ? "bottom" : undefined}
          item
        >
          <Form.Option
            color="default"
            label={utils.labelForOtherPaymentMethod(cart)}
            checked={
              state.paymentMethod === PAYMENT_METHODS.STRIPE &&
              (!state.useSavedCard || !hasDefaultSource)
            }
            onClick={() => {
              dispatch(paymentMethodUpdate(PAYMENT_METHODS.STRIPE));
              dispatch(savedCardUpdate(false));
              track({
                userId: currentUser.userId,
                actionType: "payment-method",
                actionMetaData: { type: PAYMENT_METHODS.STRIPE },
              });
            }}
            endIcon={icons.creditCards}
          />
        </Form.StyledGridItem>

        <Collapse
          in={
            state.paymentMethod === PAYMENT_METHODS.STRIPE &&
            (!state.useSavedCard || !hasDefaultSource)
          }
        >
          <PaymentElement
            onSubmit={onSubmit}
            setOnSubmit={setOnSubmit}
            onUpdate={onUpdate}
            setOnUpdate={setOnUpdate}
          />
        </Collapse>
      </Form.StyledGrid>
    </StripeProvider>
  );
}

CardElementWrapper.propTypes = {
  onSubmit: PropTypes.func,
  setOnSubmit: PropTypes.func,
  clientSecret: PropTypes.string,
};

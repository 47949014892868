import { Box, Collapse, Switch, Typography } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { B, D } from "@mobily/ts-belt";
import { useAtom } from "jotai";
import PropTypes from "prop-types";
import * as React from "react";
import { SignType } from "../../domain";
import { useCurrentUser } from "../../globalState";
import { InfoTooltip } from "../../theme";
import * as styles from "./styles";

export default function AdvancedSettings({ productAtom }) {
  const currentUser = useCurrentUser();
  const [isOpen, setIsOpen] = React.useState(false);
  const [product, setProduct] = useAtom(productAtom);

  const isPresigned = React.useMemo(
    () => SignType.isPresigned(product.signedType),
    [product.signedType],
  );

  const canHideProduct = !isPresigned || (isPresigned && currentUser.isAdmin);

  return (
    <Box display="flex" flexDirection="column" gridGap="8px">
      <styles.TextButton onClick={() => setIsOpen(B.not)}>
        Advanced Settings
        {isOpen ? (
          <ExpandLess fontSize="small" />
        ) : (
          <ExpandMore fontSize="small" />
        )}
      </styles.TextButton>

      <Collapse in={isOpen}>
        <Box
          alignItems="center"
          component="label"
          display="flex"
          gridGap="8px"
          htmlFor="product-modal-hide-product"
          justifyContent="space-between"
          mb={1}
          width="100%"
        >
          <div>
            <styles.Label htmlFor="product-modal-hide-product">
              Hide Product
              {!canHideProduct && (
                <InfoTooltip
                  title="Hide Product"
                  content="Please reach out to your Account Executive if you'd like to deactivate a presigned product"
                />
              )}
            </styles.Label>

            <Typography variant="caption" color="textSecondary">
              When turned On, this product will be hidden from the customers.
            </Typography>
          </div>

          <Switch
            id="product-modal-hide-product"
            color="secondary"
            checked={!product.active}
            onChange={() => setProduct(D.update("active", B.not))}
            disabled={!canHideProduct}
          />
        </Box>

        <Box
          alignItems="center"
          component="label"
          display="flex"
          gridGap="16px"
          htmlFor="product-modal-allow-personalization"
          justifyContent="space-between"
          mb={1}
          width="100%"
        >
          <div>
            <styles.Label htmlFor="product-modal-allow-personalization">
              Allow Personalization
            </styles.Label>

            <Typography variant="caption" color="textSecondary">
              Will Personalizations be available for orders of this product?
            </Typography>
          </div>

          <Switch
            id="product-modal-allow-personalization"
            color="secondary"
            checked={Boolean(product.allowPersonalization)}
            onChange={() => setProduct(D.update("allowPersonalization", B.not))}
          />
        </Box>

        <Box
          alignItems="center"
          component="label"
          display="flex"
          gridGap="16px"
          htmlFor="product-modal-allow-notes"
          justifyContent="space-between"
          mb={1}
          width="100%"
        >
          <div>
            <styles.Label htmlFor="product-modal-allow-notes">
              Allow Special Instructions
            </styles.Label>

            <Typography variant="caption" color="textSecondary">
              Will Special Instructions be available for orders of this product?
            </Typography>
          </div>

          <Switch
            id="product-modal-allow-notes"
            color="secondary"
            checked={Boolean(product.allowNotes)}
            onChange={() => setProduct(D.update("allowNotes", B.not))}
          />
        </Box>

        {currentUser.isAdmin && product.signedType === SignType.livestream && (
          <Box
            alignItems="center"
            component="label"
            display="flex"
            gridGap="16px"
            htmlFor="product-modal-disable-vip-addons"
            justifyContent="space-between"
            mb={1}
            width="100%"
          >
            <div>
              <styles.Label htmlFor="product-modal-disable-vip-addons">
                Disable VIP Addons
              </styles.Label>

              <Typography variant="caption" color="textSecondary">
                When turned on, disables the VIP Addons for orders of this
                product.
              </Typography>
            </div>

            <Switch
              id="product-modal-disable-vip-addons"
              color="secondary"
              checked={Boolean(product.disableVip)}
              onChange={() => setProduct(D.update("disableVip", B.not))}
            />
          </Box>
        )}

        {currentUser.isAdmin && (
          <Box
            alignItems="center"
            component="label"
            display="flex"
            gridGap="16px"
            htmlFor="product-modal-disable-coa-addons"
            justifyContent="space-between"
            mb={1}
            width="100%"
          >
            <div>
              <styles.Label htmlFor="product-modal-disable-coa-addons">
                Disable Certificate of Authenticity
              </styles.Label>

              <Typography variant="caption" color="textSecondary">
                When turned on, disables the COA Addon for orders of this
                product.
              </Typography>
            </div>

            <Switch
              id="product-modal-disable-coa-addons"
              color="secondary"
              checked={Boolean(product.blockCertOfAuth)}
              onChange={() => setProduct(D.update("blockCertOfAuth", B.not))}
            />
          </Box>
        )}

        {currentUser.isAdmin && (
          <Box
            alignItems="center"
            component="label"
            display="flex"
            gridGap="16px"
            htmlFor="product-modal-no-shipping"
            justifyContent="space-between"
            mb={1}
            width="100%"
          >
            <div>
              <styles.Label htmlFor="product-modal-no-shipping">
                No Shipping
              </styles.Label>

              <Typography variant="caption" color="textSecondary">
                When turned on, disables the shipping option for orders of this
                product.
              </Typography>
            </div>

            <Switch
              id="product-modal-no-shipping"
              color="secondary"
              checked={Boolean(product.noShipping)}
              onChange={() => setProduct(D.update("noShipping", B.not))}
            />
          </Box>
        )}
      </Collapse>
    </Box>
  );
}

AdvancedSettings.propTypes = { productAtom: PropTypes.object.isRequired };

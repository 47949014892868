import * as React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { ErrorBoundary } from "./ErrorBoundary";
import { styled } from "@material-ui/styles";
import { red, grey } from "@material-ui/core/colors";

export {
  withErrorBoundary,
  useErrorHandler,
  ErrorBoundary,
} from "./ErrorBoundary";

export { TYPES } from "./types";

const isProduction = process.env.NODE_ENV === "production";

const StyledBox = styled(Box)(() => ({
  background: grey[100],
}));

const ErrorText = styled(Typography)(() => ({
  color: red[700],
}));

export function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <StyledBox role="alert" p={2}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid item>
          <ErrorText variant="subtitle1">
            Whoops, something went wrong!
          </ErrorText>
          {!isProduction && (
            <ErrorText variant="caption">{error.message}</ErrorText>
          )}
        </Grid>
        <Grid item>
          <Button onClick={resetErrorBoundary}>Try again</Button>
        </Grid>
      </Grid>
    </StyledBox>
  );
}

/*
 * ErrorBoundaryWithFallback wraps a tree of React nodes with an ErrorBoundary
 * component, as well as a default fallback.
 *
 *  @param {function} onReset is an optional function to call when attempting to
 *  reset the ErrorBoundary
 */
export function ErrorBoundaryWithFallback({ children, onReset }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={onReset}>
      {children}
    </ErrorBoundary>
  );
}

export function concatMessages(errors) {
  return errors.map((err) => err.message).join(", ");
}

export default ErrorBoundaryWithFallback;

import { Box, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import * as React from "react";
import { Cart } from "../../../../domain";
import { useCart, useCartAttributes } from "../../../../hooks";
import { Form } from "../../../../theme";
import { useCheckout } from "../../CheckoutProvider";
import { shouldShowAffirm } from "../../utils";
import AffirmIcons, { TextLink } from "../AffirmIcons";
import CardWrapper from "./CardWrapper";

/*
 * PaymentSection renders the payment element from stripe, once the purchase
 * init has been created in the PaymentForm element.
 */
const PaymentSection = React.memo(function PaymentSection({
  onSubmit,
  setOnSubmit,
  handleBack,
}) {
  const { cart } = useCart();
  const { state } = useCheckout();
  const { cartAttributes, refresh } = useCartAttributes();
  const { savePaymentInformation, canContact } = cartAttributes;

  const handleChangeSaveInformation = React.useCallback(
    (evt) => {
      if (!state?.purchase?.wasLoggedIn) {
        return;
      }

      const updates = { savePaymentInformation: Boolean(evt.target.checked) };

      Cart.update(updates).then(() => refresh(updates));
    },
    [state?.purchase?.wasLoggedIn, refresh],
  );

  const handleChangeCanContact = React.useCallback(
    (evt) => {
      const updates = { canContact: Boolean(evt.target.checked) };

      Cart.update(updates).then(() => refresh(updates));
    },
    [refresh],
  );

  return (
    <Grid container>
      <Grid item component="header">
        <Form.Header component="h2">Payment</Form.Header>

        <Typography variant="caption" color="textSecondary">
          All transactions are encrypted and secure
        </Typography>

        {shouldShowAffirm(cart) && cart?.purchaseSubscription && (
          <Box
            display="flex"
            alignItems="center"
            gridGap="16px"
            maxWidth="72ch"
            my={2}
          >
            <AffirmIcons />
            <Typography variant="caption" color="textSecondary">
              To Buy Now and Pay Later using Affirm, Klarna, or AfterPay,{" "}
              <TextLink
                onClick={() => {
                  handleBack(null, { scrollTo: "chooseAffirm" });
                }}
              >
                go back
              </TextLink>{" "}
              and select the non-VIP option.
            </Typography>
          </Box>
        )}
      </Grid>

      <Grid item>
        <Box py={4}></Box>
      </Grid>

      {state.purchase?.clientSecret && (
        <CardWrapper
          clientSecret={state.purchase?.clientSecret}
          onSubmit={onSubmit}
          setOnSubmit={setOnSubmit}
        />
      )}

      <Grid item xs={12}>
        <Box py={1}></Box>
      </Grid>

      {!shouldShowAffirm(cart) && Boolean(state?.purchase?.wasLoggedIn) && (
        <Grid item xs={12}>
          <Form.SmallCheckbox
            label="Save my information for a faster checkout"
            checked={savePaymentInformation}
            onChange={handleChangeSaveInformation}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Form.SmallCheckbox
          label="Please send me discounts and promotions. We never sell your email address."
          checked={canContact}
          onChange={handleChangeCanContact}
        />
      </Grid>
    </Grid>
  );
});

PaymentSection.propTypes = {
  dispatch: PropTypes.func,
  onSubmit: PropTypes.func,
  setOnSubmit: PropTypes.func,
  handleBack: PropTypes.func.isRequired,
};

export default PaymentSection;

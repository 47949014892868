// optimized ordering
const urlAlphabet =
  "ModuleSymbhasOwnPr-0123456789ABCDEFGHNRVfgctiUvz_KqYTJkLxpZXIjQW";

/**
 * Non-cryptographically secure id function
 */
export function generateId(size = 21) {
  let id = "";
  // eslint-disable-next-line no-param-reassign
  while (size--) id += urlAlphabet[(Math.random() * 64) | 0];
  return id;
}

export default generateId;

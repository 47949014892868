import * as React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { Form } from "../../theme";
import inputValidation, { validations } from "../../util/inputValidation";
import { fieldsShape } from "./propTypes";
import { countries, countryOptionFor } from "../../util/addressUtils";
import AddressInput from "./AddressInput";
import {
  createInitialState,
  reducer,
  aggregateUpdate,
  simpleUpdate,
} from "./state";
import { useMatchesXsDown } from "../../hooks";
import { formatPhoneNumber } from "../../util";

const defaultCountry = { name: "United States", value: "US" };

// eslint-disable-next-line complexity
const AddressForm = React.memo(function AddressForm({
  onChange,
  onBlur,
  disabled = false,
  title = "Address",
  fields = {},
}) {
  const matchesXs = useMatchesXsDown();
  const countryInputRef = React.useRef(0);
  const [errors, setErrors] = React.useState({});

  const [state, dispatch] = React.useReducer(
    reducer,
    createInitialState(fields),
  );

  /*
   * This version of createSetter takes a key and an optional validation object
   *
   * @param {string} key
   * @param {object?} validation object
   *
   * @returns {function} event handler
   */
  const createSetter = React.useCallback(
    (key, { pattern, validityMessage } = {}) => {
      return (evt) => {
        const element = evt.target;
        const { value } = element;

        const clearError = () => {
          setErrors((errors) => {
            const copy = { ...errors };
            delete copy[key];
            return copy;
          });
        };

        if (value && pattern && pattern instanceof RegExp) {
          const isValid = pattern.test(value);

          if (!isValid) {
            setErrors((errors) => ({ ...errors, [key]: true }));
          } else {
            clearError();
            element.setCustomValidity("");
          }

          if (!isValid && validityMessage) {
            element.setCustomValidity(validityMessage);
          }
        } else {
          clearError();
        }

        dispatch(simpleUpdate({ key, value: evt.target.value }));
      };
    },
    [dispatch],
  );

  const handleCountryChange = React.useCallback(
    (_, newCountry) => {
      if (newCountry) {
        dispatch(aggregateUpdate({ country: newCountry.value, zipCode: "" }));
      }
    },
    [dispatch],
  );

  /*
   * This is a hacky way to try to let users autofill their country. Necessary
   * for browsers that have autofill enabled, so that their country choice doesn't
   * just get reset to United States.
   */
  const handleCountryInputChange = React.useCallback((_, newCountry) => {
    if (countryInputRef.current === 0) {
      countryInputRef.current++;
      return;
    }

    if (countryInputRef.current === 1) {
      const countryOption = countryOptionFor(newCountry);

      if (countryOption) {
        dispatch(aggregateUpdate({ country: countryOption.value }));
        countryInputRef.current++;
      }
    }
  }, []);

  const handleAddressChange = React.useCallback(
    (placeResult) => {
      if (typeof placeResult === "string") {
        dispatch(aggregateUpdate({ address: placeResult }));
        return;
      }

      const { addressComponents } = placeResult;
      const action = aggregateUpdate(addressComponents);

      dispatch(action);
    },
    [dispatch],
  );

  const wrappedOnBlur = React.useCallback(() => {
    if (typeof onBlur === "function") {
      return onBlur(state);
    }
  }, [onBlur, state]);

  React.useEffect(() => {
    if (typeof onChange === "function") {
      onChange(state);
    }
  }, [state, onChange]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} component="header">
        <Form.Header variant="h6" component="h1">
          {title}
        </Form.Header>
      </Grid>

      <Grid item container spacing={2}>
        {"firstName" in fields && (
          <Grid item xs={12} sm={6}>
            <Form.Input
              fullWidth
              required={fields.firstName.required}
              label={`First Name${
                fields.firstName.required ? "" : " (optional)"
              }`}
              value={state.firstName}
              onChange={createSetter("firstName")}
              onBlur={wrappedOnBlur}
              disabled={disabled || fields.firstName.disabled}
              inputProps={inputValidation.firstName}
            />
          </Grid>
        )}

        {"lastName" in fields && (
          <Grid item xs={12} sm={6}>
            <Form.Input
              fullWidth
              required={fields.lastName.required}
              label={`Last Name${
                fields.lastName.required ? "" : " (optional)"
              }`}
              value={state.lastName}
              disabled={disabled || fields.lastName.disabled}
              onChange={createSetter("lastName")}
              onBlur={wrappedOnBlur}
              inputProps={inputValidation.lastName}
            />
          </Grid>
        )}

        {"country" in fields && (
          <Grid item xs={12}>
            <Form.Autocomplete
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              value={countryOptionFor(state.country) ?? defaultCountry}
              options={countries}
              getOptionLabel={({ name }) => name}
              onChange={handleCountryChange}
              onBlur={wrappedOnBlur}
              onInputChange={handleCountryInputChange}
              renderInput={(params) => (
                <Form.Input
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    ...inputValidation.country,
                  }}
                  fullWidth
                  required
                  label="Country"
                />
              )}
            />
          </Grid>
        )}

        {"address" in fields && (
          <Grid item xs={12}>
            <AddressInput
              fields={fields}
              address={state.address}
              country={state.country}
              onChange={handleAddressChange}
              onBlur={wrappedOnBlur}
              matchesXs={matchesXs}
            />
          </Grid>
        )}

        {"address2" in fields && (
          <Grid item xs={12}>
            <Form.Input
              required={fields.address2.required}
              fullWidth
              label={`Apartment, Suite, etc${
                fields.address2.required ? "" : " (optional)"
              }`}
              value={state.address2}
              disabled={disabled || fields.address2.disabled}
              onChange={createSetter("address2")}
              onBlur={wrappedOnBlur}
              inputProps={inputValidation.address2}
            />
          </Grid>
        )}

        {"city" in fields && (
          <Grid item xs={12}>
            <Form.Input
              fullWidth
              required={fields.city.required}
              label={`City${fields.city.required ? "" : " (optional)"}`}
              value={state.city}
              disabled={disabled || fields.city.disabled}
              onChange={createSetter("city")}
              onBlur={wrappedOnBlur}
              inputProps={inputValidation.city}
            />
          </Grid>
        )}

        {"state" in fields && (
          <Grid item xs={12} sm={6}>
            <Form.Input
              required={fields.state.required}
              fullWidth
              label={`State${fields.state.required ? "" : " (optional)"}`}
              value={state.state}
              disabled={disabled || fields.state.disabled}
              onChange={createSetter("state")}
              onBlur={wrappedOnBlur}
              inputProps={inputValidation.state}
            />
          </Grid>
        )}

        {"zipCode" in fields && (
          <Grid item xs={12} sm={6}>
            <Form.Input
              required={fields.zipCode.required}
              fullWidth
              label={`Zip Code${fields.zipCode.required ? "" : " (optional)"}`}
              value={state.zipCode}
              disabled={disabled || fields.zipCode.disabled}
              onChange={createSetter("zipCode")}
              onBlur={wrappedOnBlur}
              inputProps={inputValidation.zipCode}
            />
          </Grid>
        )}

        {"phoneNumber" in fields && (
          <Grid item xs={12}>
            <Form.Input
              required={fields.phoneNumber.required}
              fullWidth
              label={`Phone Number${
                fields.phoneNumber.required ? "" : " (optional)"
              }`}
              value={formatPhoneNumber(state.phoneNumber)}
              type="tel"
              disabled={disabled || fields.phoneNumber.disabled}
              onChange={createSetter("phoneNumber")}
              onBlur={wrappedOnBlur}
              inputProps={inputValidation.phoneNumber}
              error={"phoneNumber" in errors}
              helperText={
                "phoneNumber" in errors &&
                validations.phoneNumber.validityMessage
              }
            />
          </Grid>
        )}

        {"email" in fields && (
          <Grid item xs={12}>
            <Form.Input
              required={fields.email.required}
              fullWidth
              label={`Email${fields.email.required ? "" : " (optional)"}`}
              type="email"
              value={state.email}
              disabled={disabled || fields.email.disabled}
              onChange={createSetter("email")}
              onBlur={wrappedOnBlur}
              inputProps={inputValidation.email}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});

AddressForm.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  fields: fieldsShape,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default AddressForm;

import * as React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { currencyFormatter } from "../../../../util";
import { useCurrentUser } from "../../../../globalState";
import { useCartAttributes } from "../../../../hooks";

const ShippingText = React.memo(function ShippingText({
  shipping,
  adjustedShipping,
}) {
  const currentUser = useCurrentUser();
  const { cartAttributes } = useCartAttributes();

  const { purchaseSubscription, pickupAtConvention } = cartAttributes;

  if (pickupAtConvention) {
    return (
      <Typography variant="body2" color="textSecondary">
        {currencyFormatter.format(adjustedShipping)}
      </Typography>
    );
  }

  if (!currentUser.isStreamilyFamilyMember && purchaseSubscription) {
    return (
      <StyledTypography variant="body2">
        <del>{currencyFormatter.format(shipping)}</del>{" "}
        <strong>{currencyFormatter.format(adjustedShipping)}</strong>
      </StyledTypography>
    );
  }

  return (
    <Typography variant="body2" color="textSecondary">
      {currencyFormatter.format(adjustedShipping)}
    </Typography>
  );
});

ShippingText.propTypes = {
  shipping: PropTypes.number,
  adjustedShipping: PropTypes.number,
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.red.dark,
}));

export default ShippingText;

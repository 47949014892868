import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import * as React from "react";
import { useCart } from "../../../hooks";
import track from "../../../services/UserTrackingActions";
import * as utils from "../utils";
import PaymentSection from "./PaymentSection";
import SummarySection from "./SummarySection";

/*
 * PaymentForm renders the payment section. Upon initial load, the PaymentForm
 * creates a purchase init via a call to service.createPurchase(). This returns
 * an object like { paymentIntent, subscriptionId, customerId, clientSecret }
 * which enables up to track the purchase and set up the Stripe PaymentElement
 * using the provided clientSecret.
 */
const PaymentForm = React.memo(function PaymentForm({
  dispatch,
  onSubmit,
  setOnSubmit,
  handleBack,
}) {
  const sentTrackingRef = React.useRef(false);
  const { cart } = useCart();

  const pickupAtConvention = Boolean(cart?.pickupAtConvention);

  const { shipmentRequired } = React.useMemo(
    () => utils.shipmentOptionsFor(cart),
    [cart],
  );

  React.useEffect(() => {
    if (sentTrackingRef.current) {
      return;
    }

    sentTrackingRef.current = true;

    track({
      userId: cart?.userId,
      actionType: "checkout-step",
      actionMetaData: { step: 2 },
    }).catch(() => {
      sentTrackingRef.current = false;
    });
  }, [cart?.userId]);

  return (
    <>
      {shipmentRequired && !pickupAtConvention && (
        <Grid item>
          <SummarySection handleBack={handleBack} />
        </Grid>
      )}

      <Grid item>
        <PaymentSection
          dispatch={dispatch}
          onSubmit={onSubmit}
          setOnSubmit={setOnSubmit}
          handleBack={handleBack}
        />
      </Grid>
    </>
  );
});

PaymentForm.propTypes = {
  dispatch: PropTypes.func,
  onSubmit: PropTypes.func,
  setOnSubmit: PropTypes.func,
  handleBack: PropTypes.func,
};

export default PaymentForm;

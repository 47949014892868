const isProduction =
  !process.env.NODE_ENV || process.env.NODE_ENV === "production";

/*
 * Log to info
 * @param {any}
 */
export function info(...args) {
  if (!isProduction) {
    window.console.info(...args);
  }
}

/*
 * Log to error
 * @param {any}
 */
export function error(...args) {
  if (!isProduction) {
    window.console.error(...args);
  }
}

/*
 * Log to info
 * @param {any}
 */
export function log(...args) {
  return info(...args);
}

const logger = { info, error, log };

export default logger;

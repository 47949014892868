import { Box, IconButton, Typography } from "@material-ui/core";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import PropTypes from "prop-types";
import * as React from "react";
import { Influencer, Product } from "../../../domain";
import { useCurrentUser } from "../../../globalState";
import {
  useDupeProductIdContext,
  useLivestreamWarningContext,
} from "../../../pages/Dashboard/ShopEditor";
import { StyledTextButton } from "../../../theme";
import { ProductButton, ProductImage, ProductText } from "../../Product";
import ProductModal from "../../ShopBuilderProductModal";

const ShopBuilderProduct = React.memo(function ShopBuilderProduct({
  product,
  refresh,
  influencer,
  salesCount,
  isLastProduct,
  isFirstProduct,
  onClose,
}) {
  const currentUser = useCurrentUser();
  const [isEditing, setIsEditing] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { totalSales, newSales } = countsFor(salesCount);
  const { dupeProductId } = useDupeProductIdContext();
  const livestreamWarning = useLivestreamWarningContext();

  React.useEffect(() => {
    // trigger edit dialog after duplicate saved
    if (!dupeProductId) return;
    if (dupeProductId === product.productId) {
      setIsEditing(true);
    }
  }, [dupeProductId, product]);

  const updatePosition = (n) => {
    setIsLoading(true);

    Product.updatePosition({
      product,
      shopInfluencerId: influencer.influencerId,
      position: product.position + n,
    }).finally(() => {
      setIsLoading(false);
      refresh();
    });
  };

  return (
    <div key={product.productId}>
      <ProductButton onClick={() => setIsEditing(true)}>
        <ProductImage product={product} />
        <ProductText product={product} />
      </ProductButton>

      <Box display="flex" alignItems="center" justifyContent="space-between">
        {totalSales > 0 && (
          <Typography variant="caption">
            Sales: {totalSales}
            {Boolean(newSales) && ` (${newSales} New)`}
          </Typography>
        )}

        {currentUser.isAdmin && (
          <Typography variant="caption">
            Product ID: {product.productId}
          </Typography>
        )}

        {product.isActive && !product.isArchived && (
          <>
            <IconButton
              size="small"
              onClick={() => updatePosition(-1)}
              disabled={isFirstProduct || isLoading}
            >
              <ArrowLeftIcon />
            </IconButton>

            <IconButton
              size="small"
              onClick={() => updatePosition(1)}
              disabled={isLastProduct || isLoading}
            >
              <ArrowRightIcon />
            </IconButton>
          </>
        )}

        {currentUser.isAdmin && (
          <StyledTextButton
            size="small"
            color="secondary"
            href={product.croppedOriginalImageUrl ?? product.originalImageUrl}
            target="_blank"
            rel="noopener,noreferrer"
            endIcon={<OpenInNewIcon />}
          >
            Original Image
          </StyledTextButton>
        )}
      </Box>

      {isEditing && (
        <ProductModal
          livestreamWarning={livestreamWarning}
          productId={product.productId}
          onClose={(props) => {
            setIsEditing(false);
            onClose(props);
            refresh();
          }}
        />
      )}
    </div>
  );
});

ShopBuilderProduct.propTypes = {
  product: PropTypes.instanceOf(Product),
  influencer: PropTypes.instanceOf(Influencer),
  refresh: PropTypes.func,
  salesCount: PropTypes.shape({
    unsigned: PropTypes.number,
    signed: PropTypes.number,
  }),
  isLastProduct: PropTypes.bool,
  isFirstProduct: PropTypes.bool,
  onClose: PropTypes.func,
};

function countsFor({ unsigned = 0, signed = 0 } = {}) {
  return { totalSales: signed + unsigned, newSales: unsigned };
}

export default ShopBuilderProduct;

import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { AutoSizer, Column, Table } from "react-virtualized";
import { TableCell } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { identity } from "../../util";

const styles = (theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
  },
  table: {
    "& .ReactVirtualized__Table__headerRow": {
      flip: false,
      paddingRight: theme.direction === "rtl" ? "0 !important" : undefined,
    },
    "& .ReactVirtualized__Table__headerColumn, .ReactVirtualized__Table__rowColumn":
      { margin: 0 },
  },
  tableRow: {
    cursor: "pointer",
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: "initial",
  },
});

class MuiVirtualizedTable extends React.PureComponent {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
  };

  getRowClassName = ({ index }) => {
    const { classes, onRowClick, customClasses, customRowClassNameFunc } =
      this.props;

    return clsx(
      classes.tableRow,
      classes.flexContainer,
      { [classes.tableRowHover]: index !== -1 && onRowClick != null },
      customClasses?.row,
      customRowClassNameFunc?.(index),
    );
  };

  cellRenderer = ({ cellData, columnIndex }) => {
    const { columns, classes, rowHeight, onRowClick, customClasses } =
      this.props;
    return (
      <TableCell
        component="div"
        className={clsx(
          customClasses?.row,
          classes.tableCell,
          classes.flexContainer,
          {
            [classes.noClick]: onRowClick == null,
          },
        )}
        variant="body"
        style={{ height: rowHeight }}
        align={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? "right"
            : "left"
        }
      >
        {cellData}
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex }) => {
    const {
      headerHeight,
      columns,
      classes,
      customClasses,
      customHeaderFunc = identity,
    } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick,
          customClasses?.headerRow,
        )}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? "right" : "left"}
      >
        <span>{customHeaderFunc(label, columnIndex)}</span>
      </TableCell>
    );
  };

  render() {
    const { classes, columns, rowHeight, headerHeight, ...tableProps } =
      this.props;

    const columnsWidth = getWidthFromColumns(columns);

    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={Number.isNaN(columnsWidth) ? width : columnsWidth}
            rowHeight={rowHeight}
            gridStyle={{ direction: "inherit" }}
            headerHeight={headerHeight}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(({ dataKey, ...other }, index) => (
              <Column
                key={dataKey}
                headerRenderer={(headerProps) =>
                  this.headerRenderer({
                    ...headerProps,
                    columnIndex: index,
                  })
                }
                className={classes.flexContainer}
                cellRenderer={this.cellRenderer}
                dataKey={dataKey}
                {...other}
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

function getWidthFromColumns(columns) {
  return columns.reduce((result, col) => result + col.width, 0);
}

MuiVirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      width: PropTypes.number.isRequired,
    }),
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.number,
  customClasses: PropTypes.shape({
    headerRow: PropTypes.string,
    row: PropTypes.string,
  }),
  customHeaderFunc: PropTypes.func,
  customRowClassNameFunc: PropTypes.func,
};

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

export default VirtualizedTable;

import React from "react";
import PropTypes from "prop-types";
import "../live/live.css";
import SectionWrapper from "../commonComponent/SectionWrapper";
import { SpecialShopsBlock } from "../commonComponent";

const SpecialShops = React.memo(function SpecialShops({
  data,
  title,
  screenWidth,
  isLoading = true,
}) {
  return (
    <SectionWrapper
      data={data}
      screenWidth={screenWidth}
      isLoading={isLoading}
      title={title}
    >
      {(props) => <SpecialShopsBlock data={data} {...props} />}
    </SectionWrapper>
  );
});

SpecialShops.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  screenWidth: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default SpecialShops;

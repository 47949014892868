import PropTypes from "prop-types";

export const requiredShape = PropTypes.shape({
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
});

export const fieldsShape = PropTypes.shape({
  firstName: requiredShape,
  lastName: requiredShape,
  country: requiredShape,
  address: requiredShape,
  address2: requiredShape,
  city: requiredShape,
  state: requiredShape,
  zipCode: requiredShape,
  phoneNumber: requiredShape,
  email: requiredShape,
});

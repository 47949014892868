import axios from "axios";
import Roles from "./roles";
import logger from "../util/logger";
import moment from "moment-timezone";

const endpoint = "/api/user";

export default class User {
  #rawUser;
  #roles;
  #salespeople;

  /*
   * from creates a User from a rawUser object
   *
   * @param {object} rawUser
   *
   * @returns {User}
   */
  static from(rawUser) {
    return new User(rawUser);
  }

  /*
   * update performs user updates
   *
   * @param {object} initialization object { user, updates }
   *
   * @returns {object} result of updates or errors
   */
  static async update({ user, updates }) {
    try {
      const { data } = await axios.put(`${endpoint}/${user.userId}`, updates);
      return data;
    } catch (err) {
      logger.error(err);

      if (err.isAxiosError) {
        return err.response.data;
      }

      return { errors: [{ message: "Unable to update user information" }] };
    }
  }

  /*
   * returns a new User from a rawUser
   *
   * @param {object} rawUser
   *
   * @returns {User}
   */
  constructor(rawUser) {
    this.#rawUser = { ...rawUser };
    this.#roles = new Roles(this.#rawUser);
  }

  get userId() {
    return this.#rawUser.user_id;
  }

  get email() {
    return this.#rawUser.email;
  }

  get isLoggedIn() {
    return Boolean(this.#rawUser.email);
  }

  get isActive() {
    return Boolean(this.#rawUser.active);
  }

  get isAdmin() {
    return Boolean(this.#rawUser.admin || this.#rawUser.superadmin);
  }

  get isSuperadmin() {
    return Boolean(this.#rawUser.superadmin);
  }

  get isRobPaulsenSuperfan() {
    return Boolean(this.#rawUser.isRobPaulsenSuperfan);
  }

  get isStreamilyFamilyMember() {
    return Boolean(this.#rawUser.isStreamilyFamilyMember);
  }

  get trialStatus() {
    return this.#rawUser.trialStatus;
  }

  get firstName() {
    return this.#rawUser.first_name;
  }

  get lastName() {
    return this.#rawUser.last_name;
  }

  get publicName() {
    return this.#rawUser.public_name;
  }

  get pronoun() {
    return this.#rawUser.pronoun;
  }

  /*
   * fullName returns a User's full name only if a user has both a first name and
   * a last name, otherwise it returns undefined.
   *
   * @returns {string | undefined}
   */
  get fullName() {
    if (this.#rawUser.first_name && this.#rawUser.last_name) {
      return `${this.#rawUser.first_name} ${this.#rawUser.last_name}`;
    }

    return void 0;
  }

  get phoneNumber() {
    return this.#rawUser.phone_number;
  }

  get shouldShowDashboardLink() {
    return Boolean(this.#rawUser.should_show_dashboard_link);
  }

  get organization() {
    return this.#rawUser.organization;
  }

  get roles() {
    return this.#roles;
  }

  get shops() {
    return this.#rawUser.shops ?? [];
  }

  get salespeople() {
    if (this.roles.isTalent) {
      return this.#salespeople ?? [];
    }

    return [];
  }

  get referrals() {
    return this.#rawUser.referrals ?? [];
  }

  get talentCoordinator() {
    return this.#rawUser.talent_coordinator_user_id;
  }

  get isTalent() {
    return Boolean(this.#rawUser.talent);
  }

  get isAgent() {
    return Boolean(this.#rawUser.is_agent);
  }

  get isArtist() {
    return Boolean(this.#rawUser.is_artist);
  }

  get isAffiliate() {
    return Boolean(this.#rawUser.is_affiliate);
  }

  get isTalentCoordinator() {
    return Boolean(this.#rawUser.is_talent_coordinator);
  }

  get hasTalentCoordinator() {
    return Boolean(this.#rawUser.talent_coordinator_user_id);
  }

  get raw() {
    return { ...this.#rawUser };
  }

  get lastAddress() {
    return this.#rawUser.lastAddress;
  }

  get created() {
    if (!this.#rawUser.created) {
      return "";
    }

    const localTimeZone = moment.tz.guess(true);

    return moment
      .tz(this.#rawUser.created, "UTC")
      .tz(localTimeZone)
      .format("MMMM Do YYYY, h:mm:ss a");
  }

  get updated() {
    if (!this.#rawUser.updated) {
      return "N/A";
    }

    const localTimeZone = moment.tz.guess(true);

    return moment
      .tz(this.#rawUser.updated, "UTC")
      .tz(localTimeZone)
      .format("MMMM Do YYYY, h:mm:ss a");
  }

  get isNotJustCustomer() {
    return (
      this.roles?.isTalent ||
      this.roles?.isAdmin ||
      this.roles?.isSuperadmin ||
      this.roles?.isAgent ||
      this.roles?.isTalentCoordinator ||
      this.roles?.isAgency ||
      this.roles?.isSalesperson ||
      this.roles?.isArtist ||
      this.roles?.isConvention ||
      this.roles?.isVendor ||
      this.roles?.isLegacy ||
      this.roles?.isCharity
    );
  }

  /*
   * setSalespeople sets a user's salespeople using the provided Class
   * constructor
   *
   * @param {class} Salesperson class
   *
   * @returns {User} this User
   */
  setSalespeople(Salesperson) {
    if (this.roles.isTalent) {
      this.#salespeople = (this.#rawUser.salespeople ?? []).map(
        Salesperson.from,
      );
    }

    return this;
  }

  /*
   * getFullNameWithDefault
   * return full name if first and last not null
   * else return first or last
   * else return defaultValue if both null
   *
   * @returns {string | undefined}
   */
  getFullNameWithDefault(defaultValue = "") {
    if (this.#rawUser.first_name && this.#rawUser.last_name) {
      return `${this.#rawUser.first_name} ${this.#rawUser.last_name}`;
    }
    if (this.#rawUser.first_name) return this.#rawUser.first_name;
    if (this.#rawUser.last_name) return this.#rawUser.last_name;

    return defaultValue;
  }

  /*
   * mutate sets a key to value on a new User
   *
   * @param {string} key
   * @param {any} value
   *
   * @returns {User}
   */
  mutate(key, value) {
    const newRawUser = { ...this.#rawUser, [key]: value };

    return new User(newRawUser);
  }

  [Symbol.iterator]() {
    return this.toJSON()[Symbol.iterator];
  }

  toJSON() {
    return {
      userId: this.userId,
      email: this.email,
      phoneNumber: this.phoneNumber,
      isActive: this.isActive,
      isAdmin: this.isAdmin,
      isSuperadmin: this.isSuperadmin,
      isRobPaulsenSuperfan: this.isRobPaulsenSuperfan,
      isStreamilyFamilyMember: this.isStreamilyFamilyMember,
      publicName: this.publicName,
      firstName: this.firstName,
      lastName: this.lastName,
      fullName: this.fullName,
      pronoun: this.pronoun,
      roles: this.roles.toJSON(),
      shops: this.shops,
      salespeople: this.roles.isTalent
        ? this.#salespeople?.map((sp) => sp.toJSON())
        : void 0,
      lastAddress: this.lastAddress,
    };
  }
}

import { A, G, S } from "@mobily/ts-belt";
import { atom } from "jotai";
import * as React from "react";
import useSWR from "swr";
import { SignType, Product } from "../../domain";
import { fetcher } from "../../hooks";

export const MAX_PRICE = 20_000;

export const allSignedTypes = SignType.list().reduce((acc, type) => {
  acc[type] = true;
  return acc;
}, {});

export const defaultFilter = {
  order: "ra",
  tags: {},
  talents: {},
  type: {},
  maxPrice: 0,
  minPrice: 0,
  character: {},
  franchise: {},
  signedType: { [SignType.presigned]: true, [SignType.noAutograph]: true },
  rank: 0,
  limit: 24,
};

export const filterAtom = atom(defaultFilter);

function hasMaxPrice(maxPrice) {
  return maxPrice > 0;
}

function hasMinPrice({ minPrice, maxPrice }) {
  return minPrice > 0 && (minPrice < maxPrice || !hasMaxPrice(maxPrice));
}

function hasName(name) {
  return G.isString(name) && S.isNotEmpty(name);
}

function hasRank(rank) {
  return G.isNumber(rank) && rank > 0;
}

function paramsFor({
  character,
  franchise,
  maxPrice,
  minPrice,
  name,
  order,
  tags,
  talents,
  type,
  signedType,
  rank,
}) {
  const params = new URLSearchParams({
    order,
  });

  if (hasName(name)) {
    params.set("name", name);
  }

  if (hasMaxPrice(maxPrice)) {
    params.set("max_price", maxPrice);
  }

  if (hasMinPrice({ minPrice, maxPrice })) {
    params.set("min_price", minPrice);
  }

  if (tags) {
    const tagsArr = Object.keys(tags).filter((key) => tags[key]);
    if (tagsArr.length > 0) {
      params.set("tags", tagsArr.join(","));
    }
  }

  if (talents) {
    const talentsArr = Object.keys(talents).filter((key) => talents[key]);
    if (talentsArr.length > 0) {
      params.set("talents", talentsArr.join(","));
    }
  }

  if (type) {
    const typeArr = Object.keys(type).filter((key) => type[key]);
    if (typeArr.length > 0) {
      params.set("type", typeArr.join(","));
    }
  }

  if (character) {
    const characterArr = Object.keys(character).filter((key) => character[key]);
    if (characterArr.length > 0) {
      params.set("character", characterArr.join(","));
    }
  }

  if (franchise) {
    const franchiseArr = Object.keys(franchise).filter((key) => franchise[key]);
    if (franchiseArr.length > 0) {
      params.set("franchise", franchiseArr.join(","));
    }
  }

  if (signedType) {
    const signedTypeArr = Object.keys(signedType).filter(
      (key) => signedType[key],
    );
    if (signedTypeArr.length > 0) {
      params.set("signed_type", signedTypeArr.join(","));
    }
  }

  if (hasRank(rank)) {
    params.set("rank", rank);
  }

  return params;
}

const defaultFilters = {
  order: [
    { id: "ca", name: "Oldest" },
    { id: "cd", name: "Newest" },
    { id: "pa", name: "Price: Low to High" },
    { id: "pd", name: "Price: High to Low" },
    { id: "ra", name: "Most Popular" },
  ],
  tags: [],
  talents: [],
  type: [],
  character: [],
  franchise: [],
};

const defaultPagination = {
  previousPageId: null,
  currentPageId: null,
  nextPageId: null,
  totalResults: 0,
  currentResults: 0,
  pageSize: 24,
  hasNext: false,
  hasPrevious: false,
};

export function usePresignedProducts(filter = defaultFilter) {
  const params = paramsFor(filter);
  const { data, error, isValidating, mutate } = useSWR(
    `/api/product/presigned?${params}`,
    fetcher,
    { keepPreviousData: true },
  );

  const products = React.useMemo(() => {
    if (!G.isArray(data?.products) || A.isEmpty(data.products)) {
      return [];
    }

    return data.products.map(Product.from);
  }, [data]);

  const pagination = React.useMemo(() => {
    return data?.pagination || defaultPagination;
  }, [data]);

  return {
    error,
    isError: Boolean(error),
    isLoading: !data && !error,
    isValidating,
    mutate,
    products,
    pagination,
  };
}

export function usePresignedFilters() {
  const { data, error, isValidating, mutate } = useSWR(
    `/api/product/presignedFilters`,
    fetcher,
    { keepPreviousData: true },
  );

  const filters = React.useMemo(() => {
    return data?.filters || defaultFilters;
  }, [data]);

  return {
    error,
    filters,
    isError: Boolean(error),
    isLoading: !data && !error,
    isValidating,
    mutate,
  };
}

const SIMPLE_UPDATE = "simple-update";
const AGGREGATE_UPDATE = "aggregate-update";
const PRODUCT_ERROR = "product-error-update";
const NEW_USER_OBJECT_SET = "new-user-object-set";
const NEW_USER_OBJECT_CLEAR = "new-user-object-clear";

export const STEPS = ["Shipping", "Order Confirmation"];

export function isStep(stepNumber) {
  return (step) => step === stepNumber;
}

export const FIRST_STEP = 0;
export const SECOND_STEP = 1;
export const isFirstStep = isStep(FIRST_STEP);
export const isSecondStep = isStep(SECOND_STEP);
export const PAYMENT_METHODS = Object.freeze(
  Object.seal({
    PAYPAL: "paypal",
    STRIPE: "stripe",
    GOOGLE_PAY: "google-pay",
    AFFIRM: "affirm",
    SAVED_CARD: "saved-card",
  }),
);

export const ERRORS = Object.seal(
  Object.freeze({
    FORM: "error",
    PRODUCT: "productErrors",
  }),
);

export const conventionAddress = {
  first_name: "San Diego",
  last_name: "Comic-Con",
  country: "US",
  zipCode: "92101",
  state: "CA",
  city: "San Diego",
  address: "111 W Harbor Dr",
};

/*
 * default state of the checkout page
 */
export const initialState = {
  currentStep: 0,
  address: {},
  purchase: null,
  contactInformation: {
    email: "",
    canContact: true,
  },
  isFormValid: false,
  isLoading: false,
  [ERRORS.FORM]: null,
  [ERRORS.PRODUCT]: {},
  useSavedCard: false,
  paymentMethod: PAYMENT_METHODS.STRIPE,
  createdAccount: false,
  linkedMessage: "",
  newUserObject: null,
};

export function checkoutReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case SIMPLE_UPDATE: {
      if (
        !Array.isArray(payload.value) &&
        typeof payload.value === "object" &&
        typeof payload.value !== "undefined" &&
        payload.value !== null
      ) {
        return {
          ...state,
          [payload.key]: { ...state[payload.key], ...payload.value },
        };
      }

      return { ...state, [payload.key]: payload.value };
    }

    case AGGREGATE_UPDATE: {
      return { ...state, ...payload };
    }

    case PRODUCT_ERROR: {
      const { key, value } = payload;

      const productErrors = { ...state[ERRORS.PRODUCT] };

      if (value) {
        productErrors[key] = value.message;
      } else {
        // eslint-disable-next-line prefer-reflect
        delete productErrors[key];
      }

      return { ...state, [ERRORS.PRODUCT]: productErrors };
    }

    case NEW_USER_OBJECT_SET: {
      return { ...state, newUserObject: payload };
    }

    case NEW_USER_OBJECT_CLEAR: {
      return { ...state, newUserObject: null };
    }

    default:
      return state;
  }
}

export function addressUpdate(address) {
  return { type: SIMPLE_UPDATE, payload: { key: "address", value: address } };
}

export function contactInformationUpdate(contactInformation) {
  return {
    type: SIMPLE_UPDATE,
    payload: { key: "contactInformation", value: contactInformation },
  };
}

export function formValidityUpdate(isFormValid) {
  return {
    type: SIMPLE_UPDATE,
    payload: { key: "isFormValid", value: isFormValid },
  };
}

export function purchaseUpdate(purchase) {
  return { type: SIMPLE_UPDATE, payload: { key: "purchase", value: purchase } };
}

export function loadingUpdate(isLoading) {
  return {
    type: SIMPLE_UPDATE,
    payload: { key: "isLoading", value: isLoading },
  };
}

export function currentStepUpdate(currentStep) {
  return {
    type: SIMPLE_UPDATE,
    payload: { key: "currentStep", value: currentStep },
  };
}

export function savedCardUpdate(value) {
  return {
    type: SIMPLE_UPDATE,
    payload: { key: "useSavedCard", value },
  };
}

export function paymentMethodUpdate(value) {
  return {
    type: SIMPLE_UPDATE,
    payload: { key: "paymentMethod", value },
  };
}

export function newUserObjectUpdate(value) {
  return { type: NEW_USER_OBJECT_SET, payload: value };
}

export function newUserObjectClear() {
  return { type: NEW_USER_OBJECT_CLEAR, payload: null };
}

export function linkedMessageUpdate(value) {
  return { type: SIMPLE_UPDATE, payload: { key: "linkedMessage", value } };
}

const timeouts = {};

export function createErrorUpdate(dispatch, { message, time }) {
  const key = ERRORS.FORM;

  if (timeouts[key]) {
    clearTimeout(timeouts[key]);
  }

  dispatch({
    type: SIMPLE_UPDATE,
    payload: { key, value: { message } },
  });

  if (Number.isFinite(time)) {
    timeouts[key] = setTimeout(() => {
      dispatch({ type: SIMPLE_UPDATE, payload: { key, value: null } });
    }, time);
  }
}

export function createProductErrorUpdate(
  dispatch,
  { message, time, productId },
) {
  const key = `${ERRORS.PRODUCT}${productId}`;

  if (timeouts[key]) {
    clearTimeout(timeouts[key]);
  }

  dispatch({
    type: PRODUCT_ERROR,
    payload: { key: productId, value: { message } },
  });

  if (Number.isFinite(time)) {
    timeouts[key] = setTimeout(() => {
      dispatch({
        type: PRODUCT_ERROR,
        payload: { key: productId, value: null },
      });
    }, time);
  }
}

export function clearError(dispatch, key) {
  if (timeouts[key]) {
    clearTimeout(timeouts[key]);
  }

  dispatch({ type: SIMPLE_UPDATE, payload: { key, value: null } });
}

export function aggregateUpdate(payload) {
  return { type: AGGREGATE_UPDATE, payload };
}

import * as React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { useTracking } from "../../hooks";
import {
  CheckoutProvider,
  CheckoutForm,
  CheckoutSummary,
} from "../../components/Checkout";

/*
 * Checkout renders the new checkout page. This is divided into two sections:
 * Right and Left, which are rendered as "top" or "bottom" for mobile. The right
 * side is a presentational section showing the order summary. The left side has
 * all the logic for creating subscriptions, accounts, etc.
 */
const Checkout = React.memo(function Checkout() {
  const { refreshSession } = useTracking();

  React.useEffect(() => {
    refreshSession();
  }, [refreshSession]);

  React.useEffect(() => {
    function onResize() {
      const doc = document.documentElement;
      doc.style.setProperty("--container-height", `${window.innerHeight}px`);
    }

    window.addEventListener("resize", onResize);

    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <CheckoutProvider>
      <StyledBox>
        <StyledRight item xs={12} lg={5}>
          <CheckoutSummary />
        </StyledRight>

        <StyledLeft item xs={12} lg={7}>
          <CheckoutForm />
        </StyledLeft>
      </StyledBox>
    </CheckoutProvider>
  );
});

Checkout.propTypes = {
  state: PropTypes.object,
  dispatch: PropTypes.func,
};

const StyledBox = styled(Box)(({ theme }) => ({
  height: "var(--container-height, 100vh)",
  width: "100vw",
  overflowY: "scroll",
  overflowX: "hidden",
  position: "relative",
  background: theme.palette.grey[200],
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("lg")]: {
    display: "block",
    flexDirection: "unset",
  },
  boxSizing: "border-box",
}));

const StyledLeft = styled(Grid)(({ theme }) => ({
  paddingLeft: "4vw",
  paddingRight: "4vw",
  [theme.breakpoints.up("sm")]: {
    paddingLeft: "6vw",
    paddingRight: "6vw",
  },
  [theme.breakpoints.up("md")]: {
    paddingLeft: "8vw",
    paddingRight: "8vw",
  },
  [theme.breakpoints.up("lg")]: {
    paddingLeft: "10vw",
    paddingRight: 0,
    position: "absolute",
    left: 0,
    right: "40vw",
    top: 0,
    minHeight: "100vh",
    flexGrow: "unset",
  },
  flexGrow: 1,
  flex: 1,
  background: "white",
  boxSizing: "border-box",
}));

const StyledRight = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    marginRight: "10vw",
    paddingLeft: 0,
    top: 0,
    right: 0,
    left: "60vw",
    position: "absolute",
  },
  pointerEvents: "none",
  flexBasis: "fit-content",
  boxSizing: "border-box",
}));

export default Checkout;

import * as React from "react";
import { useHistory } from "react-router-dom";
import { useCart, useGtag, useTracking } from "../../hooks";
import { realisticConfetti } from "../../util";
import * as utils from "../../components/Checkout/utils";

export default function SuccessPage() {
  const { track } = useTracking();
  const gtag = useGtag();
  const cart = useCart();
  const history = useHistory();

  React.useEffect(() => {
    const {
      orderNumber,
      totalPurchaseAmount,
      isVip,
      didPurchaseSubscription,
      email,
    } = utils.extractTrackingInfoFrom(window.location);

    // gtag("event", "purchase", {
    //   transaction_id: orderNumber,
    //   affiliation: "streamily",
    //   value: totalPurchaseAmount,
    //   currency: "USD",
    //   items: Object.values(cart.items).map((cartProduct) => ({
    //     id: cartProduct.productId,
    //     name: cartProduct.name,
    //     price: cartProduct.price.value,
    //     quantity: 1,
    //   })),
    // });

    track(utils.actionFor({ email, isVip, didPurchaseSubscription }));
    track("revenue", totalPurchaseAmount);
  }, [cart.items, gtag, track, history]);

  // TODO add success page logic

  React.useEffect(() => {
    realisticConfetti();
  }, []);

  return null;
}

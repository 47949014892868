import * as React from "react";
import PropTypes from "prop-types";
import { Link, Box, Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { useCurrentUser } from "../../../../hooks";
import AuthModal from "../../../AuthModal";

/*
 * EmailSection collects the user's email
 */
const EmailSection = React.memo(function EmailSection() {
  const currentUser = useCurrentUser();
  const [showAuthModal, setShowAuthModal] = React.useState(false);

  const handleLogin = React.useCallback(() => {
    setShowAuthModal(true);
  }, []);

  const onClose = React.useCallback(() => {
    setShowAuthModal(false);
  }, []);

  return (
    <>
      {!currentUser.email && (
        <StyledGrid
          item
          xs
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Box pt={0.25}>
            <Typography variant="body2">
              Already have an account?&nbsp;
              <StyledLinkButton
                onClick={handleLogin}
                type="button"
                title="Login to your account"
                typographyVariant="body2"
              >
                Login
              </StyledLinkButton>
            </Typography>
          </Box>
        </StyledGrid>
      )}

      {showAuthModal && <AuthModal isLoggingIn onClose={onClose} />}
    </>
  );
});

EmailSection.propTypes = {
  dispatch: PropTypes.func,
};

const StyledLinkButton = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  font: "inherit",
  color: theme.palette.text.primary,
  "&:hover": {
    cursor: "pointer",
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  justifyContent: "flex-end",
  [theme.breakpoints.down("xs")]: {
    justifyContent: "flex-start",
  },
}));

export default EmailSection;

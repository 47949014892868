import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Chip } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Addon, CartProduct } from "../../../../domain";
import * as shopUtils from "../../../Shop/utils";
import { useCheckoutErrors } from "../../CheckoutProvider";
import { useCart } from "../../../../hooks";

const CartProductElement = React.memo(function CartProduct({ cartProduct }) {
  const { cart } = useCart();
  const { productErrors } = useCheckoutErrors();

  const specialMessage = React.useMemo(() => {
    if (cartProduct.addons.find(Addon.isVideoMessageAddon)) {
      return (
        <StyledMessage variant="body2">
          Record your Video Message to {cart.influencer.name} after checkout!
        </StyledMessage>
      );
    }
  }, [cartProduct, cart]);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs="auto">
        <StyledImage src={cartProduct.imageUrl} alt={cartProduct.name} />
      </Grid>

      <Grid item xs>
        <StyledTitle variant="subtitle2" component="p">
          {cartProduct.name}
        </StyledTitle>

        <StyledTypography component="p">
          {shopUtils.signerTextFor(cartProduct)}
        </StyledTypography>

        <StyledTypography component="p">
          {cartProduct.productType}
        </StyledTypography>

        <StyledTypography component="p">
          <strong>To: </strong>
          {cartProduct.personalizationTo}
        </StyledTypography>

        <StyledTypography component="p">
          <strong>Special instructions: </strong>
          {cartProduct.notes || "None"}
        </StyledTypography>
      </Grid>

      <Grid item>
        <StyledPrice variant="body1">
          {cartProduct.subtotal.format()}
        </StyledPrice>
      </Grid>

      <Grid item container spacing={1}>
        {cartProduct.addons.map((addon) => (
          <Grid item key={addon.name}>
            <Chip size="small" label={addon.title} />
          </Grid>
        ))}
      </Grid>

      {Boolean(specialMessage) && <Grid item>{specialMessage}</Grid>}

      {cartProduct.productId in productErrors && (
        <Grid item container alignItems="center">
          <Typography variant="caption" color="error">
            {productErrors[cartProduct.productId]}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
});

CartProductElement.propTypes = {
  cartProduct: PropTypes.instanceOf(CartProduct),
};

const StyledImage = styled("img")(({ theme }) => ({
  borderRadius: theme.spacing(1),
  height: "8rem",
  width: "8rem",
  verticalAlign: "middle",
  objectFit: "cover",
}));

const StyledMessage = styled(Typography)(() => ({
  color: "#9E5050",
  fontWeight: 500,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 600,
  color: theme.palette.text.primary,
  margin: 0,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  margin: 0,
  lineHeight: 1.35,
  "& strong": {
    color: theme.palette.text.secondary,
  },
}));

const StyledPrice = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
}));

export default CartProductElement;

import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export default function StatusMessage({ message, type, size, onClick }) {
  return (
    <StyledTypography
      variant="caption"
      className={clsx(type, size)}
      role="alert"
      aria-roledescription="Shows an important status message"
      style={{ cursor: onClick ? "pointer" : "inherit" }}
      onClick={onClick}
    >
      {message}
    </StyledTypography>
  );
}

StatusMessage.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(["error", "success", "info", "grey"]),
  size: PropTypes.oneOf(["large"]),
  onClick: PropTypes.func,
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  "&.error": {
    color: theme.palette.error.main,
  },
  "&.success": {
    color: theme.palette.green.main,
  },
  "&.warning": {
    color: theme.palette.yellow.main,
  },
  "&.grey": {
    color: theme.palette.grey[500],
  },
  "&.large": {
    fontSize: theme.typography.body2.fontSize,
    fontFamily: theme.typography.body2.fontFamily,
  },
}));

export const errorStatus = (errors) => ({
  message: errors.map((err) => err.message).join(", "),
  type: "error",
});

export const successStatus = (message) => ({ message, type: "success" });
export const warningStatus = (message) => ({ message, type: "warning" });

import { Box, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import * as React from "react";
import { Cart } from "../../../../domain";
import { useCart, useCartAttributes, useCurrentUser } from "../../../../hooks";
import { Form } from "../../../../theme";
import { useCheckoutErrors } from "../../CheckoutProvider";
import { clearError, ERRORS } from "../../state";
import { shouldShowAffirm, useScrollAtom } from "../../utils";
import AffirmIcons from "../AffirmIcons";
import NonVipSelection from "./NonVipSelection";
import PickupSelection from "./PickupSelection";
import VipSelection from "./VipSelection";
import VipSelectionPickup from "./VipSelectionPickup";

/*
 * ChooseShipping renders the form section that presents the user with the
 * option of selecting a VIP subscription to get free shipping on their first
 * order.
 */
const ChooseShipping = React.memo(function ChooseShipping({
  showPickupChoice,
  shipmentRequired,
}) {
  const shippingChoiceRef = React.useRef(null);
  const { cart } = useCart();
  const { cartAttributes, refresh } = useCartAttributes();
  const { dispatch } = useCheckoutErrors();
  const currentUser = useCurrentUser();
  const { isStreamilyFamilyMember } = currentUser;
  const { pickupAtConvention, purchaseSubscription } = cartAttributes;
  const [scroll, setScroll] = useScrollAtom();

  /*
   * onClickVip sets the shipping choice to VIP Shipping (ie. purchaseSubscription = true)
   */
  const onClickVip = React.useCallback(() => {
    clearError(dispatch, ERRORS.FORM);

    const updates = {
      pickupAtConvention: false,
      purchaseSubscription: true && !isStreamilyFamilyMember,
    };

    Cart.update(updates).then(() => refresh(updates));
  }, [dispatch, refresh, isStreamilyFamilyMember]);

  /*
   * onClickNonVip sets the shipping choice to standard shipping, (ie. purchaseSubscription = false)
   */
  const onClickNonVip = React.useCallback(() => {
    clearError(dispatch, ERRORS.FORM);

    const updates = {
      pickupAtConvention: false,
      purchaseSubscription: false && !isStreamilyFamilyMember,
    };

    Cart.update(updates).then(() => refresh(updates));
  }, [dispatch, refresh, isStreamilyFamilyMember]);

  /*
   * onClickPickup sets the shipping choice to null, and sets
   * state.pickupAtConvention to true so as to indicate the user wishes to pickup
   * their order from a convention
   */
  const onClickPickup = React.useCallback(() => {
    clearError(dispatch, ERRORS.FORM);

    const updates = {
      pickupAtConvention: true,
      purchaseSubscription: false,
    };

    Cart.update(updates).then(() => refresh(updates));
  }, [dispatch, refresh]);

  React.useEffect(() => {
    if (isStreamilyFamilyMember && purchaseSubscription) {
      const updates = { purchaseSubscription: false };
      Cart.update(updates).then(() => refresh(updates));
    }
  }, [isStreamilyFamilyMember, purchaseSubscription, refresh]);

  React.useEffect(() => {
    if (scroll === "chooseAffirm") {
      shippingChoiceRef.current?.scrollIntoView({ behavior: "smooth" });
      setScroll("");
    }
  }, [scroll, setScroll]);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        component="header"
        container
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Form.Header component="h1" gutterBottom>
            Choose {shipmentRequired ? "Shipping" : "Membership"}
          </Form.Header>

          {shouldShowAffirm(cart) && !isStreamilyFamilyMember && (
            <Grid item>
              <Box
                display="flex"
                alignItems="center"
                gridGap="16px"
                maxWidth="72ch"
              >
                <AffirmIcons />
                <Typography variant="body2" color="textSecondary">
                  Affirm, Klarna and AfterPay are not available when joining the
                  Streamily Family Membership.
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>

      {/*
        This has two similar but different VIP Selection options, one which is
        the regular button that offers a descriptive upsell for no vip members,
        and the other for VIP members who need an option vs pickupAtConvention
        */}
      {(!showPickupChoice ||
        (showPickupChoice && !isStreamilyFamilyMember)) && (
        <Grid item xs={12}>
          <VipSelection
            shipmentRequired={shipmentRequired}
            selected={purchaseSubscription === true}
            onClick={onClickVip}
            disabled={isStreamilyFamilyMember}
          />
        </Grid>
      )}

      {isStreamilyFamilyMember && showPickupChoice && (
        <Grid item xs={12}>
          <VipSelectionPickup
            shipmentRequired={shipmentRequired}
            selected={!pickupAtConvention && isStreamilyFamilyMember}
            onClick={onClickNonVip}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <NonVipSelection
          ref={shippingChoiceRef}
          shipmentRequired={shipmentRequired}
          disabled={isStreamilyFamilyMember}
          selected={purchaseSubscription === false && !pickupAtConvention}
          onClick={onClickNonVip}
        />
      </Grid>

      {showPickupChoice && (
        <Grid item xs={12}>
          <PickupSelection
            shipmentRequired={shipmentRequired}
            selected={pickupAtConvention}
            onClick={onClickPickup}
          />
        </Grid>
      )}
    </Grid>
  );
});

ChooseShipping.propTypes = {
  showPickupChoice: PropTypes.bool,
  shipmentRequired: PropTypes.bool,
};

export default ChooseShipping;

import React from "react";
import PropTypes from "prop-types";
import "./popular_product.css";
import { ProductBlock } from "../commonComponent";
import SectionWrapper from "../commonComponent/SectionWrapper";

const PopularProduct = React.memo(function PopularProduct({
  data,
  screenWidth,
  isLoading = true,
}) {
  return (
    <SectionWrapper
      data={data}
      screenWidth={screenWidth}
      isLoading={isLoading}
      blockWidth={180}
      blockHeight={212}
      margin={16}
      title="Popular Products"
    >
      {ProductBlock}
    </SectionWrapper>
  );
});

PopularProduct.propTypes = {
  data: PropTypes.array,
  screenWidth: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default PopularProduct;

import * as React from "react";
import PropTypes from "prop-types";
import { Typography, Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { FIRST_STEP, STEPS, SECOND_STEP } from "../../state";
import clsx from "clsx";

const StepperElement = React.memo(function StepperElement({ currentStep }) {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <StyledTypography
          className={clsx(currentStep === FIRST_STEP && "active")}
        >
          {STEPS[FIRST_STEP]}
        </StyledTypography>
      </Grid>

      <Grid item>
        <ChevronRightIcon fontSize="small" />
      </Grid>

      <Grid item>
        <StyledTypography
          className={clsx(currentStep === SECOND_STEP && "active")}
        >
          {STEPS[SECOND_STEP]}
        </StyledTypography>
      </Grid>
    </Grid>
  );
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  "&.active": {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

StepperElement.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string),
  currentStep: PropTypes.number,
};

export default StepperElement;

import * as React from "react";

export function MailIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 1L11.8 14.2"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 1L16.6 25L11.8 14.2L1 9.4L25 1Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function UserCheckIcon() {
  return (
    <svg
      width="32"
      height="27"
      viewBox="0 0 32 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4545 25.5455V22.8182C21.4545 21.3715 20.8799 19.9842 19.8569 18.9612C18.834 17.9383 17.4466 17.3636 16 17.3636H6.45455C5.00791 17.3636 3.62053 17.9383 2.5976 18.9612C1.57467 19.9842 1 21.3715 1 22.8182V25.5455"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2273 11.9091C14.2397 11.9091 16.6818 9.46701 16.6818 6.45455C16.6818 3.44208 14.2397 1 11.2273 1C8.21479 1 5.77271 3.44208 5.77271 6.45455C5.77271 9.46701 8.21479 11.9091 11.2273 11.9091Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.8182 11.9091L25.5455 14.6364L31.0001 9.18182"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function MoneyIcon() {
  return (
    <svg
      width="16"
      height="26"
      viewBox="0 0 16 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.54541 1V25"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 5.36364H4.81818C3.80554 5.36364 2.83437 5.76591 2.11832 6.48196C1.40227 7.198 1 8.16917 1 9.18182C1 10.1945 1.40227 11.1656 2.11832 11.8817C2.83437 12.5977 3.80554 13 4.81818 13H10.2727C11.2854 13 12.2565 13.4023 12.9726 14.1183C13.6886 14.8344 14.0909 15.8055 14.0909 16.8182C14.0909 17.8308 13.6886 18.802 12.9726 19.518C12.2565 20.2341 11.2854 20.6364 10.2727 20.6364H1"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function NextIcon() {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13L7 7L1 1"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DownIcon() {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L7 7L13 1"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { Box, Button, Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useCart } from "../../../../hooks";
import { ErrorUI, Form, LoadingUI } from "../../../../theme";
import { addWordBreakHints } from "../../../../util";

const AddressSummary = React.memo(function AddressSummary({ handleBack }) {
  const { cart, isError, isLoading } = useCart();

  if (isLoading) {
    return <LoadingUI />;
  }

  if (isError) {
    return <ErrorUI message="Unable to load cart address" />;
  }

  const { address } = cart;

  return (
    <Form.StyledGrid container spacing={1}>
      <Form.StyledGridItem container alignItems="center" position="bottom">
        <Grid item xs="auto">
          <Box ml={1} width="4rem">
            <StyledHeader variant="subtitle2">Contact</StyledHeader>
          </Box>
        </Grid>

        <Grid item xs>
          <Box px={1}>
            {addWordBreakHints([".", "_", "-", "@"])([address.email])}
          </Box>
        </Grid>

        <Grid item xs="auto">
          <StyledButton onClick={handleBack} size="small">
            Change
          </StyledButton>
        </Grid>
      </Form.StyledGridItem>

      <Form.StyledGridItem container alignItems="center">
        <Grid item xs="auto">
          <Box ml={1} width="4rem">
            <StyledHeader variant="subtitle2">Ship To</StyledHeader>
          </Box>
        </Grid>

        <Grid item xs>
          <Box px={1}>
            <Typography variant="body2">
              <span>
                {address.firstName} {address.lastName},{" "}
              </span>
              <span>{address.address}, </span>
              {Boolean(address.address2) && <span>{address.address2}, </span>}
              <span>
                {address.city}, {address.state} {address.country}{" "}
              </span>
              {address.zipCode}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs="auto">
          <StyledButton onClick={handleBack} size="small">
            Change
          </StyledButton>
        </Grid>
      </Form.StyledGridItem>
    </Form.StyledGrid>
  );
});

AddressSummary.propTypes = {
  handleBack: PropTypes.func,
};

const StyledHeader = styled("h6")(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: theme.typography.fontWeightMedium,
  margin: 0,
  padding: 0,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0.5),
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(0.25),
  },
}));

export default AddressSummary;

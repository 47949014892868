import { Box } from "@material-ui/core";
import { atom, useAtom } from "jotai";
import * as React from "react";
import { Addon } from "../../domain";
import { TYPES } from "../../errors";
import { generateId, ifEmpty, localStorageUtils, toBoolean } from "../../util";
import AffirmIcons from "./Form/AffirmIcons";

const AFFIRM_MIN_AMOUNT = 200;

export function shouldShowAffirm(cart) {
  return Boolean(
    cart?.totals?.total.value >= AFFIRM_MIN_AMOUNT &&
      (cart?.address?.country === "US" ||
        cart?.address?.country === "United States"),
  );
}

export const scrollAtom = atom("");

export function useScrollAtom() {
  return useAtom(scrollAtom);
}

const vipShippingRateAdjustement = 14;

const undefinedIfEmpty = ifEmpty(undefined);

const five = /^([0-9]{5})$/;
const ten = /^([0-9]{5}-[0-9]{4})$/;

/*
 * isValidZip returns if a zipCode is a valid US zip
 *
 * @param {string} zipCode
 * @returns {boolean} result
 */
export function isValidZip(zipCode) {
  return five.test(zipCode) || ten.test(zipCode);
}

/*
 * createPurchaseInfo returns a purchaseInfo object for creating either
 * a paymentIntent or a subscription
 *
 * @param {object} initialization object { state }
 * @returns {object} purchaseInfo
 */
export function createPurchaseInfo({ state, currentUser, cart }) {
  const { email } = currentUser;
  const { contactInformation } = state;
  const { purchaseSubscription, cartProducts } = cart;
  const hasVideoMessageAddon = Boolean(
    cartProducts.find((cartProduct) =>
      cartProduct.addons.find(Addon.isVideoMessageAddon),
    ),
  );

  return {
    type: "autographPurchase",
    credentials: { email: undefinedIfEmpty(contactInformation.email) },
    createAccount: (purchaseSubscription || hasVideoMessageAddon) && !email,
  };
}

/*
 * composeParams composes the redirect URLSearchParams for a successful
 * stripe.confirmPayment() call.
 *
 * @param {object} initialization object
 *
 * {
 *   route,
 *   orderNumber,
 *   shopName,
 *   email,
 *   accountAlreadyExists,
 *   isStreamilyFamilyMember,
 *   didPurchaseSubscription,
 *   totalPurchaseAmount
 * }
 *
 * @returns {URLSearchParams} params
 */
export function composeParams({
  accountAlreadyExists,
  createdAccount,
  didLinkAccount,
  didPurchaseSubscription,
  email,
  hasVideoMessageAddon,
  isStreamilyFamilyMember,
  orderNumber,
  route,
  shopName,
  signedTypes,
  totalPurchaseAmount,
}) {
  const sp = new URLSearchParams({
    amount: totalPurchaseAmount,
    created_account: createdAccount,
    did_purchase_subscription: didPurchaseSubscription,
    is_vip: isStreamilyFamilyMember,
    linked_account: didLinkAccount,
    nc: true,
    order_number: orderNumber,
    route,
    shop_name: shopName,
    signed_types: signedTypes.join(","),
    tuuid: generateId(52),
  });

  if (email) {
    sp.set("email", email);
  }

  if (typeof accountAlreadyExists !== "undefined") {
    sp.set("exist_email", accountAlreadyExists);
  }

  if (hasVideoMessageAddon) {
    sp.set("has_video_message_addon", true);
  }

  return sp;
}

/*
 * extractTrackingInfoFrom returns a trackingInfo object from a location string
 *
 * @param {string} location
 * @returns {object} trackingInfo
 */
export function extractTrackingInfoFrom(location) {
  const searchParams = new URLSearchParams(location);
  const email = searchParams.get("email");
  const orderNumber = searchParams.get("order_number");
  const totalPurchaseAmount = Number.parseInt(searchParams.get("amount"), 10);
  const isVip = toBoolean(searchParams.get("is_vip"));
  const didPurchaseSubscription = toBoolean(
    searchParams.get("did_purchase_subscription"),
  );

  return {
    orderNumber,
    totalPurchaseAmount,
    isVip,
    didPurchaseSubscription,
    email,
  };
}

/*
 * createCancelPurchaseInfo creates purchase info for cancelling a paymentIntent
 * or subscription
 *
 * @param {object} initialization object { state }
 * @returns {object} cancelPurchaseInfo
 */
export function createCancelPurchaseInfo({ state }) {
  const { purchase } = state;

  return {
    type: "autographPurchase",
    client_secret: purchase?.clientSecret,
    payment_intent_id: purchase?.paymentIntentId,
    stripe_customer_id: purchase?.stripeCustomerId,
    subscription_id: purchase?.subscriptionId,
  };
}

export function setGuestPurchaseToken(guestPurchaseToken) {
  localStorageUtils.setWithExpiry("guestToken", guestPurchaseToken);
}

/*
 * actionFor returns a globalTrackingAction for a purchase object
 *
 * @param {object} purchase
 * @returns {string} action
 */
export function actionFor({ email, isVip, didPurchaseSubscription }) {
  if (!isVip && didPurchaseSubscription) {
    return "nonsubscriber-subscription-and-purchase-success";
  }

  if (email && isVip) {
    return "subscriber-purchase-success";
  }

  return "nonsubscriber-purchase-success";
}

/*
 * labelForDefaultCard returns the label for a defaultCard
 *
 * @param {object} defaultSource { type: string, last4: string }
 * @returns {string} label
 */
export function labelForDefaultCard(defaultSource) {
  return defaultSource.card?.last4
    ? `Saved Card (ending in ${defaultSource.card?.last4})`
    : `Saved Payment Method (type ${defaultSource.type})`;
}

/*
 * labelForOtherPaymentMethod returns the label for another payment method
 *
 * @param {object} defaultSource
 * @returns {string} label
 */
export function labelForOtherPaymentMethod(cart) {
  if (!shouldShowAffirm(cart) || cart?.purchaseSubscription) {
    return "Credit Card";
  }

  return (
    <Box display="flex" alignItems="center" gridGap="16px">
      <span>Credit Card</span>
      <AffirmIcons size="small" />
    </Box>
  );
}

/*
 * shipmentOptionsFor returns whether shipment is required or should show
 * shipment choice for a cart
 *
 * @param {object} cart
 * @returns {object} shipmentOptions { shipmentRequired, showShipmentChoice }
 */
export function shipmentOptionsFor(cart) {
  if (!cart) {
    return { shipmentRequired: true, showPickupChoice: false };
  }

  return cart.cartProducts.reduce(
    (result, cartProduct) => {
      if (cartProduct.hasNoShipping) {
        result.shipmentRequired = false;
      }

      if (cartProduct.hasConventionTag) {
        result.showPickupChoice = true;
      }

      return result;
    },
    {
      shipmentRequired: true,
      showPickupChoice: false,
    },
  );
}

const propName = ({ propertyName, dataPath } = {}) => {
  if (propertyName) {
    return propertyName;
  }

  const last = dataPath?.split(".")?.pop();

  if (last) {
    return last;
  }

  return "Unknown";
};

export function errorMessageFrom(errors) {
  const [error] = errors;

  if (error.message.includes('should match format "password"')) {
    return `Password must be at least 6 characters long`;
  }

  if (errors.length > 1 && error.propertyName) {
    return errors
      .map(
        ({ propertyName, dataPath, message }) =>
          `${propName({ propertyName, dataPath })} invalid: ${message}`,
      )
      .join(", ");
  }

  switch (error.type) {
    case TYPES.CREATE_USER_ERROR:
      return error.message;

    case TYPES.CREATE_SIMPLE_PURCHASE_ERROR:
      return error.message;

    case TYPES.CREATE_SUBSCRIPTION_PURCHASE_ERROR:
      return error.message;

    default:
      return `Whoops! ${errors
        .map((err) => `${propName(err)} ${err.message}`)
        .join(", ")}`;
  }
}

/*
 * hasValidShippingChoice returns if state has valid shipping choice
 *
 * @param {object} cartAttributes
 * @returns {boolean} result
 */
export function hasValidShippingChoice(cartAttributes) {
  return (
    cartAttributes.pickupAtConvention ||
    (!cartAttributes.pickupAtConvention &&
      (cartAttributes.purchaseSubscription === true ||
        cartAttributes.purchaseSubscription === false))
  );
}

/*
 * createPromoText returns a function that returns promo text
 *
 * @param {string} FREE Shipping text
 * @param {string} Almost free shipping text
 *
 * @returns {function} promoTextFor { cart, currentUser, state }
 */
export function createPromoText(freeText, almostFreeText) {
  return function promoTextFor({ cart }) {
    const { totals } = cart;
    const { unadjustedShippingCost } = totals;

    return unadjustedShippingCost.value === 0 ||
      unadjustedShippingCost.value <= vipShippingRateAdjustement
      ? freeText
      : `$${vipShippingRateAdjustement} ${almostFreeText}`;
  };
}

export const vipPromoTextFor = createPromoText("FREE", "Off");

export const errorPromoTextFor = createPromoText(
  "Free Shipping?",
  "Off Shipping?",
);

export function setCartProducts(cartProducts) {
  localStorage.setItem("STREAMILY::CART::KEY", JSON.stringify(cartProducts));
}

import * as React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@material-ui/core";
import { PaymentElement as StripePaymentElement } from "@stripe/react-stripe-js";
import { logger } from "../../../../util";
import { useCart, useCurrentUser, useStripe } from "../../../../hooks";
import { Addon } from "../../../../domain";
import { Form } from "../../../../theme";
import {
  loadingUpdate,
  createErrorUpdate,
  formValidityUpdate,
  PAYMENT_METHODS,
} from "../../state";
import { useCheckout } from "../../CheckoutProvider";
import * as utils from "../../utils";

const PaymentElement = React.memo(function PaymentElement({
  onSubmit,
  setOnSubmit,
  setOnUpdate,
  onUpdate,
}) {
  const { cart, isLoading, isError } = useCart();
  const currentUser = useCurrentUser();
  const { stripe, elements } = useStripe();
  const { state, dispatch } = useCheckout();
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    if (!elements || typeof elements.fetchUpdates !== "function") {
      return;
    }

    if (!onUpdate && typeof elements.fetchUpdates === "function") {
      setOnUpdate(elements.fetchUpdates);
    }
  }, [onUpdate, setOnUpdate, elements]);

  const onPurchase = React.useCallback(
    async (evt) => {
      evt.preventDefault();

      if (!ready) {
        return;
      }

      if (isLoading || isError) {
        return createErrorUpdate(dispatch, {
          message: "Something went wrong",
          time: 5000,
        });
      }

      if (state.paymentMethod !== PAYMENT_METHODS.STRIPE) {
        return createErrorUpdate(dispatch, {
          message: "Payment method not yet available",
          time: 5000,
        });
      }

      if (!state.useSavedCard && !state.isFormValid) {
        return createErrorUpdate(dispatch, {
          message: "Payment details are not complete.",
          time: 5000,
        });
      }

      dispatch(loadingUpdate(true));

      try {
        const { purchase } = state;
        const { totals, address, influencer, cartProducts } = cart;
        const { isStreamilyFamilyMember, email } = currentUser;

        utils.setCartProducts(cartProducts); // For gtag on autograph-purchase-success.hbs
        const signedTypes = cartProducts.map(
          (cartProduct) => cartProduct.signedType,
        );

        const hasVideoMessageAddon = cartProducts.find((cartProduct) =>
          cartProduct.addons.find(Addon.isVideoMessageAddon),
        );

        const didPurchaseSubscription = Boolean(purchase.subscriptionId);

        const params = utils.composeParams({
          accountAlreadyExists: purchase.accountAlreadyExists,
          createdAccount: purchase.didCreateAccount,
          didLinkAccount: purchase.didLinkAccount,
          didPurchaseSubscription,
          email: !email || purchase.didLinkAccount ? address.email : null,
          hasVideoMessageAddon,
          isStreamilyFamilyMember:
            Boolean(isStreamilyFamilyMember) || didPurchaseSubscription,
          orderNumber: purchase.orderNumber,
          route: influencer.route,
          shopName: influencer.name,
          signedTypes,
          totalPurchaseAmount: totals.rawTotal,
        });

        const successUrl = `${window.location.origin}/${influencer.route}/Success?${params}`;
        const returnUrl = `${window.location.origin}/api/purchases/verifyPaymentIntent?${params}`;

        /*
         * With the elements, stripe handles the redirect
         */
        if (state.useSavedCard) {
          const { error } = await stripe.confirmCardPayment(
            state.purchase.clientSecret,
          );

          if (error) {
            throw error;
          }

          window.location.assign(successUrl);
        } else {
          const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: { return_url: returnUrl },
          });

          if (error) {
            throw error;
          }

          window.location.assign(successUrl);
        }
      } catch (err) {
        logger.error(err);

        if (err.type === "card_error") {
          createErrorUpdate(dispatch, {
            message: composeErrorMessage(err),
            time: 10000,
          });
        } else {
          createErrorUpdate(dispatch, {
            message: "Unable to create purchase. Please try again",
            time: 8000,
          });
        }

        dispatch(loadingUpdate(false));
      }
    },
    [
      ready,
      state,
      dispatch,
      stripe,
      elements,
      cart,
      isError,
      isLoading,
      currentUser,
    ],
  );

  const handleChange = React.useCallback(
    (evt) => {
      if (evt.complete) {
        dispatch(formValidityUpdate(true));
      } else {
        dispatch(formValidityUpdate(false));
      }
    },
    [dispatch],
  );

  React.useEffect(() => {
    if (onSubmit !== onPurchase) {
      setOnSubmit(() => onPurchase);
    }
  }, [onSubmit, setOnSubmit, onPurchase]);

  return (
    <Grid item>
      <Form.StyledDarkBackground>
        <Box p={2}>
          <StripePaymentElement
            onReady={() => setReady(true)}
            onChange={handleChange}
          />
        </Box>
      </Form.StyledDarkBackground>
    </Grid>
  );
});

PaymentElement.propTypes = {
  onSubmit: PropTypes.func,
  setOnSubmit: PropTypes.func,
  onUpdate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  setOnUpdate: PropTypes.func,
};

function composeErrorMessage(err) {
  return `${err.message} Please try again.`;
}

export default PaymentElement;

import * as React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Form, DarkSmallHeading } from "../../../../theme";

const PickupSelection = React.memo(function PickupSelection({
  onClick,
  selected,
  disabled,
  label = "Convention",
}) {
  if (disabled) {
    return null;
  }

  return (
    <StyledButton onClick={onClick} className={selected ? "selected" : ""}>
      <Box pr={1.5}>
        <Form.Radio color="default" checked={selected} />
      </Box>

      <DarkSmallHeading>Pickup at {label}</DarkSmallHeading>
    </StyledButton>
  );
});

PickupSelection.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.grey[300]}`,
  width: "100%",
  padding: theme.spacing(2),
  textAlign: "left",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  transition: "background 0.2s ease",
  "&.selected": {
    boxShadow: `0 0 0 3px ${theme.palette.grey.main}`,
  },
}));

export default PickupSelection;

import * as React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import { Cart, Addon } from "../../../../domain";
import { StreamilyButton } from "../../../../theme";
import { useCartProduct, useCurrentUser } from "../../../../hooks";
import { VipAddon } from "../../../../domain/addon";

const ProductModalAddonButton = React.memo(function ProductModalAddonButton({
  addon,
  productId,
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const currentUser = useCurrentUser();

  const {
    cartProduct,
    isLoading: isLoadingCartProduct,
    refresh,
  } = useCartProduct(productId);

  const has = React.useMemo(() => {
    return Boolean(
      cartProduct?.addons?.find((cartProductAddon) => {
        return cartProductAddon.name === addon.name;
      }),
    );
  }, [cartProduct, addon.name]);

  const onClick = React.useCallback(async () => {
    setIsLoading(true);

    try {
      if (has) {
        await Cart.removeProductAddon({
          productId,
          addonName: addon.name,
        });
      } else {
        await Cart.addProductAddon({
          productId,
          addonName: addon.name,
        });
      }

      await refresh();
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  }, [has, addon.name, productId, refresh]);

  const isLoadingAll = isLoading || isLoadingCartProduct;

  const disabled =
    addon.name === VipAddon.TYPE && currentUser.isStreamilyFamilyMember;

  return (
    <StreamilyButton
      size="small"
      variant="contained"
      color="primary"
      className={has ? "offline" : ""}
      onClick={onClick}
      disabled={disabled || isLoadingAll}
      style={{ minHeight: "2.5rem" }}
    >
      {contentFor({
        disabled,
        disabledText: "Included with VIP",
        isLoading: isLoadingAll,
        isAdded: has,
      })}
    </StreamilyButton>
  );
});

ProductModalAddonButton.propTypes = {
  addon: PropTypes.instanceOf(Addon).isRequired,
  productId: PropTypes.number.isRequired,
};

function contentFor({ disabled, disabledText, isLoading, isAdded }) {
  if (isLoading) {
    return <CircularProgress size="1.5rem" />;
  }

  if (disabled) {
    return disabledText;
  }

  if (isAdded) {
    return "Remove";
  }

  return "Add";
}

export default ProductModalAddonButton;

export const handleSortBySigned = (arr) => {
  const sortedArray = [];
  arr.forEach((val) => {
    if (!val.signed) {
      sortedArray.push(val);
    }
  });
  arr.forEach((val) => {
    if (val.signed) {
      sortedArray.push(val);
    }
  });
  return sortedArray;
};

const handleSortByCreated = (arr) => {
  const sortedArr = arr.sort((a, b) => {
    return new Date(a.created) - new Date(b.created);
  });
  return sortedArr;
};

export const handleSortByPriority = (arr) => {
  const unsignedSales = arr.filter((val) => !val.signed);
  let signedSales = arr.filter((val) => val.signed);
  let vipAndSubscribed = [];
  let vipAndNotSubscribed = [];
  let notVipAndSubscribed = [];
  let notVipAndNotSubscribed = [];
  let none = [];
  const tempArray = [];
  let videoRecordingAddon = [];
  unsignedSales.forEach((val) => {
    if (val.is_vip && val.subscribed_user_id) {
      vipAndSubscribed.push(val);
    } else if (val.is_vip && !val.subscribed_user_id) {
      vipAndNotSubscribed.push(val);
    } else if (!val.is_vip && val.subscribed_user_id) {
      notVipAndSubscribed.push(val);
    } else if (val.video_recording_addon) {
      videoRecordingAddon.push(val);
    } else if (!val.is_vip && !val.subscribed_user_id) {
      notVipAndNotSubscribed.push(val);
    }
  });

  //Sorting for all Arrays by created date
  vipAndSubscribed = handleSortByCreated(vipAndSubscribed);
  vipAndNotSubscribed = handleSortByCreated(vipAndNotSubscribed);
  notVipAndSubscribed = handleSortByCreated(notVipAndSubscribed);
  videoRecordingAddon = handleSortByCreated(videoRecordingAddon);
  notVipAndNotSubscribed = handleSortByCreated(notVipAndNotSubscribed);
  none = handleSortByCreated(none);
  signedSales = handleSortByCreated(signedSales);

  //Setting all arrays in respective order
  tempArray.push(...vipAndSubscribed);
  tempArray.push(...vipAndNotSubscribed);
  tempArray.push(...notVipAndSubscribed);
  tempArray.push(...videoRecordingAddon);
  tempArray.push(...notVipAndNotSubscribed);
  tempArray.push(...signedSales);

  tempArray.forEach((val) => {
    if (!arr.includes(val)) {
      none.push(val);
    }
  });
  tempArray.push(...none);
  return tempArray;
};

export const handleSortByVideoCapture = (arr) => {
  const sortedArr = arr.sort((a, b) => {
    return new Date(a.created) - new Date(b.created);
  });
  const withAddOn = [];
  const withoutAddOn = [];
  sortedArr.forEach((val) => {
    if (val.video_recording_addon) {
      withAddOn.push(val);
    } else {
      withoutAddOn.push(val);
    }
  });
  const responseArray = [...withAddOn, ...withoutAddOn];
  return responseArray;
};

export function sortData({ data, currentSigner, filter }) {
  if (data && data[currentSigner] && data[currentSigner].sales) {
    let newArray = [];
    if (filter.unpersonalized) {
      const input = data[currentSigner].sales.filter(
        (val) => !val.notes && !val.personalization_to,
      );
      newArray = handleSortBySigned(input);
    } else {
      newArray = handleSortBySigned(data[currentSigner].sales);
    }
    // newArray = handleSortByPriority(newArray);
    let incomplete = [];
    const complete = [];
    newArray.forEach((val) => {
      if (val.signed) {
        complete.push(val);
      } else {
        incomplete.push(val);
      }
    });
    // complete = handleSortByPriority(complete);
    incomplete = handleSortByPriority(incomplete);
    return [...complete, ...incomplete];
  }
}

// shuffleArray shuffles an array (NOTE: mutates)
export function shuffleArray(array) {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // eslint-disable-next-line no-param-reassign
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

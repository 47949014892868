import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import AuthModal from "../../AuthModal";
import LeftArrow from "../../../assets/left-arrow.png";
import RightArrow from "../../../assets/right-arrow.png";

import { formatDateRangeReverseTZ } from "../../../util/formatDateRange";
import { formatDateStrTimeReverseTZ } from "../../../util/formatSingleDate";
import { useCurrentUser, useMatchesXsDown } from "../../../hooks";
import { Influencer } from "../../../domain";
import { PreloadLink } from "../../../theme";

const fabHeart = <FavoriteIcon />;
const farHeart = <FavoriteBorderIcon />;

const Wrapper = ({ item, children }) => {
  const influencer = Influencer.from(item);
  const className = "browse-v2-link";

  return (
    <PreloadLink
      isReactLink={influencer.isOpen}
      to={`/${influencer.route}`}
      className={className}
      endpoints={[
        influencer.endpoints.influencerByRoute,
        influencer.endpoints.influencerEvents,
      ]}
    >
      {children}
    </PreloadLink>
  );
};

export const NextBtn = (props) => {
  const { className, onClick, currentSlide, slideCount, count = 0 } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{ display: currentSlide + count >= slideCount && "none" }}
    >
      <img
        alt=""
        src={RightArrow}
        style={{
          width: "16px",
          height: "16px",
        }}
      />
    </div>
  );
};

export const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{ display: currentSlide === 0 && "none" }}
    >
      <img
        alt=""
        src={LeftArrow}
        style={{
          width: "16px",
          height: "16px",
        }}
      />
    </div>
  );
};

export const LiveBlock = React.memo(function LiveBlock({
  item,
  className = "",
  margin = 0,
  width,
  height,
  isSlider,
  showLive,
}) {
  const [favourited, toggleFavourited] = useState(item.is_favourite);
  const [open, setOpen] = useState(false);
  const matchesXs = useMatchesXsDown();
  const currentUser = useCurrentUser();
  const isLoggedIn = Boolean(currentUser?.userId);

  const openDialogHandler = (isOpen) => {
    setOpen(isOpen);
  };

  const updateFavouriteStatus = async () => {
    try {
      await axios.post("/api/favourites/status", {
        influencer_id: item.influencer_id,
        is_favourite: !favourited,
      });
    } catch (err) {
      // do nothing
    }
  };

  const onClose = async () => {
    setOpen(false);

    if (isLoggedIn) {
      await updateFavouriteStatus();
      toggleFavourited(!favourited);
    }
  };

  const handleFavourite = async (evt) => {
    evt.preventDefault();

    if (isLoggedIn) {
      await updateFavouriteStatus();
      toggleFavourited(!favourited);
    } else {
      openDialogHandler(true, true);
      toggleFavourited(false);
    }
  };

  return (
    <>
      <Wrapper item={item}>
        <CardBlock
          className={className}
          width={width}
          height={height}
          style={{
            marginLeft: margin,
            marginBottom: isSlider ? 0 : "20px",
          }}
        >
          <div className="liveItem_img" style={{ width }}>
            {showLive && (
              <>
                {item.is_live === 1 ? (
                  <div className="liveItem_container">
                    <span className="liveItem_container_text">LIVE</span>
                  </div>
                ) : (
                  <div className="tbaItem_container">
                    <span className="tbaItem_container_text">
                      {item.date_type === "Day & Time"
                        ? formatDateStrTimeReverseTZ(item.next_event_time)
                        : item.date_type === "Range"
                        ? formatDateRangeReverseTZ(
                            item.event_start_date,
                            item.event_end_date,
                          )
                        : "TBA"}
                    </span>
                  </div>
                )}
              </>
            )}
            {!matchesXs && (
              <div
                className={`btn-favourite ${favourited && "active"}`}
                onClick={handleFavourite}
              >
                {favourited ? fabHeart : farHeart}
              </div>
            )}
            <img
              className="liveItem_image"
              src={item.avatar_url}
              alt=""
              loading="lazy"
              width={width}
              height={height}
            />
          </div>
          <div
            className="liveItem_content"
            style={{ width, boxSizing: "border-box" }}
          >
            <div className="liveItem_content_name">{item.name}</div>
            <div className="liveItem_content_role">{item.famous_for}</div>
            <div className="liveItem_content_role">{item.famous_character}</div>
          </div>
        </CardBlock>
      </Wrapper>
      {open && (
        <AuthModal onClose={onClose} isLoggingIn={true} showSignupBtn={true} />
      )}
    </>
  );
});

export const FeaturedBlock = React.memo(function FeaturedBlock({
  item,
  className = "",
  margin = 0,
  width,
  height,
  isSlider,
}) {
  const [favourited, toggleFavourited] = useState(item.is_favourite);
  const [open, setOpen] = useState(false);
  const matchesXs = useMatchesXsDown();
  const currentUser = useCurrentUser();
  const isLoggedIn = Boolean(currentUser?.userId);

  const openDialogHandler = (isOpen) => {
    setOpen(isOpen);
  };

  const updateFavouriteStatus = async () => {
    try {
      await axios.post("/api/favourites/status", {
        influencer_id: item.influencer_id,
        is_favourite: !favourited,
      });
    } catch (err) {
      // do nothing
    }
  };

  const onClose = async () => {
    setOpen(false);

    if (isLoggedIn) {
      await updateFavouriteStatus();
      toggleFavourited(!favourited);
    }
  };

  const handleFavourite = async (evt) => {
    evt.preventDefault();

    if (isLoggedIn) {
      await updateFavouriteStatus();
      toggleFavourited(!favourited);
    } else {
      openDialogHandler(true, true);
      toggleFavourited(false);
    }
  };

  return (
    <>
      <Wrapper item={item}>
        <CardBlock
          className={className}
          width={width}
          height={height}
          style={{
            marginLeft: margin,
            marginBottom: isSlider ? 0 : "20px",
          }}
        >
          <div className="liveItem_img" style={{ width }}>
            {!matchesXs && (
              <div
                className={`btn-favourite ${favourited && "active"}`}
                onClick={handleFavourite}
              >
                {favourited ? fabHeart : farHeart}
              </div>
            )}
            <img
              className="liveItem_image"
              src={item.avatar_url}
              alt=""
              loading="lazy"
              width={width}
              height={height}
            />
          </div>
          <div
            className="liveItem_content"
            style={{ width, boxSizing: "border-box" }}
          >
            <div className="liveItem_content_name">{item.name}</div>
            <div className="liveItem_content_role">{item.famous_for}</div>
            <div className="liveItem_content_role">{item.famous_character}</div>
          </div>
        </CardBlock>
      </Wrapper>
      {open && (
        <AuthModal onClose={onClose} isLoggingIn={true} showSignupBtn={true} />
      )}
    </>
  );
});

export const SpecialShopsBlock = React.memo(function SpecialShopsBlock({
  item,
  className = "",
  margin = 0,
  width,
  height,
  isSlider,
  showLive,
}) {
  return (
    <>
      <Wrapper item={item}>
        <CardBlock
          className={className}
          width={width}
          height={height}
          style={{
            marginLeft: margin,
            marginBottom: isSlider ? 0 : "20px",
          }}
        >
          <div className="liveItem_img" style={{ width }}>
            {showLive && (
              <>
                {item.is_live === 1 ? (
                  <div className="liveItem_container">
                    <span className="liveItem_container_text">LIVE</span>
                  </div>
                ) : (
                  <div className="tbaItem_container">
                    <span className="tbaItem_container_text">
                      {item.date_type === "Day & Time"
                        ? formatDateStrTimeReverseTZ(item.next_event_time)
                        : item.date_type === "Range"
                        ? formatDateRangeReverseTZ(
                            item.event_start_date,
                            item.event_end_date,
                          )
                        : "TBA"}
                    </span>
                  </div>
                )}
              </>
            )}
            <img
              className="liveItem_image"
              src={item.avatar_url}
              alt=""
              loading="lazy"
              width={width}
              height={height}
            />
          </div>
          <div
            className="liveItem_content"
            style={{ width, boxSizing: "border-box" }}
          >
            <div className="liveItem_content_name">{item.name}</div>
            <div className="liveItem_content_role">{item.famous_for}</div>
            <div className="liveItem_content_role">{item.famous_character}</div>
          </div>
        </CardBlock>
      </Wrapper>
    </>
  );
});

export const FranchiseBlock = React.memo(function FranchiseBlock({
  item,
  className = "",
  margin = 0,
  width,
  height,
  isSlider,
}) {
  const [favourited, toggleFavourited] = useState(item.is_favourite);
  const [open, setOpen] = useState(false);
  const currentUser = useCurrentUser();
  const isLoggedIn = Boolean(currentUser?.userId);
  const matchesXs = useMatchesXsDown();

  const openDialogHandler = (isOpen) => {
    setOpen(isOpen);
  };

  const updateFavouriteStatus = async () => {
    try {
      await axios.post("/api/favourites/status", {
        influencer_id: item.influencer_id,
        is_favourite: !favourited,
      });
    } catch (err) {
      // do nothing
    }
  };

  const onClose = async () => {
    setOpen(false);

    if (isLoggedIn) {
      await updateFavouriteStatus();
      toggleFavourited(!favourited);
    }
  };

  const handleFavourite = async (evt) => {
    evt.preventDefault();

    if (isLoggedIn) {
      await updateFavouriteStatus();
      toggleFavourited(!favourited);
    } else {
      openDialogHandler(true, true);
      toggleFavourited(false);
    }
  };

  return (
    <>
      <Wrapper item={item}>
        <CardBlock
          className={className}
          width={width}
          height={height}
          style={{
            marginLeft: margin,
            marginBottom: isSlider ? 0 : "20px",
          }}
        >
          {!matchesXs && (
            <div
              className={`btn-favourite ${favourited && "active"}`}
              onClick={handleFavourite}
            >
              {favourited ? fabHeart : farHeart}
            </div>
          )}
          <div className="popular_franchiseItem_container">
            <ImgCard
              width={width}
              height={height}
              src={item.avatar_url}
              alt=""
              loading="lazy"
            />
            <div className="popular_franchiseItem_container_image_title">
              {item.name}
            </div>
          </div>
        </CardBlock>
      </Wrapper>
      {open && (
        <AuthModal onClose={onClose} isLoggingIn={true} showSignupBtn={true} />
      )}
    </>
  );
});

export const ProductBlock = React.memo(function ProductBlock({
  item,
  className = "",
  margin = 0,
  width,
  height,
  isSlider,
}) {
  return (
    <PreloadLink
      to={`/${item.route}`}
      className="browse-v2-link"
      endpoints={[
        `/api/influencer/byRoute/${item.route}`,
        `/api/influencerEvents/${item.influencer_id}`,
      ]}
    >
      <ProductCardBlock
        className={className}
        width={width}
        height={height}
        style={{
          marginLeft: margin,
          marginBottom: isSlider ? 0 : "20px",
        }}
      >
        <div className="popularItem_product_image" style={{ width }}>
          <span className="popularItem_product_price">${item.price / 100}</span>
          <img
            width={width}
            height={height}
            className="popularItem_product_image"
            src={item.image_url}
            alt=""
            loading="lazy"
          />
        </div>
        <div className="popularItem_product_content" style={{ width }}>
          <div className="popularItem_product_content_name">
            {item.name}
            <div className="popularItem_product_content_role">
              {item.subtext}
            </div>
            <div className="popularItem_product_content_role">
              {item.marketing_tag}
            </div>
          </div>
        </div>
      </ProductCardBlock>
    </PreloadLink>
  );
});

export const CardBlock = styled.div`
  box-sizing: border-box;
  background-color: #1b1b1b;
  color: white;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  --webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  --moz-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  ${(props) => `
    flex-basis: ${props.width}px;
    height: ${props?.height ? `${props.height}px` : "auto"}
  `}
  & img {
    margin: 0;
    padding: 0;
  }
`;

export const ImgCard = styled.img`
  border-radius: 5px;
  object-fit: cover;
  ${(props) => `
    width: ${props.width}px;
    height: ${props.height}px;
  `}
`;

export const ProductCardBlock = styled.div`
  padding: 6px 8px;
  background-color: #fff;
  border-radius: 8px;
  margin: 5px 0;
  position: relative;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  --webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  --moz-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  ${(props) => `
    width: ${props.width}px;
    height: ${props?.height ? `${props.height}px` : "212px"}
  `}
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 52px;
`;

export const SectionContainer = styled.div`
  ${(props) => `
      margin: ${props.isSlider ? "0px 0 30px" : "20px 0 40px"};
  `}
`;

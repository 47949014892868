import * as React from "react";
import PropTypes from "prop-types";
import { checkoutReducer, initialState } from "./state";
import { actionCreators, useDispatch } from "../../globalState";

const checkoutContext = React.createContext({});

/*
 * the CheckoutProvider wraps the checkout page in a "global" state. This
 * provides storage for the address information, shipping choice, as well as the
 * purchase init object returned from the call to service.createPurchase() once
 * the user is ready to proceed to payment.
 */
export default function CheckoutProvider({ children }) {
  const [state, dispatch] = React.useReducer(checkoutReducer, initialState);
  const globalDispatch = useDispatch();

  const value = React.useMemo(() => {
    return {
      state,
      dispatch,
    };
  }, [state, dispatch]);

  React.useEffect(() => {
    if (!state.newUserObject) {
      return;
    }

    const userObject = state.newUserObject;

    return () => {
      globalDispatch(actionCreators.createSetUser(userObject));
    };
  }, [globalDispatch, state.newUserObject]);

  return (
    <checkoutContext.Provider value={value}>
      {children}
    </checkoutContext.Provider>
  );
}

CheckoutProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/*
 * useCheckout returns the checkout context
 */
export function useCheckout() {
  return React.useContext(checkoutContext);
}

/*
 * useCheckoutAddress returns memoized address from the checkout context
 */
export function useCheckoutAddress() {
  const { state, dispatch } = useCheckout();

  return React.useMemo(() => {
    return {
      address: state.address,
      dispatch,
    };
  }, [state.address, dispatch]);
}

export function useContactInformation() {
  const { state, dispatch } = useCheckout();

  const { contactInformation } = state;
  const { email, createAccount, canContact, password } = contactInformation;

  return React.useMemo(() => {
    return {
      email,
      createAccount,
      canContact,
      password,
      dispatch,
    };
  }, [email, createAccount, canContact, password, dispatch]);
}

export function useCheckoutErrors() {
  const { state, dispatch } = useCheckout();

  const { error, productErrors } = state;

  return React.useMemo(() => {
    return {
      error,
      productErrors,
      dispatch,
    };
  }, [error, productErrors, dispatch]);
}

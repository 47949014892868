import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { Product } from "../../domain";
import { useActiveInfluencer, useCurrentUser } from "../../hooks";
import * as utils from "../Shop/utils";

const MAX_SIGNERS = 2;

const ProductText = React.memo(function ProductText({
  product,
  isDark,
  isStorefront,
}) {
  const activeInfluencer = useActiveInfluencer();
  const currentUser = useCurrentUser();

  const signers = React.useMemo(() => {
    if (!isDark) {
      const signerText = utils.signerTextFor(product);

      return (
        <p
          className="gradient-text elip no-margin no-wrap text-secondary"
          title={signerText}
        >
          {signerText}
        </p>
      );
    }

    const fulfillers = product.fulfillers
      .slice(0, MAX_SIGNERS)
      .map(({ name }) => name);

    const remainder = product.fulfillers.length - fulfillers.length;

    if (remainder > 0) {
      fulfillers.push(`and ${remainder} more..`);
    }

    return (
      <p
        className="gold-text elip no-margin no-wrap text-small"
        key="show more"
      >
        Signed by {fulfillers.join(", ")}
      </p>
    );
  }, [isDark, product]);

  const price = React.useMemo(() => {
    if (isStorefront) {
      return product?.subtotal.format();
    }

    if (currentUser.isAdmin || currentUser.isAgent) {
      return product?.price.format();
    }

    return product?.priceFor(activeInfluencer.influencerId).format();
  }, [isStorefront, product, currentUser, activeInfluencer]);

  return (
    <div className={clsx(isDark && "dark")}>
      <h4 className="product-text__name" title={product?.name}>
        {product?.name}
      </h4>

      <div className="product-text__details">
        {!product?.hasNoSignature && signers}

        {!product.hasNoSignature && (
          <span className={clsx("product-text__price", isDark && "dark")}>
            {price}
          </span>
        )}
      </div>

      {!product.hasNoSignature && (
        <p className="product-text__type">{product?.productType}</p>
      )}

      {product.hasNoSignature && (
        <div className="product-text__no-signature">
          <p className="product-text__type">{product?.productType}</p>
          <p className={clsx("product-text__price", isDark && "dark")}>
            {price}
          </p>
        </div>
      )}
    </div>
  );
});

ProductText.propTypes = {
  product: PropTypes.instanceOf(Product),
  isDark: PropTypes.bool,
  isStorefront: PropTypes.bool,
};

export default ProductText;

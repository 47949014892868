import * as React from "react";
import { Box, Collapse, Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCartOutlined";
import { ExpandButton, Logo } from "../../../../theme";
import SharedSummary from "../SharedSummary";
import { FIRST_STEP, SECOND_STEP } from "../../state";
import { useCheckout } from "../../CheckoutProvider";
import Total from "./Total";
import Subtotal from "./Subtotal";
import { useMatchesXsDown, useCart } from "../../../../hooks";

/*
 * SummaryHeader presents the mobile header summary of an order
 */
const SummaryHeader = React.memo(function SummaryHeader() {
  const matchesXs = useMatchesXsDown();
  const boxRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const { cart } = useCart();
  const { state } = useCheckout();
  const { currentStep } = state;

  const handleClick = React.useCallback(() => {
    setOpen((open) => !open);
  }, []);

  if (!cart?.totals) {
    return null;
  }

  return (
    <>
      <StyledGrid>
        <Logo height={matchesXs ? 42 : 64} />
      </StyledGrid>

      <StyledBox ref={boxRef} className={open ? "open" : ""}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item container xs alignItems="center" spacing={1}>
            <Grid item>
              <Box py={2}>
                <ShoppingCartIcon />
              </Box>
            </Grid>

            <Grid item>
              <ExpandButton
                onClick={handleClick}
                iconPosition="end"
                style={{ pointerEvents: "auto" }}
              >
                Show order summary
              </ExpandButton>
            </Grid>
          </Grid>

          <Grid item>
            {currentStep === FIRST_STEP && <Subtotal totals={cart.totals} />}

            {currentStep === SECOND_STEP && <Total totals={cart.totals} />}
          </Grid>
        </Grid>

        <Collapse in={open}>
          <SharedSummary />
        </Collapse>
      </StyledBox>
    </>
  );
});

const StyledGrid = styled(Grid)(({ theme }) => ({
  background: "#fff",
  paddingTop: theme.spacing(1),
  paddingLeft: "4vw",
  paddingRight: "4vw",
  [theme.breakpoints.up("sm")]: {
    paddingLeft: "6vw",
    paddingRight: "6vw",
  },
  [theme.breakpoints.up("md")]: {
    paddingLeft: "8vw",
    paddingRight: "8vw",
  },
  [theme.breakpoints.up("lg")]: {
    paddingTop: 0,
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  background: "#F8F8F8",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  "&.open": {
    paddingBottom: theme.spacing(4),
  },
  paddingLeft: "4vw",
  paddingRight: "4vw",
  [theme.breakpoints.up("sm")]: {
    paddingLeft: "6vw",
    paddingRight: "6vw",
  },
  [theme.breakpoints.up("md")]: {
    paddingLeft: "8vw",
    paddingRight: "8vw",
  },
}));

export default SummaryHeader;

import axios from "axios";
import { logger } from "../../util";

const endpoint = "/api/purchases";

/*
 * createPurchase creates either a paymentIntent or a subscription from
 * a purchaseInfo object.
 *
 * @param {object} purchaseInfo
 * @returns {object} purchase init with paymentIntent or subscriptionId
 */
export async function createPurchase(purchaseInfo) {
  const { data } = await axios.post(`${endpoint}/create`, purchaseInfo);
  return data;
}

/*
 * cancelPurchase deletes a paymentIntent or subscription when the user leaves
 * the checkout flow
 *
 * @param {object} cancelPurchaseInfo
 * @returns {object} cancellation result
 */
export async function cancelPurchase(cancelPurchaseInfo) {
  try {
    const { data } = await axios.post(`${endpoint}/delete`, cancelPurchaseInfo);

    return data;
  } catch (err) {
    logger.error(err);
    return null;
  }
}

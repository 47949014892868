import { G } from "@mobily/ts-belt";

const isValidValue = G.isNotNullable;

function looksLikeURL(maybeURL = "") {
  if (!maybeURL || !G.isString(maybeURL)) {
    return false;
  }

  try {
    new URL(maybeURL);
    return true;
  } catch {
    return maybeURL.startsWith("www.") || maybeURL.includes(".com");
  }
}

export { isValidValue, looksLikeURL };

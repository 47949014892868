import { Collapse, Box } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { B } from "@mobily/ts-belt";
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { Form, scrollbarStyles } from "../../theme";
import VirtualizedList from "../../theme/Virtualized/VirtualizedList";

const StyledHeader = styled("button")(({ theme }) => ({
  ...theme.typography.body2,
  alignItems: "center",
  background: "none",
  border: "none",
  cursor: "pointer",
  display: "flex",
  fontWeight: 500,
  gap: theme.spacing(1),
  margin: 0,
  marginBottom: theme.spacing(1),
  width: "100%",
  padding: "4px 8px 4px 0",
  borderRadius: 4,
  textAlign: "left",
  "&:hover": {
    background: `rgba(0, 0, 0, 0.04)`,
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  ...scrollbarStyles(theme),
}));

const ROW_HEIGHT = 40;

export default function Filter({
  expandedByDefault,
  getOptionLabel,
  getOptionValue,
  label,
  matchesSmDown,
  maxHeight = 7.5,
  options,
  search = true,
  selected,
  setSelected,
}) {
  const [isOpen, setIsOpen] = React.useState(
    matchesSmDown || expandedByDefault,
  );

  const [value, setValue] = React.useState("");

  const filteredOptions = React.useMemo(() => {
    return options.filter((option) =>
      getOptionLabel(option).toLowerCase().includes(value.toLowerCase().trim()),
    );
  }, [options, value, getOptionLabel]);

  return (
    <Box mt={matchesSmDown ? 2 : 3} mb={1}>
      <StyledHeader
        onClick={() => {
          setIsOpen(B.not);
        }}
      >
        {isOpen ? <ExpandLess /> : <ExpandMore />}
        {label}
      </StyledHeader>

      <Collapse in={isOpen}>
        {search && (
          <Form.Input
            fullWidth
            label={`Search ${label}`}
            type="text"
            size="small"
            variant="outlined"
            value={value || ""}
            onChange={(evt) => {
              setValue(evt.target.value);
            }}
            onBlur={(evt) => {
              setValue(evt.target.value.trim());
            }}
          />
        )}

        <StyledBox
          mt={1}
          ml={-2}
          display="flex"
          flexDirection="column"
          minHeight={Math.min(
            filteredOptions.length * ROW_HEIGHT,
            maxHeight * ROW_HEIGHT,
          )}
          overflow="auto"
        >
          <VirtualizedList
            rowHeight={ROW_HEIGHT}
            defaultWidth={200}
            rows={filteredOptions}
            rowRenderer={({ index, key, style }) => {
              const option = filteredOptions[index];
              return (
                <Box
                  alignItems="center"
                  display="flex"
                  height={ROW_HEIGHT}
                  key={key}
                  style={style}
                  px={2}
                >
                  <Form.SmallCheckbox
                    label={getOptionLabel(option)}
                    checked={!!selected[getOptionValue(option)]}
                    value={selected[getOptionValue(option)]}
                    onChange={(evt) => {
                      setSelected({
                        value: evt.target.checked,
                        key: getOptionValue(option),
                      });
                    }}
                  />
                </Box>
              );
            }}
          />
        </StyledBox>
      </Collapse>
    </Box>
  );
}

Filter.propTypes = {
  expandedByDefault: PropTypes.bool,
  getOptionLabel: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  matchesSmDown: PropTypes.bool.isRequired,
  maxHeight: PropTypes.number,
  options: PropTypes.array.isRequired,
  search: PropTypes.bool,
  selected: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
};

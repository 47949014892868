import * as React from "react";
import PropTypes from "prop-types";
import {
  useAvailableAddonsForProduct,
  useCurrentUser,
  useCartProduct,
} from "../../../../hooks";
import { Cart } from "../../../../domain";
import Addon, { VipAddon } from "../../../../domain/addon";
import ProductModalAddonButton from "./ProductModalAddonButton";
import Addons from "../../../Addons";

const ProductModalAddons = React.memo(function ProductModalAddons({
  productId,
}) {
  const currentUser = useCurrentUser();
  const {
    cartProduct,
    isLoading: isLoadingCartProduct,
    refresh,
  } = useCartProduct(productId);

  const { availableAddons, isLoading: isLoadingAddons } =
    useAvailableAddonsForProduct(productId);

  const isLoading = isLoadingAddons || isLoadingCartProduct;

  /*
   * If the user isStreamilyFamilyMember is true, it means they are a VIP
   * member and should automatically have the VIP addon added to their product.
   */
  React.useEffect(() => {
    if (!cartProduct) {
      return;
    }

    if (
      !isLoading &&
      availableAddons?.vipAddon &&
      currentUser.isStreamilyFamilyMember &&
      !cartProduct.addons.find(Addon.isVipAddon)
    ) {
      Cart.addProductAddon({ productId, addonName: VipAddon.TYPE }).then(() =>
        refresh(),
      );
    }
  }, [
    availableAddons,
    isLoading,
    currentUser,
    productId,
    cartProduct,
    refresh,
  ]);

  return (
    <Addons
      addons={availableAddons}
      ActionComponent={({ addon }) => (
        <ProductModalAddonButton addon={addon} productId={productId} />
      )}
    />
  );
});

ProductModalAddons.propTypes = {
  productId: PropTypes.number.isRequired,
};

export default ProductModalAddons;

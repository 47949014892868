import * as React from "react";
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import { Icons } from "../../../../theme";

export const paymentIcon = <PaymentOutlinedIcon />;

export const paypalIcon = (
  <img
    src="https://d3qdxs9s8vjwda.cloudfront.net/misc/pp-logo-100px.png"
    alt="PayPal"
    style={{ verticalAlign: "middle", objectFit: "contain" }}
    width={80}
    height={26}
  />
);

export const googlePayIcon = (
  <img
    src="https://d3qdxs9s8vjwda.cloudfront.net/misc/google-pay-mark.png"
    alt="Google Pay"
    style={{
      objectFit: "contain",
      objectPosition: "left",
      verticalAlign: "middle",
    }}
    width={100}
    height={26}
  />
);

export const affirmIcon = (
  <img
    src="https://d3qdxs9s8vjwda.cloudfront.net/misc/affirm.png"
    alt="Affirm Monthly Payments"
    style={{
      objectFit: "contain",
      objectPosition: "left",
      verticalAlign: "middle",
    }}
    width={150}
    height={26}
  />
);

export const creditCards = (
  <div style={{ display: "flex", justifyContent: "flex-end" }}>
    <Icons.Visa height={20} width={36} />
    <Icons.MasterCard height={20} width={36} />
    <Icons.Discover height={20} width={36} />
    <Icons.Amex height={20} width={36} />
  </div>
);

import * as React from "react";
import PropTypes from "prop-types";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { grey } from "@material-ui/core/colors";
import ErrorBoundaryWithFallback from "../errors";

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

if (!stripePublicKey) {
  throw new Error("Missing Stripe public key.");
}

const stripePromise = loadStripe(stripePublicKey, { apiVersion: "2019-10-08" });

const appearance = {
  theme: "flat",
  variables: {
    fontFamily: "Inter, Roboto, Helvetica, sans-serif",
    fontLineHeight: "19px",
    borderRadius: "5px",
    colorBackground: "#fff",
    colorPrimaryText: grey[800],
  },
  rules: {
    ".Block": {
      backgroundColor: "var(--colorBackground)",
      boxShadow: "none",
    },
    ".Input": {
      padding: "7.5px 10px",
      border: `1px solid ${grey[500]}`,
      fontFamily: "inherit",
      fontSize: "inherit",
      lineHeight: "19px",
    },
    ".Input:disabled, .Input--invalid:disabled": {
      color: grey[500],
    },
    ".Label": {
      fontWeight: "500",
    },
  },
};

export default function StripeProvider({ children, clientSecret }) {
  return (
    <ErrorBoundaryWithFallback>
      <Elements stripe={stripePromise} options={{ appearance, clientSecret }}>
        {children}
      </Elements>
    </ErrorBoundaryWithFallback>
  );
}

StripeProvider.propTypes = {
  children: PropTypes.node,
  clientSecret: PropTypes.string,
};

export function StripeDummyProvider({ children }) {
  return (
    <ErrorBoundaryWithFallback>
      <Elements stripe={stripePromise}>{children}</Elements>
    </ErrorBoundaryWithFallback>
  );
}

import * as React from "react";
import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export default function PageImage() {
  return (
    <StyledImage>
      <BgImage src="https://d3qdxs9s8vjwda.cloudfront.net/misc/dotbg.png" />

      <FgImage src="https://d3qdxs9s8vjwda.cloudfront.net/misc/referralpic.png" />
    </StyledImage>
  );
}

const StyledImage = styled(Box)(({ theme }) => ({
  position: "relative",
  height: 280,
  width: 349,
  [theme.breakpoints.down("sm")]: {
    height: 240,
    width: 300,
  },
}));

const BgImage = styled("img")(({ theme }) => ({
  position: "absolute",
  top: -theme.spacing(2),
  left: -theme.spacing(3),
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

const FgImage = styled("img")(({ theme }) => ({
  position: "absolute",
  borderRadius: theme.spacing(2),
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

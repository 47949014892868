import { Box, IconButton, Link, Typography, Button } from "@material-ui/core";
import { InfoRounded } from "@material-ui/icons";
import PropTypes from "prop-types";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { mutate } from "swr";
import { Influencer } from "../../../domain";
import { useUser } from "../../../globalState";
import {
  useInfluencer,
  useShippingAddress,
  useUserAccount,
} from "../../../hooks";
import { useLivestreamWarningContext } from "../../../pages/Dashboard/ShopEditor";
import { StreamilyToggle } from "../../../theme";
import { compose, isEmpty, noop, or, pick, some, values } from "../../../util";
import NotifyActionsModal from "../../NotifyActionsModal";
import { StyledSmallDialog } from "../../ShopBuilderProductModal/styles";

const EventDialog = React.lazy(() =>
  import("../../TalentDashboard/EventDialog"),
);

const SHIPPING_COLUMNS = [
  "first_name",
  "last_name",
  "address_primary",
  "city",
  "state",
  "country",
  "zip_code",
];

const defaultEmail = "partnerships@streamily.com";

const ShopStatus = React.memo(function ShopStatus({ route }) {
  const currentUser = useUser();
  const [isLoading, setIsLoading] = React.useState(false);
  const history = useHistory();
  const { influencer, refresh } = useInfluencer({
    route,
    forShopBuilder: true,
  });
  const { account } = useUserAccount(influencer?.userId);
  const { shippingAddress } = useShippingAddress(account?.shipping_address_id);
  const [isOpen, setIsOpen] = React.useState(false);
  const livestreamWarning = useLivestreamWarningContext();
  const [notifyProps, setNotifyProps] = React.useState(null);
  const [openEventDialog, setOpenEventDialog] = React.useState(false);
  const [closedShopDialogOpen, setClosedShopDialogOpen] = React.useState(false);

  const onChange = React.useCallback(() => {
    const updates = { sold_out: Boolean(influencer?.isOpen) };

    setIsLoading(true);
    setIsOpen((isOpen) => !isOpen);

    Influencer.update({ influencer, updates })
      .then((result) => {
        if (result?.errors) {
          setNotifyProps({
            type: "shopSetupError",
            title: "Oops, shop still needs some set-up! 😱",
            message: result.message,
            actions: [],
            customActions: ({ onClose }) => (
              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" color="secondary" onClick={onClose}>
                  OK
                </Button>
              </Box>
            ),
          });
        } else {
          refresh();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [influencer, refresh]);

  const verifyChange = React.useCallback(() => {
    if (isOpen || influencer.isGroupShop) {
      // closing shop, no tests
      // collections have no tests
      onChange();
      return;
    }

    const hasShippingError = compose(
      or(some(isEmpty), isEmpty),
      values,
      pick(SHIPPING_COLUMNS),
    )(shippingAddress);

    if (account && hasShippingError) {
      setNotifyProps({
        type: "shippingError",
        title: "Oops, we need your shipping address! 😱",
        message:
          "Please fill out your shipping address in Account Settings before opening your shop. This is where we will send your prints for your signing, please make sure it’s correct!",
        actions: ["Go to Account Settings"],
      });
    } else if (livestreamWarning) {
      // request to open shop, but has livestream products with no event set
      setNotifyProps({
        type: "livestreamError",
        title: "Oops! Looks like you don't have an event set",
        message:
          "Your Signed on Livestream products won't be shown to customers until you set an event.",
        actions: [
          "Schedule An Event",
          "Open my shop without Signed on Livestream products",
        ],
      });
    } else {
      // no errors
      onChange();
    }
  }, [
    account,
    influencer,
    onChange,
    isOpen,
    livestreamWarning,
    shippingAddress,
  ]);

  const notifyModalSave = React.useCallback(
    (actionIndex, actionText) => {
      if (actionText.indexOf("Account Settings") >= 0) {
        history.push("/Dashboard/Account-Settings");
      } else if (actionText.includes("shop split")) {
        // do nothing
      } else if (actionText.includes("shop owner")) {
        // do nothing
      } else {
        // livestreamWarning
        if (actionIndex === 0) {
          setOpenEventDialog(true);
        }
        if (actionIndex === 1) {
          onChange();
        }
      }
    },
    [onChange, history],
  );

  React.useEffect(() => {
    if (!isLoading) {
      setIsOpen(Boolean(influencer?.isOpen));
    }
  }, [isLoading, influencer?.isOpen]);

  const emailMailto = React.useMemo(() => {
    const email = influencer?.tcEmail || defaultEmail;
    const params = new URLSearchParams({ subject: "Help me set up my shop!" });

    if (email !== defaultEmail) {
      params.set("cc", defaultEmail);
    }

    return `mailto:${email}?${params}`;
  }, [influencer?.tcEmail]);

  const emailDisplayName = React.useMemo(() => {
    const email = influencer?.tcEmail || defaultEmail;

    return email;
  }, [influencer?.tcEmail]);

  return (
    <>
      {currentUser.isAdmin && (
        <StreamilyToggle
          label={
            <Typography variant="body2">
              Shop Status: <strong>{isOpen ? "OPEN" : "CLOSED"}</strong>
            </Typography>
          }
          color="secondary"
          disabled={!influencer || isLoading}
          checked={isOpen}
          onChange={verifyChange}
          labelPlacement="start"
        />
      )}

      {!currentUser.isAdmin && (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gridGap="4px"
        >
          <Typography variant="body2">
            Shop Status: <strong>{isOpen ? "OPEN" : "CLOSED"}</strong>{" "}
          </Typography>

          <IconButton
            size="small"
            onClick={() => setClosedShopDialogOpen(true)}
          >
            <InfoRounded fontSize="small" />
          </IconButton>

          {closedShopDialogOpen && (
            <StyledSmallDialog
              open={closedShopDialogOpen}
              onClose={() => setClosedShopDialogOpen(false)}
            >
              <Typography variant="body2" gutterBottom>
                <strong>Before you close your shop... </strong>
              </Typography>

              <Typography variant="body2" gutterBottom>
                Need a break from signing, or you&apos;re super busy right now?
                Streamily&apos;s got your back!
              </Typography>

              <Typography variant="body2" gutterBottom>
                We want to help you get the time off you need, and also continue
                to engage with your fans without lifting a pen. Instead of
                closing up shop, how about:
                <ul>
                  <li>Offering cool, unsigned merch? Fans love it!</li>
                  <li>
                    Pre-signed prints? They&apos;re a hit, especially for those
                    who missed your live events.
                  </li>
                </ul>
                Let us help you maximize every fan visit to your shop. If
                you&apos;re unsure about what to offer or how to keep things
                fresh, just drop us a message at{" "}
                <Link href={emailMailto}>{emailDisplayName}</Link>. We&apos;re
                here to support you!
              </Typography>
            </StyledSmallDialog>
          )}
        </Box>
      )}

      {notifyProps && (
        <NotifyActionsModal
          title={notifyProps.title}
          subtitle={notifyProps.message}
          open={notifyProps !== null}
          onClose={() => setNotifyProps(false)}
          onSave={notifyModalSave}
          actions={notifyProps.actions}
          customActions={notifyProps.customActions}
        />
      )}

      {openEventDialog && (
        <React.Suspense fallback={null}>
          <EventDialog
            open={openEventDialog}
            setOpen={setOpenEventDialog}
            onClose={(result) => {
              if (result) {
                // Event created!
                onChange();
                if (influencer) {
                  mutate(`/api/influencerEvents/${influencer.influencerId}`);
                }
              }
              setOpenEventDialog(false);
            }}
            setAddedItemToList={noop}
          />
        </React.Suspense>
      )}
    </>
  );
});

ShopStatus.propTypes = {
  route: PropTypes.string.isRequired,
};

export default ShopStatus;

import axios from "axios";
import { logger } from "../util";
import Address from "./address";
import Influencer from "./influencer";
import CartProduct from "./cartProduct";
import CartTotals from "./cartTotals";

const baseEndpoint = "/api/cart";

export default class Cart {
  /*
   * addProduct adds a product to the user cart
   *
   * @param {object} initialization object
   *   {
   *     productId: number,
   *     personalization: {
   *       notes: string,
   *       personalizationTo: string,
   *       personalizationAddonCharacterName: string,
   *       personalizationAddonQuote: string,
   *     },
   *   }
   *
   * @returns {object} result
   */
  static async addProduct({ productId, personalization = {} }) {
    try {
      const { data } = await axios.post(`${baseEndpoint}/${productId}`, {
        personalization,
      });

      return data;
    } catch (err) {
      logger.error(err);

      if (err.isAxiosError) {
        return err.response.data;
      }

      return {};
    }
  }

  /*
   * updateProduct updates a product in the user's cart
   *
   * @param {object} initialization object
   *   {
   *     productId: number,
   *     personalization: {
   *       notes: string,
   *       personalizationTo: string,
   *       personalizationAddonCharacterName: string,
   *       personalizationAddonQuote: string,
   *     },
   *   }
   *
   * @returns {object} result
   */
  static async updateProduct({ productId, personalization }) {
    try {
      const { data } = await axios.put(`${baseEndpoint}/${productId}`, {
        personalization,
      });

      return data;
    } catch (err) {
      logger.error(err);

      if (err.isAxiosError) {
        return err.response.data;
      }

      return {};
    }
  }

  /*
   * removeProduct removes a product from the user cart
   *
   * @param {number} productId
   * @returns {object} result
   */
  static async removeProduct(productId) {
    try {
      const { data } = await axios.delete(`${baseEndpoint}/${productId}`);

      return data;
    } catch (err) {
      logger.error(err);

      if (err.isAxiosError) {
        return err.response.data;
      }

      return {};
    }
  }

  /*
   * addProductAddon adds an addon to a cart product
   *
   * @param {object} initialization object { productId: number, addonName: string }
   * @returns {object} result
   */
  static async addProductAddon({ productId, addonName }) {
    try {
      const { data } = await axios.post(
        `${baseEndpoint}/${productId}/${addonName}`,
      );

      return data;
    } catch (err) {
      logger.error(err);

      if (err.isAxiosError) {
        return err.response.data;
      }

      return {};
    }
  }

  /*
   * removeProductAddon removes an addon from a cart product
   *
   * @param {object} initialization object { productId: number, addonName: string }
   * @returns {object} result
   */
  static async removeProductAddon({ productId, addonName }) {
    try {
      const { data } = await axios.delete(
        `${baseEndpoint}/${productId}/${addonName}`,
      );

      return data;
    } catch (err) {
      logger.error(err);

      if (err.isAxiosError) {
        return err.response.data;
      }

      return {};
    }
  }

  /*
   * clear clears a user cart
   *
   * @returns {object} result
   */
  static async clear() {
    try {
      const { data } = await axios.delete(baseEndpoint);

      return data;
    } catch (err) {
      logger.error(err);

      if (err.isAxiosError) {
        return err.response.data;
      }

      return {};
    }
  }

  /*
   * update updates a user cart with shop influencerId and trackingCode
   *
   * @param {object} updates object
   *   {
   *     influencerId: number,
   *     trackingCode: string,
   *     purchaseSubscription: boolean,
   *     savePaymentInformation: boolean,
   *     pickupAtConvention: boolean,
   *     canContact: boolean
   *   }
   * @returns {object} result
   */
  static async update(updates) {
    try {
      const { data } = await axios.put(baseEndpoint, updates);

      return data;
    } catch (err) {
      logger.error(err);

      if (err.isAxiosError) {
        return err.response.data;
      }

      return {};
    }
  }

  /*
   * updateAddress updates a user's cart address
   *
   * @param {object} newAddress
   * @returns {object} result
   */
  static async updateAddress(newAddress = {}) {
    try {
      const { data } = await axios.put(`${baseEndpoint}/address`, {
        address: newAddress,
      });

      return data;
    } catch (err) {
      logger.error(err);

      if (err.isAxiosError) {
        return err.response.data;
      }

      return {};
    }
  }

  static from(rawCart) {
    return new Cart(rawCart);
  }

  #rawCart;
  #address;
  #cartProducts;
  #influencer;
  #totals;

  constructor(rawCart) {
    const rawCartCopy = { ...rawCart };
    const purchaseSubscription = Boolean(rawCartCopy.purchaseSubscription);

    this.#rawCart = rawCartCopy;
    this.#address = Address.from(rawCartCopy.address);
    this.#cartProducts = rawCartCopy.cartProducts.map((cartProduct) =>
      CartProduct.from(cartProduct, purchaseSubscription),
    );
    this.#influencer = Influencer.from(rawCartCopy.influencer);
    this.#totals = CartTotals.from(rawCartCopy.totals ?? {});
  }

  get address() {
    return this.#address;
  }

  get cartProducts() {
    return this.#cartProducts;
  }

  get token() {
    return this.#rawCart.cartToken;
  }

  get influencer() {
    return this.#influencer;
  }

  get influencerId() {
    return this.#rawCart.influencerId;
  }

  get totals() {
    return this.#totals;
  }

  get trackingCode() {
    return this.#rawCart.trackingCode;
  }

  get userId() {
    return this.#rawCart.userId;
  }

  get purchaseSubscription() {
    return Boolean(this.#rawCart.purchaseSubscription);
  }

  get noShipmentChoice() {
    return Boolean(this.#rawCart.noShipmentChoice);
  }

  get savePaymentInformation() {
    return Boolean(this.#rawCart.savePaymentInformation);
  }

  get pickupAtConvention() {
    return Boolean(this.#rawCart.pickupAtConvention);
  }

  get canContact() {
    return Boolean(this.#rawCart.canContact);
  }

  get isEmpty() {
    return !Array.isArray(this.#cartProducts) || !this.#cartProducts.length;
  }

  mutate(key, value) {
    const rawCart = { ...this.#rawCart, [key]: value };
    return new Cart(rawCart);
  }

  mutateKeys(keyMap) {
    const rawCart = { ...this.#rawCart, ...keyMap };
    return new Cart(rawCart);
  }

  mutateKeysRaw(keyMap) {
    return { ...this.#rawCart, ...keyMap };
  }
}

import * as React from "react";
import PropTypes from "prop-types";
import { styled, withStyles } from "@material-ui/core/styles";
import { Typography, Chip } from "@material-ui/core";
import SignType from "../../domain/signType";

const SignTypeBadge = React.memo(function SignTypeBadge({ label, signedType }) {
  const hasNewBadge = !!SignType.backgroundColor(signedType);

  if (hasNewBadge) {
    return (
      <ConditionalStyledChip
        style={{ backgroundColor: SignType.backgroundColor(signedType) }}
        label={
          <ConditionalStyledTypography>{label}</ConditionalStyledTypography>
        }
      />
    );
  }

  return <StyledChip label={<StyledTypography>{label}</StyledTypography>} />;
});

SignTypeBadge.propTypes = {
  label: PropTypes.string.isRequired,
  signedType: PropTypes.string.isRequired,
};

const StyledChip = withStyles((theme) => ({
  root: {
    background: theme.gradient.grey,
    height: "unset",
  },
  label: {
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1.5)}px`,
  },
}))(Chip);

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.interFontStack,
  fontSize: theme.typography.pxToRem(11),
  fontWeight: theme.typography.semiBold,
  lineHeight: theme.typography.pxToRem(13),
  background:
    "linear-gradient(90deg, #FFE68E 0%, #C2A049 100%), linear-gradient(90deg, #FFC48E 0%, #B491FF 100%), #FFFFFF;",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
}));

const ConditionalStyledChip = withStyles((theme) => ({
  root: {
    height: "unset",
  },
  label: {
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1.5)}px`,
  },
}))(Chip);

const ConditionalStyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.interFontStack,
  fontSize: theme.typography.pxToRem(11),
  fontWeight: theme.typography.semiBold,
  lineHeight: theme.typography.pxToRem(13),
  color: "#FFFFFF",
}));

export const TopLeft = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(0.5), // postion modified for error icon
  left: theme.spacing(0.75),
}));

export const TopRight = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(0.75),
  right: theme.spacing(0.75),
}));

export default SignTypeBadge;

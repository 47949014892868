import React from "react";
import styled from "styled-components";

const Testimonials = () => {
  return (
    <Container>
      <VideoContainer>
        <OnboardingVideo
          controls
          playsInline
          src={
            "https://d3668kru679vnq.cloudfront.net/misc/talent-onboarding-video-uncompressed.mov"
          }
        ></OnboardingVideo>
      </VideoContainer>
    </Container>
  );
};

export default Testimonials;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgb(166, 180, 205), rgb(194, 171, 197));
`;

const VideoContainer = styled.div`
  width: 95%;
  max-width: 800px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  overflow: hidden;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  @media only screen and (max-height: 700px) {
    max-width: 700px;
  }
  @media only screen and (max-height: 600px) {
    max-width: 600px;
  }
  @media only screen and (max-height: 500px) {
    max-width: 500px;
  }
  @media only screen and (max-height: 450px) {
    max-width: 400px;
  }
  @media only screen and (max-height: 350px) {
    max-width: 300px;
  }
  @media only screen and (max-height: 300px) {
    max-width: 200px;
  }
`;

const OnboardingVideo = styled.video`
  width: 100%;
  height: auto;
`;

import Money from "./money";

export default class CartTotals {
  static from(rawCartTotals) {
    return new CartTotals(rawCartTotals);
  }

  #rawCartTotals;

  constructor(rawCartTotals = {}) {
    this.#rawCartTotals = { ...rawCartTotals };
  }

  get total() {
    if (
      Number.isInteger(this.#rawCartTotals.total) &&
      Number.isInteger(this.#rawCartTotals.subscriptionAmount)
    ) {
      return Money.for({
        value:
          (this.#rawCartTotals.total + this.#rawCartTotals.subscriptionAmount) /
          100,
        currency: "usd",
      });
    }

    return Money.ZERO_USD;
  }

  get rawTotal() {
    return this.#rawCartTotals?.total ?? 0;
  }

  get subtotal() {
    if (Number.isInteger(this.#rawCartTotals.subtotal)) {
      return Money.for({
        value: this.#rawCartTotals.subtotal / 100,
        currency: "usd",
      });
    }

    return Money.ZERO_USD;
  }

  get shippingCost() {
    if (Number.isInteger(this.#rawCartTotals.shippingCost)) {
      return Money.for({
        value: this.#rawCartTotals.shippingCost / 100,
        currency: "usd",
      });
    }

    return Money.ZERO_USD;
  }

  get unadjustedShippingCost() {
    if (Number.isInteger(this.#rawCartTotals.unadjustedShippingCost)) {
      return Money.for({
        value: this.#rawCartTotals.unadjustedShippingCost / 100,
        currency: "usd",
      });
    }

    return Money.ZERO_USD;
  }

  get shippingRateAdjustment() {
    if (Number.isInteger(this.#rawCartTotals.shippingRateAdjustment)) {
      return Money.for({
        value: this.#rawCartTotals.shippingRateAdjustment / 100,
        currency: "usd",
      });
    }

    return Money.ZERO_USD;
  }

  get tax() {
    if (Number.isInteger(this.#rawCartTotals.tax)) {
      return Money.for({
        value: this.#rawCartTotals.tax / 100,
        currency: "usd",
      });
    }

    return Money.ZERO_USD;
  }

  get serviceFee() {
    if (Number.isInteger(this.#rawCartTotals.serviceFee)) {
      return Money.for({
        value: this.#rawCartTotals.serviceFee / 100,
        currency: "usd",
      });
    }

    return Money.ZERO_USD;
  }

  get subscriptionAmount() {
    if (Number.isInteger(this.#rawCartTotals.subscriptionAmount)) {
      return Money.for({
        value: this.#rawCartTotals.subscriptionAmount / 100,
        currency: "usd",
      });
    }

    return Money.ZERO_USD;
  }

  get productBump() {
    if (Number.isInteger(this.#rawCartTotals.productBump)) {
      return Money.for({
        value: this.#rawCartTotals.productBump / 100,
        currency: "usd",
      });
    }

    return Money.ZERO_USD;
  }

  get hasSubscription() {
    return Boolean(this.#rawCartTotals.hasSubscription);
  }

  get isDomesticOnly() {
    return Boolean(this.#rawCartTotals.isDomesticOnly);
  }

  get hasFreeShipping() {
    return Boolean(this.#rawCartTotals.hasFreeShipping);
  }

  get hasNoServiceFee() {
    return Boolean(this.#rawCartTotals.hasNoServiceFee);
  }

  get hasNoTax() {
    return Boolean(this.#rawCartTotals.hasNoTax);
  }

  get hasNoShipping() {
    return Boolean(this.#rawCartTotals.hasNoShipping);
  }

  get currency() {
    return this.#rawCartTotals.currency;
  }

  toJSON() {
    return {
      total: this.total.toJSON(),
      subtotal: this.subtotal.toJSON(),
      shippingCost: this.shippingCost.toJSON(),
      shippingRateAdjustment: this.shippingRateAdjustment.toJSON(),
      tax: this.tax.toJSON(),
      serviceFee: this.serviceFee.toJSON(),
      subscriptionAmount: this.subscriptionAmount.toJSON(),
      productBump: this.productBump.toJSON(),
      hasSubscription: this.hasSubscription,
      isDomesticOnly: this.isDomesticOnly,
      hasFreeShipping: this.hasFreeShipping,
      hasNoServiceFee: this.hasNoServiceFee,
      hasNoTax: this.hasNoTax,
      hasNoShipping: this.hasNoShipping,
      currency: this.currency,
    };
  }
}

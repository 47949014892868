import React from "react";
import PropTypes from "prop-types";
import "../live/live.css";
import { LiveBlock } from "../commonComponent";
import SectionWrapper from "../commonComponent/SectionWrapper";

const PopularCharacters = React.memo(function PopularCharacters({
  data,
  screenWidth,
  isLoading = true,
}) {
  return (
    <SectionWrapper
      data={data}
      screenWidth={screenWidth}
      isLoading={isLoading}
      title={"Popular Characters"}
      showSlider={true}
    >
      {(props) => <LiveBlock {...props} />}
    </SectionWrapper>
  );
});

PopularCharacters.propTypes = {
  data: PropTypes.array,
  screenWidth: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default PopularCharacters;

import { Box, Button, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseRounded";
import PropTypes from "prop-types";
import * as React from "react";
import { ExtraSmallAvatar } from "../../theme";
import FranchiseCharacterSearchBox from "./FranchiseCharacterSearchBox";
import SignerSearchBox from "./SignerSearchBox";

function Add({ id, onAdd, onCancel, signers }) {
  const [franchise, setFranchise] = React.useState(null);
  const [character, setCharacter] = React.useState(null);
  const [influencer, setInfluencer] = React.useState(null);

  const handleAdd = () => {
    if (!franchise || !character) {
      return;
    }

    const newFranchise = {
      franchiseId: franchise.franchise_id,
      name: franchise.name,
    };

    const newCharacter = {
      characterId: character.character_id,
      franchiseCharacterId: character.franchise_character_id,
      franchiseId: character.franchise_id,
      name: character.name,
      titleCharacterId: character.title_character_id,
    };

    const newInfluencer = {
      influencerId: influencer?.influencerId ?? null,
      influencerName: influencer?.name ?? "",
      influencerAvatarUrl: influencer?.avatarUrl ?? null,
    };

    onAdd({
      id,
      character: newCharacter,
      franchise: newFranchise,
      influencer: newInfluencer,
    });
  };

  const onSetFranchise = React.useCallback((value) => {
    setFranchise(value);
    setCharacter(null);
  }, []);

  const onSetCharacter = React.useCallback(
    (value) => {
      if (
        value?.franchise_id &&
        (!franchise || franchise.franchise_id !== value.franchise_id)
      ) {
        setFranchise({
          franchise_id: value.franchise_id,
          name: value.franchise_name,
        });
      }

      setCharacter(value);
    },
    [franchise],
  );

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 72px" gridGap="8px">
      <FranchiseCharacterSearchBox
        type="franchise"
        selectedValue={franchise}
        setSelectedValue={onSetFranchise}
      />

      <FranchiseCharacterSearchBox
        type="character"
        franchiseId={franchise?.franchise_id}
        selectedValue={character}
        setSelectedValue={onSetCharacter}
      />

      <SignerSearchBox
        signers={signers}
        selectedValue={influencer}
        setSelectedValue={setInfluencer}
      />

      {franchise && character && (
        <Button
          color="secondary"
          disabled={!franchise || !character}
          onClick={handleAdd}
          title={`Save: ${character.name} (${franchise.name})`}
          variant="outlined"
        >
          Save
        </Button>
      )}

      {(!franchise || !character) && (
        <Button
          onClick={onCancel}
          title="Cancel adding new character"
          variant="outlined"
        >
          Remove
        </Button>
      )}
    </Box>
  );
}

Add.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  signers: PropTypes.arrayOf(
    PropTypes.shape({
      influencerId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string,
    }),
  ).isRequired,
  id: PropTypes.string.isRequired,
};

function Display({ onRemove, franchise, character, influencer }) {
  return (
    <Box mb={1} display="flex" gridGap="8px" alignItems="center">
      <ExtraSmallAvatar src={influencer.avatarUrl} />

      <Typography variant="body2">{character.name}</Typography>

      <Typography variant="body2" color="textSecondary">
        ({franchise.name})
      </Typography>

      <IconButton
        onClick={() => onRemove({ franchise, character })}
        size="small"
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
}

Display.propTypes = {
  onRemove: PropTypes.func.isRequired,
  franchise: PropTypes.shape({
    franchiseId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  character: PropTypes.shape({
    characterId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  influencer: PropTypes.shape({
    avatarUrl: PropTypes.string,
    influencerId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default {
  Add,
  Display,
};

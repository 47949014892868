import * as React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { CartTotals } from "../../../../domain";

const Total = React.memo(function Total({ totals }) {
  return <Typography variant="h6">{totals.total.formattedPrice}</Typography>;
});

Total.propTypes = {
  totals: PropTypes.instanceOf(CartTotals),
};

export default Total;

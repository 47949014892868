import React from "react";
import PropTypes from "prop-types";
import "../popular-product/popular_product.css";
import { ProductBlock } from "../commonComponent";
import SectionWrapper from "../commonComponent/SectionWrapper";

const PopularPresigned = React.memo(function PopularPresigned({
  data,
  screenWidth,
  isLoading = true,
}) {
  return (
    <SectionWrapper
      data={data}
      screenWidth={screenWidth}
      isLoading={isLoading}
      blockWidth={180}
      blockHeight={212}
      margin={16}
      title="Pre-Signed Products"
    >
      {ProductBlock}
    </SectionWrapper>
  );
});

PopularPresigned.propTypes = {
  data: PropTypes.array,
  screenWidth: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default PopularPresigned;

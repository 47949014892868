import { Box, Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Cart } from "../../../domain";
import {
  useCart,
  useCartAttributes,
  useCurrentUser,
  useMatchesLgUp,
  useMatchesXlUp,
  useMatchesXsDown,
  usePurchaseResponse,
} from "../../../hooks";
import { SexyButton, StatusMessage } from "../../../theme";
import { logger, mapObjectAll } from "../../../util";
import { useCheckout } from "../CheckoutProvider";
import * as service from "../service";
import {
  clearError,
  createErrorUpdate,
  currentStepUpdate,
  ERRORS,
  FIRST_STEP,
  formValidityUpdate,
  isFirstStep,
  isSecondStep,
  linkedMessageUpdate,
  loadingUpdate,
  newUserObjectUpdate,
  PAYMENT_METHODS,
  purchaseUpdate,
  savedCardUpdate,
  SECOND_STEP,
} from "../state";
import * as utils from "../utils";
import CheckoutSteps from "./CheckoutSteps";
import HeaderSection from "./HeaderSection";

/*
 * CheckoutStepper renders the stepper form component subtree, with the address
 * section as step 1 and the payment section as step 2
 */
const CheckoutStepper = React.memo(function CheckoutStepper() {
  const didSetPurchaseResponseRef = React.useRef(false);
  const formRef = React.useRef(null);
  const history = useHistory();
  const { cart, isError, isLoading, refresh } = useCart();
  const { cartAttributes } = useCartAttributes();
  const { purchaseResponse } = usePurchaseResponse();
  const [, setScroll] = utils.useScrollAtom();

  const currentUser = useCurrentUser();

  const { state, dispatch } = useCheckout();
  const matchesXs = useMatchesXsDown();
  const matchesLg = useMatchesLgUp();
  const matchesXl = useMatchesXlUp();

  const [onSubmit, setOnSubmit] = React.useState(null);
  const { currentStep } = state;

  /*
   * purchaseHasErrors performs some validation pre-purchase creation
   *
   * @returns {boolean} hasErrors
   */
  const purchaseHasErrors = React.useCallback(() => {
    if (!cart) {
      return true;
    }

    let hasErrors = false;

    /*
     * Verify shipping choice is valid
     */
    if (!utils.hasValidShippingChoice(cartAttributes)) {
      const promoText = utils.errorPromoTextFor({ cart, currentUser, state });

      createErrorUpdate(dispatch, {
        message: `${promoText} Please choose your shipping option`,
        time: Infinity,
      });

      hasErrors = true;
    }

    return hasErrors;
  }, [cart, cartAttributes, currentUser, dispatch, state]);

  const handlePurchaseResponse = React.useCallback(
    async (purchase) => {
      dispatch(purchaseUpdate(purchase));
      dispatch(loadingUpdate(false));
      clearError(dispatch, ERRORS.FORM);

      await refresh();

      /*
       * Set the global currentUser as the new user we created on purchase,
       * unless we are just linking the account, in which case we want them to
       * still have to log in
       */
      if (purchase.user && !purchase.didLinkAccount) {
        dispatch(newUserObjectUpdate(purchase.user));
      }

      /*
       * Set the guestPurchaseToken in localStorage for the 'Success' page
       * TODO: Change this to just use the cookie instead for security.
       */
      if (purchase.guestPurchaseToken) {
        utils.setGuestPurchaseToken(purchase.guestPurchaseToken);
      }

      if (purchase.linkedMessage) {
        dispatch(linkedMessageUpdate(purchase.linkedMessage));
      }

      if (purchase.defaultSource !== null) {
        dispatch(savedCardUpdate(true));
      }
    },
    [dispatch, refresh],
  );

  /*
   * createPurchase sends the purchaseInfo request to /api/purchases in order to
   * create the paymentIntent we will need for the next step in checkout flow. Once
   * the paymentIntent (and / or subscription) gets created, we can proceed to the
   * next step and collect user's payment information with stripe elements.
   *
   * @param {function} onPurchase callback
   */
  const createPurchase = React.useCallback(async () => {
    dispatch(loadingUpdate(true));

    if (purchaseHasErrors()) {
      return dispatch(loadingUpdate(false));
    }

    try {
      const { email } = state.contactInformation;

      if (!email) {
        return dispatch(loadingUpdate(false));
      }

      let addressUpdates;

      if (state.address?.address) {
        addressUpdates = mapObjectAll((value) => value || null, {
          country: state.address?.country,
          address: state.address?.address,
          address2: state.address?.address2,
          city: state.address?.city,
          state: state.address?.state,
          first_name: state.address?.firstName,
          last_name: state.address?.lastName,
          zip_code: state.address?.zipCode,
          phone_number: state.address?.phoneNumber?.replace(/\D/g, ""),
          email,
        });
      } else {
        addressUpdates = { email };
      }

      const { errors } = await Cart.updateAddress(addressUpdates);

      if (Array.isArray(errors) && errors.length) {
        dispatch(loadingUpdate(false));
        return createErrorUpdate(dispatch, {
          message: utils.errorMessageFrom(errors),
          time: 10000,
        });
      }

      const purchaseInfo = utils.createPurchaseInfo({
        state,
        cart,
        currentUser,
      });

      const purchase = await service.createPurchase(purchaseInfo);

      await handlePurchaseResponse(purchase);
      return true;
    } catch (err) {
      if (err.isAxiosError) {
        const { errors } = err.response.data;

        createErrorUpdate(dispatch, {
          message: utils.errorMessageFrom(errors),
          time: 10000,
        });
      } else {
        logger.error(err);
      }

      dispatch(loadingUpdate(false));
      return false;
    }
  }, [
    cart,
    currentUser,
    state,
    dispatch,
    purchaseHasErrors,
    handlePurchaseResponse,
  ]);

  /*
   * wrappedOnSubmit handles updating the history location so we can hijack the
   * browser back button and take the user back to step one, instead of out of the
   * checkout flow.
   */
  const wrappedOnSubmit = React.useCallback(
    async (evt) => {
      evt.preventDefault();

      if (isFirstStep(currentStep)) {
        const isSuccess = await createPurchase();

        if (!isSuccess) {
          return;
        }

        dispatch(currentStepUpdate(SECOND_STEP));

        history.push({
          pathname: history.location.pathname,
          search: history.location.search,
        });
      } else if (isSecondStep(currentStep) && typeof onSubmit === "function") {
        return onSubmit(evt);
      }
    },
    [onSubmit, currentStep, createPurchase, dispatch, history],
  );

  const wrappedDispatch = React.useCallback(
    (...args) => {
      dispatch(...args);

      if (formRef.current) {
        dispatch(
          formValidityUpdate(
            Array.from(formRef.current.elements).every((element) =>
              element.checkValidity(),
            ),
          ),
        );
      }
    },
    [dispatch],
  );

  /*
   * handleBack handles cancelling the paymentIntent or pending subscription if the
   * user decides to go back to change information.
   */
  const handleBack = React.useCallback(
    (onBack, { scrollTo = "" } = { scrollTo: "" }) => {
      if (isFirstStep(currentStep)) {
        return;
      }

      if (isSecondStep(currentStep)) {
        dispatch(currentStepUpdate(FIRST_STEP));
        dispatch(purchaseUpdate(null));
        dispatch(linkedMessageUpdate(""));

        service.cancelPurchase(utils.createCancelPurchaseInfo({ state }));
      }

      if (typeof onBack === "function") {
        onBack();
      }

      if (scrollTo) {
        setScroll(scrollTo);
      }
    },
    [currentStep, dispatch, state, setScroll],
  );

  /*
   * Handle overriding the back button event so we don't abort the checkout if they
   * are on the second step.
   */
  React.useEffect(() => {
    return history.listen((newLocation, action) => {
      if (
        action === "POP" &&
        newLocation.pathname === history.location.pathname
      ) {
        handleBack();
      }
    });
  }, [history, handleBack, currentStep]);

  /*
   * If nothing in the cart, push back to the shop page, or just the home page
   * if they didn't come from the shop.
   */
  React.useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isError || (cart && cart.isEmpty)) {
      history.push(cart.influencer?.route ?? "/");
    }
  }, [isLoading, isError, cart, history]);

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (
      purchaseResponse &&
      !didSetPurchaseResponseRef.current &&
      params.has("error")
    ) {
      handlePurchaseResponse(purchaseResponse);
      didSetPurchaseResponseRef.current = true;
      dispatch(currentStepUpdate(SECOND_STEP));
      createErrorUpdate(dispatch, {
        message: params.get("error"),
        time: 12000,
      });
      params.delete("error");
      history.push({
        pathname: history.location.pathname,
        search: params.toString(),
      });
    }
  }, [dispatch, history, purchaseResponse, handlePurchaseResponse]);

  return (
    <form onSubmit={wrappedOnSubmit} ref={formRef}>
      <StyledBox
        pt={matchesXl ? 12 : matchesLg ? 8 : 2}
        pb={matchesXs ? 18 : 12}
        pr={matchesXl ? 8 : matchesLg ? 4 : 0}
      >
        <StyledGrid container spacing={4} direction="column">
          <Grid item>
            <HeaderSection currentStep={currentStep} />
          </Grid>

          <CheckoutSteps
            currentStep={currentStep}
            dispatch={wrappedDispatch}
            onSubmit={onSubmit}
            setOnSubmit={setOnSubmit}
            handleBack={handleBack}
          />

          <Grid
            container
            item
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            {Boolean(state.error) && (
              <Grid item xs container justifyContent="flex-end">
                <StatusMessage
                  size="large"
                  message={state.error.message}
                  type="error"
                />
              </Grid>
            )}

            <Grid item xs={matchesXs ? true : undefined}>
              <SexyButton
                disabled={
                  state.isLoading ||
                  (isSecondStep(currentStep) && !onSubmit) ||
                  (isSecondStep(currentStep) &&
                    state.paymentMethod !== PAYMENT_METHODS.STRIPE)
                }
                isLoading={state.isLoading}
                type="submit"
                className="large"
              >
                {isFirstStep(currentStep) ? "Proceed to Payment" : "Pay Now"}
              </SexyButton>
            </Grid>
          </Grid>
        </StyledGrid>
      </StyledBox>
    </form>
  );
});

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    maxWidth: theme.breakpoints.values.md,
  },
}));

const StyledBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export default CheckoutStepper;

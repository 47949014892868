import * as React from "react";
import PropTypes from "prop-types";
import CheckoutForm from "./CheckoutForm";
import PaymentForm from "./PaymentForm";

const CheckoutSteps = React.memo(function CheckoutSteps({
  currentStep,
  dispatch,
  onSubmit,
  setOnSubmit,
  handleBack,
}) {
  switch (currentStep) {
    case 0:
      return <CheckoutForm dispatch={dispatch} />;

    case 1:
      return (
        <PaymentForm
          dispatch={dispatch}
          onSubmit={onSubmit}
          setOnSubmit={setOnSubmit}
          handleBack={handleBack}
        />
      );

    default:
      return null;
  }
});

CheckoutSteps.propTypes = {
  currentStep: PropTypes.number,
  dispatch: PropTypes.func,
  onSubmit: PropTypes.func,
  setOnSubmit: PropTypes.func,
  handleBack: PropTypes.func,
};

export default CheckoutSteps;

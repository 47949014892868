import { Box } from "@material-ui/core";
import { B, D, G } from "@mobily/ts-belt";
import { useAtom, useAtomValue } from "jotai";
import PropTypes from "prop-types";
import * as React from "react";
import { useCurrentUser } from "../../../globalState";
import { Form } from "../../../theme";
import EducationalMessage from "../EducationalMessage";
import ProductSigners from "../ProductSigners";
import * as styles from "../styles";
import { format } from "../utils";
import { totalsFor } from "./utils";
import ProductImageInput from "./ProductImageInput";

function onBlurTrim({ key, setProduct }) {
  return (evt) => {
    const { target } = evt;
    const newValue = evt.target.value.trim();
    setProduct(D.set(key, newValue));
    target.value = newValue;
  };
}

function ProductDetailsStep({ stateAtom, productAtom, productSignersAtom }) {
  const currentUser = useCurrentUser();
  const signers = useAtomValue(productSignersAtom);

  const [state, setState] = useAtom(stateAtom);
  const [product, setProduct] = useAtom(productAtom);

  const onBlurBump = (evt) => {
    const { target } = evt;
    const newValue = Math.round(Number(target.value)) * 100;
    setProduct(D.set("productBump", newValue));
    target.value = format(newValue);
  };

  const canEdit =
    currentUser.isAdmin || currentUser.isSuperadmin || signers.length < 2;

  const bump = React.useMemo(() => {
    const totals = totalsFor({ product, signers });

    if (product.fixedBump) {
      return format(product.productBump);
    }

    if (totals.bump !== product.productBump) {
      return format(totals.bump);
    }

    return format(product.productBump);
  }, [product, signers]);

  return (
    <Box display="flex" flexDirection="column">
      <ProductImageInput
        disabled={!canEdit}
        productAtom={productAtom}
        stateAtom={stateAtom}
      />

      <Box mt={2} display="flex" flexDirection="column" gridGap="8px">
        <styles.Label>Name</styles.Label>

        <div>
          <Form.Input
            disabled={!canEdit}
            fullWidth
            size="small"
            value={product.name}
            placeholder="Product Name"
            inputProps={{ maxLength: 100 }}
            onChange={(evt) => setProduct(D.set("name", evt.target.value))}
            onBlur={onBlurTrim({ key: "name", setProduct })}
          />

          <styles.InputLengthStatus value={product.name} length={100} />
        </div>
      </Box>

      <Box display="flex" flexDirection="column" gridGap="8px">
        <styles.Label>Description</styles.Label>

        <div>
          <Form.Input
            disabled={!canEdit}
            fullWidth
            value={G.isString(product.subtext) ? product.subtext : ""}
            size="small"
            multiline
            rows={3}
            placeholder="Add a description of what you're selling"
            onChange={(evt) => setProduct(D.set("subtext", evt.target.value))}
            onBlur={onBlurTrim({ key: "subtext", setProduct })}
            inputProps={{ maxLength: 255 }}
          />

          <styles.InputLengthStatus value={product.subtext} length={100} />
        </div>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        gridGap="16px"
        width="100%"
      >
        <Box display="flex" flexDirection="column" gridGap="8px">
          <styles.Label>Quantity</styles.Label>

          <Box mb={1} display="flex" alignItems="center" gridGap="16px">
            <Form.Input
              value={product.stock ?? ""}
              disabled={!canEdit || product.stock === null}
              size="small"
              placeholder={product.stock === null ? "N/A" : "Quantity"}
              onChange={(evt) =>
                setProduct(D.set("stock", Number(evt.target.value)))
              }
              inputProps={{
                type: "number",
                min: 0,
                max: 9999,
                size: 3,
                maxLength: 4,
                step: 1,
              }}
              error={product.stock < 0}
            />

            <Form.SmallCheckbox
              disabled={!canEdit}
              checked={product.stock === null}
              onChange={() =>
                setProduct(D.set("stock", product.stock === null ? 0 : null))
              }
              label="Unlimited"
            />
          </Box>
        </Box>

        {currentUser.isAdmin && (
          <Box width={250} display="flex" flexDirection="column" gridGap="8px">
            <styles.Label>Product Bump</styles.Label>

            <Box mb={1} display="flex" alignItems="center" gridGap="16px">
              <Form.Input
                value={bump}
                disabled={!product.fixedBump}
                size="small"
                placeholder="Bump"
                InputProps={{ startAdornment: "$" }}
                onChange={(evt) => {
                  const newValue = Math.round(Number(evt.target.value)) * 100;
                  setProduct(D.set("productBump", newValue));
                }}
                onBlur={onBlurBump}
                inputProps={{ type: "number", size: 2, min: 0, maxLength: 4 }}
              />

              <Form.SmallCheckbox
                checked={Boolean(product.fixedBump)}
                onChange={() => setProduct(D.update("fixedBump", B.not))}
                label="Set Fixed Bump"
              />
            </Box>
          </Box>
        )}
      </Box>

      {!state.didShowEducationalMessage && (
        <EducationalMessage
          open={!state.didShowEducationalMessage}
          onClose={() => {
            setState(D.merge({ didShowEducationalMessage: true }));
          }}
        />
      )}

      <ProductSigners
        productId={product.productId}
        productSignersAtom={productSignersAtom}
        productAtom={productAtom}
      />
    </Box>
  );
}

ProductDetailsStep.propTypes = {
  productAtom: PropTypes.object.isRequired,
  productSignersAtom: PropTypes.object.isRequired,
  stateAtom: PropTypes.object.isRequired,
};

export default {
  Component: ProductDetailsStep,
  title: "Product Details",
};

import { Box, Container, Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import * as React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as RP from "../../components/ReferralProgram";
import { useStateValue } from "../../globalState";
import { useMatchesXsDown } from "../../hooks";
import { StreamilyPageContainer } from "../../theme";

export default function ReferralProgram() {
  const history = useHistory();
  const matchesXsDown = useMatchesXsDown();
  const [{ user }] = useStateValue();

  useEffect(() => {
    if (!user.user_id) return history.push("/dashboard");
  }, [user, history]);

  return (
    <StreamilyPageContainer>
      <Container>
        <Box width="100%" my={4} display="flex" justifyContent="center">
          <Box>
            <Grid container spacing={matchesXsDown ? 2 : 6}>
              <Grid item xs={12} sm="auto">
                <RP.PageImage matchesXsDown={matchesXsDown} />
              </Grid>

              <Grid item xs={12} sm>
                <StyledHeading gutterBottom variant="h4">
                  Better together 🤝
                </StyledHeading>

                <Typography gutterBottom variant="body2">
                  Our goal is to give Talent and Fans great experiences from
                  anywhere in the world!
                </Typography>

                <Typography gutterBottom variant="body2">
                  Help your talented friends reach their audience by referring
                  them to Streamily!
                </Typography>

                <Typography gutterBottom variant="body2">
                  We’re happy to provide you with 2% of Streamily’s cut from
                  sales made by the Talent you refer.
                </Typography>

                <Typography variant="body2">
                  <strong>
                    Ask your Talent Coordinator for more info today, or just
                    start sharing your referral link!
                  </strong>
                </Typography>

                <Box mt={2}>
                  <RP.ReferralLink matchesXsDown={matchesXsDown} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Typography gutterBottom variant="h5">
          How it works
        </Typography>
        <RP.HowItWorks matchesXsDown={matchesXsDown} />
        <RP.TermsAndConditions />
      </Container>
    </StreamilyPageContainer>
  );
}

const StyledHeading = styled(Typography)(() => ({
  fontWeight: 800,
}));

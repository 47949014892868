import React from "react";
import PropTypes from "prop-types";
import "../live/live.css";
import SectionWrapper from "../commonComponent/SectionWrapper";
import { LiveBlock } from "../commonComponent";
import useShuffle from "../../../hooks/useShuffle";

const EvenMore = React.memo(function EvenMore({
  data,
  screenWidth,
  isLoading = true,
}) {
  const shuffledData = useShuffle(data);

  return (
    <SectionWrapper
      data={shuffledData}
      screenWidth={screenWidth}
      isLoading={isLoading}
      title={`Even More`}
      showSlider={false}
    >
      {(props) => <LiveBlock {...props} />}
    </SectionWrapper>
  );
});

EvenMore.propTypes = {
  data: PropTypes.array,
  screenWidth: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default EvenMore;

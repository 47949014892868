const DEFAULT_CURRENCY = "usd";
const DEFAULT_LOCALE = "en-US";

export default class Money {
  #rawMoney;

  static DEFAULT_CURRENCY = DEFAULT_CURRENCY;
  static DEFAULT_LOCALE = DEFAULT_LOCALE;
  static ZERO_USD = new Money({ value: 0, currency: "USD" });

  /*
   * for returns a new Money for a rawMoney object { value, currency }
   *
   * @param {object} rawMoney
   * @returns {Money}
   */
  static for(rawMoney) {
    return new Money(rawMoney);
  }

  static assertSameCurrency(aMoney, bMoney) {
    if (!aMoney.currency === bMoney.currency) {
      throw new TypeError(
        `Cannot subtract different currencies: ${aMoney.currency} and ${bMoney.currency}`,
      );
    }
  }

  static add(aMoney, bMoney) {
    Money.assertSameCurrency(aMoney, bMoney);

    return new Money({
      value: aMoney.value + bMoney.value,
      currency: aMoney.currency,
    });
  }

  static subtract(aMoney, bMoney) {
    Money.assertSameCurrency(aMoney, bMoney);

    return new Money({
      value: aMoney.value - bMoney.value,
      currency: aMoney.currency,
    });
  }

  static gt(aMoney, bMoney) {
    Money.assertSameCurrency(aMoney, bMoney);

    return aMoney.value > bMoney.value;
  }

  static lt(aMoney, bMoney) {
    Money.assertSameCurrency(aMoney, bMoney);

    return aMoney.value < bMoney.value;
  }

  static gte(aMoney, bMoney) {
    Money.assertSameCurrency(aMoney, bMoney);

    return aMoney.value >= bMoney.value;
  }

  static lte(aMoney, bMoney) {
    Money.assertSameCurrency(aMoney, bMoney);

    return aMoney.value <= bMoney.value;
  }

  /*
   * creates a new Money for a rawMoney object { value, currency }
   *
   * @param {object} rawMoney
   * @returns {Money}
   */
  constructor(rawMoney) {
    const rawMoneyCopy = { ...rawMoney };
    this.#rawMoney = rawMoneyCopy;
  }

  get currency() {
    return this.#rawMoney?.currency ?? DEFAULT_CURRENCY;
  }

  get value() {
    return this.#rawMoney?.value ?? 0;
  }

  get formattedPrice() {
    return this.format({ places: 2 });
  }

  get formattedAmount() {
    return this.format({ places: 2 });
  }

  format({ locale = DEFAULT_LOCALE, places } = {}) {
    let minimumFractionDigits;

    if (Number.isInteger(this.#rawMoney.value) && !places) {
      minimumFractionDigits = 0;
    } else {
      minimumFractionDigits = places ?? 2;
    }

    return new Intl.NumberFormat(locale, {
      currency: this.currency,
      style: "currency",
      minimumFractionDigits,
    }).format(this.#rawMoney.value);
  }

  toJSON() {
    return {
      value: this.value,
      currency: this.currency,
    };
  }

  toString() {
    return this.formattedPrice;
  }

  add(aMoney) {
    return Money.add(this, aMoney);
  }

  subtract(aMoney) {
    return Money.subtract(this, aMoney);
  }

  gt(aMoney) {
    return Money.gt(this, aMoney);
  }

  lt(aMoney) {
    return Money.lt(this, aMoney);
  }

  gte(aMoney) {
    return Money.gte(this, aMoney);
  }

  lte(aMoney) {
    return Money.lte(this, aMoney);
  }
}

import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";
import { useEffect } from "react";
import Slider from "./Slider";
import { CardBlock, SectionContainer, SkeletonContainer } from ".";

const SectionWrapper = React.memo(function SectionWrapper({
  data,
  screenWidth,
  isLoading = true,
  children,
  blockWidth = 144,
  blockHeight = 200,
  blockMargin = 16,
  title = "",
  showSlider = true,
}) {
  const sectionRef = useRef(null);
  const [showAll, setShowAll] = useState(false);
  const [visible, setVisible] = useState(0);
  const [noOfCards, setNoOfCards] = useState(1);
  const [rowCount, setNoOfRows] = useState(1);
  const [margin, setMargin] = useState(0);

  const Item = children;

  useEffect(() => {
    if (sectionRef.current) {
      const { width } = sectionRef.current.getBoundingClientRect();
      const noOfCardsInEachRow = Number.parseInt(width / blockWidth, 10);
      const calMarginWidth = (noOfCardsInEachRow - 1) * blockMargin;
      const actualCardCount = Number.parseInt(
        (width - calMarginWidth) / blockWidth,
        10,
      );
      const noOfExtraRow = data.length % actualCardCount;

      const rightMargin =
        (width - actualCardCount * blockWidth) / (actualCardCount - 1);
      let noOfRows = Number.parseInt(data.length / actualCardCount, 10);
      if (noOfExtraRow > 0) {
        noOfRows += 1;
      }
      setNoOfCards(actualCardCount);
      setMargin(screenWidth < 580 ? blockMargin : rightMargin);
      setNoOfRows(noOfRows);
      setVisible(actualCardCount * 2);
    }
  }, [blockWidth, blockMargin, screenWidth, showAll, data]);

  useEffect(() => {
    if (showSlider) setShowAll(data.length < noOfCards);
    else setShowAll(true);
  }, [showSlider, data, noOfCards]);

  const getMappedItem = useCallback(
    (item, i, isSlider) => {
      return (
        <Item
          key={i}
          item={item}
          className="card"
          margin={
            data.length < noOfCards
              ? blockMargin
              : i === 0
              ? "7px"
              : `${margin}px`
          }
          index={i}
          width={blockWidth}
          height={blockHeight}
          isSlider={isSlider}
        />
      );
    },
    [blockWidth, blockHeight, blockMargin, data.length, margin, noOfCards],
  );

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + noOfCards * 10);
  };

  const finalData = useMemo(() => data.slice(0, visible), [data, visible]);

  return (
    <>
      <div className="browse-section-block" ref={sectionRef}>
        <div className="browse-section-title">{title}</div>
        <hr className="browse-v2-hr" />
        {data.length > (showSlider ? noOfCards : noOfCards * 2) &&
          (showSlider ? (
            <>
              <button
                className="browse-section-viewAll"
                onClick={() => setShowAll(!showAll)}
              >
                {showAll ? "Show Less" : "Show More"}
              </button>
            </>
          ) : (
            <>
              <button
                className="browse-section-viewAll"
                onClick={() =>
                  visible > noOfCards * 2
                    ? setVisible(noOfCards * 2)
                    : showMoreItems()
                }
              >
                {visible > noOfCards * 2 ? "Show Less" : "Show More"}
              </button>
            </>
          ))}
      </div>
      {isLoading ? (
        <SkeletonContainer>
          {Array.from({ length: noOfCards }, (_, i) => (
            <CardBlock
              width={blockWidth}
              className="card"
              style={{
                background: "inherit",
                marginLeft: i === 0 ? 0 : `${margin}px`,
              }}
              key={i}
            >
              <Skeleton
                variant="rect"
                width={blockWidth}
                height={blockHeight}
                style={{ borderRadius: "10px" }}
              />
            </CardBlock>
          ))}
        </SkeletonContainer>
      ) : (
        <SectionContainer isSlider={!showAll}>
          {showAll ? (
            <>
              {Array.from({ length: rowCount }, (_, index) => (
                <div className="section_row" key={index}>
                  {finalData
                    .slice(index * noOfCards, noOfCards * index + noOfCards)
                    .map((item, i) => getMappedItem(item, i))}
                </div>
              ))}
              {showSlider && data.length !== data.slice(0, visible).length && (
                <div className="show_more">
                  <button className="show_more_click" onClick={showMoreItems}>
                    Show More
                  </button>
                </div>
              )}
            </>
          ) : (
            <Slider title="" row={true}>
              {data.map((item, i) => getMappedItem(item, i, true))}
            </Slider>
          )}
        </SectionContainer>
      )}
      {!showSlider && data.length !== finalData.length && (
        <div className="show_more">
          <button className="show_more_click" onClick={showMoreItems}>
            Show More
          </button>
        </div>
      )}
    </>
  );
});

SectionWrapper.propTypes = {
  data: PropTypes.array,
  screenWidth: PropTypes.number,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  blockWidth: PropTypes.number,
  blockHeight: PropTypes.number,
  blockMargin: PropTypes.number,
  title: PropTypes.string,
  showSlider: PropTypes.bool,
};

export default SectionWrapper;

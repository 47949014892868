import React from "react";
import styled from "styled-components";

const Policy = () => {
  return (
    <Container>
      <Title>Delivery</Title>
      <ContextText>
        Autographs shipped globally to eligible countries by USPS, UPS, or DHL.
        Merchandise shipped within 72 hours of receipt of autographed item from
        signer.
      </ContextText>
      <ContextText>
        <a href="/terms-and-conditions">Terms and Conditions</a>
      </ContextText>
      <ContextText>
        <a href="/privacy-policy">Privacy Policy</a>
      </ContextText>
      <ContextText>
        <a href="/return-policy">Return Policy</a>
      </ContextText>
    </Container>
  );
};

export default Policy;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  @media only screen and (min-height: 430px) {
    justify-content: flex-start;
    padding-top: 50px;
  }
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  color: rgb(80, 80, 80);
  margin-top: 20px;
`;

const ContextText = styled.div`
  text-align: center;
  color: rgb(100, 100, 100);
  width: 90%;
  max-width: 500px;
  font-size: 18px;
  margin: 10px;
  a {
    text-decoration: none;
    color: rgb(64, 131, 255);
    &:hover {
      text-decoration: underline;
    }
  }
`;

import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { Product, SignType } from "../../domain";
import { useLivestreamWarningContext } from "../../pages/Dashboard/ShopEditor";
import SignTypeBadge from "../SignTypeBadge";

const ProductImage = React.memo(function ProductImage({
  product,
  isStorefront = false,
  isDark,
}) {
  const livestreamWarning = useLivestreamWarningContext();
  const shouldShowWarning =
    livestreamWarning && product.signedType === SignType.livestream;

  return (
    <div
      className="product-image__container"
      title={
        !isStorefront && shouldShowWarning
          ? "This product will not be available to customers until an event date has been set"
          : ""
      }
    >
      {product.isSoldOut && (
        <div className="product-image__sold-out-overlay">
          <span className="sold-out-text">SOLD OUT</span>
        </div>
      )}

      {!isStorefront && shouldShowWarning && (
        <div className="top-left">
          <ErrorRoundedIcon style={{ color: "red", fontSize: 30 }} />
        </div>
      )}

      <div className="top-right">
        <SignTypeBadge
          signedType={product.signedType}
          label={SignType.toLabel(product.signedType)}
        />
      </div>

      <div
        className={clsx(
          "product-image__image-container",
          product.shouldContainImage && "contained",
          isDark && "dark",
          "fancy",
        )}
      >
        <img
          className="product-image__image"
          loading="lazy"
          src={product?.imageUrl}
          alt={product?.name}
        />
      </div>
    </div>
  );
});

ProductImage.propTypes = {
  product: PropTypes.instanceOf(Product),
  isStorefront: PropTypes.bool,
  isDark: PropTypes.bool,
};

export default ProductImage;

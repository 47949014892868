import { Box, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { G } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import React from "react";
import { useDebounce } from "../../hooks";
import { Form } from "../../theme";
import VirtualizedList from "../../theme/Virtualized/VirtualizedList";

const StyledCaption = styled("p")(({ theme }) => ({
  ...theme.typography.caption,
  lineHeight: 1,
  color: theme.palette.text.secondary,
  margin: 0,
}));

const listContext = React.createContext();

function ListContextProvider({ children, franchiseId, type }) {
  return (
    <listContext.Provider value={{ franchiseId, type }}>
      {children}
    </listContext.Provider>
  );
}

ListContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  franchiseId: PropTypes.number,
  type: PropTypes.string.isRequired,
};

const ListBox = React.forwardRef(function ListBox(props, ref) {
  const { children, ...other } = props;
  const { franchiseId, type } = React.useContext(listContext);
  const itemData = React.Children.toArray(children);
  const itemSize = type === "character" && !franchiseId ? 42 : 36;

  const height = Math.min(itemSize * itemData.length, 250);

  return (
    <div {...other} ref={ref} role="listbox">
      <VirtualizedList
        AutoSizerProps={{ disableHeight: true }}
        height={height}
        rows={itemData}
        rowHeight={itemSize}
        rowRenderer={({ index, style, key }) =>
          React.cloneElement(itemData[index], { style, key })
        }
      />
    </div>
  );
});

ListBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function FranchiseCharacterSearchBox({
  type,
  franchiseId,
  selectedValue,
  setSelectedValue,
}) {
  const inputEl = React.useRef(null);
  const [value, setValue] = React.useState("");
  const [query, setQuery] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);

  const label = type === "franchise" ? "Franchise" : "Character";

  const onSearch = React.useCallback(
    async (query) => {
      try {
        const params = new URLSearchParams();

        if (query) {
          params.set("query", query);
        }

        if (type === "character" && franchiseId) {
          params.set("franchise_id", franchiseId);
        }

        const path = type === "franchise" ? "franchise" : "character/temp";

        const response = await window.fetch(`/api/${path}?${params}`, {
          method: "GET",
          headers: { "content-type": "application/json" },
        });

        if (!response.ok) {
          throw new Error("Failed to search");
        }

        const json = await response.json();

        setSearchResults(json[`${type}s`] ?? []);
      } catch (error) {
        console.error(error);
      }
    },
    [type, franchiseId],
  );

  const onSelect = React.useCallback(
    (_, newValue) => {
      setSelectedValue(newValue);
    },
    [setSelectedValue],
  );

  const onBlur = React.useCallback(() => {
    if (!selectedValue && !franchiseId) {
      setSearchResults([]);
    } else if (!selectedValue) {
      setValue("");
    }
  }, [selectedValue, franchiseId]);

  useDebounce(
    () => {
      setQuery(value);
    },
    250,
    [value],
  );

  React.useEffect(() => {
    if (query || franchiseId) {
      onSearch(query);
    }
  }, [query, onSearch, franchiseId]);

  React.useEffect(() => {
    if (franchiseId) {
      onSearch(query);
    } else {
      setSearchResults([]);
    }
  }, [franchiseId, onSearch, query]);

  React.useEffect(() => {
    if (type === "franchise") {
      inputEl.current?.focus();
    }
  }, [type]);

  if (type !== "franchise" && type !== "character") {
    return null;
  }

  const renderInput = (params) => (
    <Form.Input
      {...params}
      inputRef={inputEl}
      inputProps={{ maxLength: 45, ...params.inputProps }}
      fullWidth
      label={label}
      size="small"
      onChange={(evt) => {
        setValue(evt.target.value);
      }}
    />
  );

  return (
    <Box flex="1">
      <ListContextProvider franchiseId={franchiseId} type={type}>
        <Form.Autocomplete
          autoComplete
          clearOnBlur={!selectedValue?.name}
          freeSolo
          handleHomeEndKeys
          includeInputInList
          ListboxComponent={ListBox}
          options={searchResults}
          value={selectedValue?.name ?? ""} // This is required to clear the input
          selectOnFocus
          size="small"
          onChange={onSelect}
          onBlur={onBlur}
          getOptionLabel={(selectedValue) => {
            if (G.isString(selectedValue)) {
              return selectedValue;
            }
            return selectedValue.name;
          }}
          renderInput={renderInput}
          renderOption={(option) => (
            <div>
              <Typography noWrap variant="body2">
                {option.name}
              </Typography>

              {type === "character" && !franchiseId && (
                <StyledCaption noWrap variant="caption">
                  {option.franchise_name}
                </StyledCaption>
              )}
            </div>
          )}
        />
      </ListContextProvider>
    </Box>
  );
}

FranchiseCharacterSearchBox.propTypes = {
  type: PropTypes.string.isRequired,
  franchiseId: PropTypes.number,
  onSelect: PropTypes.func,
  selectedValue: PropTypes.object,
  setSelectedValue: PropTypes.func.isRequired,
};

import * as React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@material-ui/core";
import { SoftIconCard } from "../../../theme";
import * as icons from "./Icons";

const sep = {};

const steps = [
  {
    title: "Share your link",
    text: "Share your link with other Talent that you think could make sales on Streamily!",
    icon: <icons.MailIcon />,
  },
  sep,
  {
    title: "Talent signs up",
    text: "The Talent you referred signs up, creates their shop and starts making sales!",
    icon: <icons.UserCheckIcon />,
  },
  sep,
  {
    title: "Get 2% of their sales",
    text: "You’ll receive 2% of each sale they make! Don’t worry, this won’t be taken away from the Talent’s payout.",
    icon: <icons.MoneyIcon />,
  },
];

export default function HowItWorks({ matchesXsDown }) {
  return (
    <Box mt={2}>
      <Grid container>
        {steps.map((step, index) => (
          <Grid item key={index} xs={12} sm={step?.title ? true : "auto"}>
            {step?.title ? (
              <SoftIconCard
                size="small"
                style={{ height: "100%" }}
                icon={step.icon}
                title={step.title}
                iconPadding={{ x: 1, y: 1 }}
                gridSpacing={1}
              >
                {step.text}
              </SoftIconCard>
            ) : (
              <Box
                minWidth={48}
                minHeight={48}
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {matchesXsDown ? <icons.DownIcon /> : <icons.NextIcon />}
              </Box>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

HowItWorks.propTypes = {
  matchesXsDown: PropTypes.bool.isRequired,
};

import * as React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Money, CartTotals } from "../../../../domain";

const Subtotal = React.memo(function Subtotal({ totals }) {
  const subtotalPrice = React.useMemo(() => {
    if (!totals) {
      return 0;
    }

    return Money.add(
      Money.add(totals.shippingCost, totals.productBump),
      totals.subtotal,
    ).formattedPrice;
  }, [totals]);

  return <Typography variant="h6">{subtotalPrice}</Typography>;
});

Subtotal.propTypes = {
  totals: PropTypes.instanceOf(CartTotals),
};

export default Subtotal;

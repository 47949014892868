import Money from "./money";
import { identity } from "../util";
import Addon from "./addon";
import Tag from "./tag";

export default class CartProduct {
  #rawCartProduct;
  #purchaseSubscription;

  /*
   * from creates a new CartProduct from a rawCartProduct
   *
   * @param {object} rawCartProduct
   * @param {boolean} purchaseSubscription
   * @returns {CartProduct}
   */
  static from(rawCartProduct, purchaseSubscription) {
    return new CartProduct(rawCartProduct, purchaseSubscription);
  }

  /*
   * creates a new CartProduct from a rawCartProduct. NOTE: Does not copy input
   * object, since the main use case is via fromProduct, which does.
   *
   * @param {object} rawCartProduct
   * @returns {CartProduct}
   */
  constructor(rawCartProduct, purchaseSubscription = false) {
    this.#rawCartProduct = { ...rawCartProduct };
    this.#purchaseSubscription = purchaseSubscription;
    this.#rawCartProduct.tags = (this.#rawCartProduct.tags ?? []).map(Tag.from);
    this.#rawCartProduct.addons = (this.#rawCartProduct.addons ?? []).map(
      Addon.from,
    );
  }

  get price() {
    if (Number.isInteger(this.#rawCartProduct.price)) {
      return Money.for({
        value: this.#rawCartProduct.price / 100,
        currency: "usd",
      });
    }

    return Money.for(this.#rawCartProduct.price);
  }

  get productBump() {
    if (Number.isInteger(this.#rawCartProduct.productBump)) {
      return Money.for({
        value: this.#rawCartProduct.productBump / 100,
        currency: "usd",
      });
    }

    return Money.for(this.#rawCartProduct.productBump);
  }

  get subtotal() {
    const subtotal = Money.add(this.price, this.productBump);

    return Money.add(
      subtotal,
      this.addons.reduce((result, addon) => {
        if (Addon.isVipAddon(addon) && this.#purchaseSubscription) {
          return result;
        }

        return Money.add(result, addon.price);
      }, Money.ZERO_USD),
    );
  }

  getSubtotal({ includeAllAddons = false } = {}) {
    const subtotal = Money.add(this.price, this.productBump);

    return Money.add(
      subtotal,
      this.addons.reduce((result, addon) => {
        if (
          !includeAllAddons &&
          Addon.isVipAddon(addon) &&
          this.#purchaseSubscription
        ) {
          return result;
        }

        return Money.add(result, addon.price);
      }, Money.ZERO_USD),
    );
  }

  get cartProductId() {
    return this.#rawCartProduct.cartProductId;
  }

  get created() {
    if (this.#rawCartProduct.created) {
      return new Date(this.#rawCartProduct.created);
    }

    return new Date();
  }

  get productId() {
    return this.#rawCartProduct.productId;
  }

  get quantity() {
    return this.#rawCartProduct.quantity ?? 0;
  }

  get name() {
    return this.#rawCartProduct.name;
  }

  get signedType() {
    return this.#rawCartProduct.signedType;
  }

  get size() {
    return this.#rawCartProduct.size;
  }

  get imageUrl() {
    return this.#rawCartProduct.imageUrl;
  }

  get fulfillers() {
    return this.#rawCartProduct.fulfillers;
  }

  get influencerName() {
    return this.#rawCartProduct.influencerName;
  }

  get influencerRoute() {
    return this.#rawCartProduct.influencerRoute;
  }

  get influencerId() {
    return this.#rawCartProduct.influencerId;
  }

  get isInfluencerSoldOut() {
    return Boolean(this.#rawCartProduct.isInfluencerSoldOut);
  }

  get isInfluencerActive() {
    return Boolean(this.#rawCartProduct.isInfluencerActive);
  }

  get personalizationTo() {
    return this.#rawCartProduct.personalizationTo;
  }

  get personalizationAddonCharacterName() {
    return this.#rawCartProduct.personalizationAddonCharacterName;
  }

  get personalizationAddonQuote() {
    return this.#rawCartProduct.personalizationAddonQuote;
  }

  get notes() {
    return this.#rawCartProduct.notes;
  }

  get additionalShippingCost() {
    if (Number.isInteger(this.#rawCartProduct.additionalShippingCost)) {
      return Money.for({
        value: this.#rawCartProduct.additionalShippingCost / 100,
        currency: "usd",
      });
    }

    return Money.for(this.#rawCartProduct.additionalShippingCost);
  }

  get additionalInternationalShippingCost() {
    if (
      Number.isInteger(this.#rawCartProduct.additionalInternationalShippingCost)
    ) {
      return Money.for({
        value: this.#rawCartProduct.additionalInternationalShippingCost / 100,
        currency: "usd",
      });
    }

    return Money.for(this.#rawCartProduct.additionalInternationalShippingCost);
  }

  get additionalServiceFee() {
    if (Number.isInteger(this.#rawCartProduct.additionalServiceFee)) {
      return Money.for({
        value: this.#rawCartProduct.additionalServiceFee / 100,
        currency: "usd",
      });
    }

    return Money.for(this.#rawCartProduct.additionalServiceFee);
  }

  get addons() {
    return this.#rawCartProduct.addons;
  }

  setAddons(setter = identity) {
    this.#rawCartProduct.addons = setter(this.#rawCartProduct.addons);
    return this;
  }

  get subtext() {
    return this.#rawCartProduct.subtext;
  }

  get marketingTag() {
    return this.#rawCartProduct.marketingTag;
  }

  get productType() {
    return this.#rawCartProduct.productType;
  }

  get isDomesticOnly() {
    return Boolean(this.#rawCartProduct.isDomesticOnly);
  }

  get hasFreeShipping() {
    return Boolean(this.#rawCartProduct.hasFreeShipping);
  }

  get hasNoServiceFee() {
    return Boolean(this.#rawCartProduct.hasNoServiceFee);
  }

  get hasNoTax() {
    return Boolean(this.#rawCartProduct.hasNoTax);
  }

  get hasNoShipping() {
    return Boolean(this.#rawCartProduct.hasNoShipping);
  }

  get isMembersOnly() {
    return Boolean(this.#rawCartProduct.isMembersOnly);
  }

  get isPresigned() {
    return Boolean(this.#rawCartProduct.isPresigned);
  }

  get total() {
    return Money.for(this.#rawCartProduct.total);
  }

  get shippingCost() {
    return Money.for(this.#rawCartProduct.shippingCost);
  }

  get originalShippingCost() {
    if (!this.#rawCartProduct.originalShippingCost) {
      return Money.ZERO_USD;
    }

    return Money.for(this.#rawCartProduct.originalShippingCost);
  }

  get serviceFee() {
    return Money.for(this.#rawCartProduct.serviceFee);
  }

  get tax() {
    if (this.#rawCartProduct.tax) {
      return Money.for(this.#rawCartProduct.tax);
    }

    return void 0;
  }

  get raw() {
    return this.#rawCartProduct;
  }

  get tags() {
    return this.#rawCartProduct.tags ?? [];
  }

  get hasConventionTag() {
    return this.tags.length > 0 && this.tags.some(Tag.isConventionTag);
  }

  get isActive() {
    return Boolean(this.#rawCartProduct.active);
  }

  get stock() {
    return this.#rawCartProduct.stock ?? Infinity;
  }

  get shouldAllowShipmentChoice() {
    return Boolean(this.#rawCartProduct.shouldAllowShipmentChoice);
  }

  getTax({ address, noShipmentChoice }) {
    return CartProduct.getTax({ cartProduct: this, address, noShipmentChoice });
  }

  verify() {
    return CartProduct.verify({ cartProduct: this });
  }

  validateAddons({ address }) {
    return CartProduct.validateAddons({ cartProduct: this, address });
  }

  /*
   * mutate returns a new CartProduct with key set to value
   *
   * @param {string} key
   * @param {any} value
   *
   * @returns {CartProduct}
   */
  mutate(key, value) {
    const updatedRawCartProduct = { ...this.#rawCartProduct, [key]: value };
    return new CartProduct(updatedRawCartProduct);
  }

  toJSON() {
    return {
      quantity: this.quantity,
      size: this.size,
      name: this.name,
      productId: this.productId,
      price: this.price.toJSON(),
      productBump: this.productBump.toJSON(),
      imageUrl: this.imageUrl,
      // Since CartProduct is a relatively specific class, we don't need to
      // actually instantiate the fulfillers array, so this does not need us
      // to call .toJSON
      fulfillers: this.fulfillers,
      influencerName: this.influencerName,
      influencerRoute: this.influencerRoute,
      influencerId: this.influencerId,
      personalizationTo: this.personalizationTo,
      notes: this.notes,
      personalizationAddonQuote: this.personalizationAddonQuote,
      personalizationAddonCharacterName: this.personalizationAddonCharacterName,
      additionalShippingCost: this.additionalShippingCost.toJSON(),
      additionalInternationalShippingCost:
        this.additionalInternationalShippingCost.toJSON(),
      additionalServiceFee: this.additionalServiceFee.toJSON(),
      addons: this.addons,
      subtext: this.subtext,
      marketingTag: this.marketingTag,
      productType: this.productType,
      isDomesticOnly: this.isDomesticOnly,
      hasFreeShipping: this.hasFreeShipping,
      hasNoServiceFee: this.hasNoServiceFee,
      hasNoTax: this.hasNoTax,
      isPresigned: this.isPresigned,
      total: this.total.toJSON(),
      shippingCost: this.shippingCost.toJSON(),
      originalShippingCost: this.originalShippingCost.toJSON(),
      serviceFee: this.serviceFee.toJSON(),
      tax: this.tax?.toJSON(),
      hasNoShipping: this.hasNoShipping,
      isMembersOnly: this.isMembersOnly,
      tags: this.tags,
    };
  }
}

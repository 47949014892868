import React, { useRef, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useStateValue } from "../../../globalState";

import LeftArrow from "../../../assets/left-arrow.png";
import RightArrow from "../../../assets/right-arrow.png";

const Slider = React.memo(function Slider({
  title,
  row,
  isLoading,
  isEarly,
  children,
}) {
  const [{ browserWidth }] = useStateValue();

  const ShopsScrollingContainerRef = useRef(null);
  const AnimationLoop = useRef(null);

  const [scrollPositionTarget, setScrollPositionTarget] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const Scroll = useCallback(async (px) => {
    try {
      const maxScrollLeft =
        ShopsScrollingContainerRef.current.scrollWidth -
        ShopsScrollingContainerRef.current.clientWidth;
      setScrollPositionTarget((prevState) => {
        let pos = prevState;
        pos += px;
        if (pos < 0) pos = 0;
        if (pos > maxScrollLeft) pos = maxScrollLeft;
        return pos;
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const ShowHideArrows = useCallback(() => {
    if (ShopsScrollingContainerRef.current) {
      setShowLeftArrow(ShopsScrollingContainerRef.current.scrollLeft > 0);
      setShowRightArrow(
        ShopsScrollingContainerRef.current.scrollLeft <
          ShopsScrollingContainerRef.current.scrollWidth -
            ShopsScrollingContainerRef.current.clientWidth,
      );
    }
  }, []);

  const OnScroll = useCallback(() => {
    ShowHideArrows();
  }, [ShowHideArrows]);

  useEffect(() => {
    ShowHideArrows();
  }, [ShowHideArrows, browserWidth]);

  useEffect(() => {
    clearInterval(AnimationLoop.current);
    if (ShopsScrollingContainerRef.current) {
      let count = 0;
      let speed = 30;
      AnimationLoop.current = setInterval(() => {
        count += 1;
        if (count < 15) speed += 4;
        else speed -= 4;
        if (speed < 20) speed = 20;
        let stop = false;
        if (ShopsScrollingContainerRef.current) {
          if (
            ShopsScrollingContainerRef.current.scrollLeft < scrollPositionTarget
          ) {
            let potentialPos =
              ShopsScrollingContainerRef.current.scrollLeft + speed;
            if (potentialPos > scrollPositionTarget) {
              potentialPos = scrollPositionTarget;
              stop = true;
            }
            ShopsScrollingContainerRef.current.scrollLeft = potentialPos;
          } else {
            let potentialPos =
              ShopsScrollingContainerRef.current.scrollLeft - speed;
            if (potentialPos < scrollPositionTarget) {
              potentialPos = scrollPositionTarget;
              stop = true;
            }
            ShopsScrollingContainerRef.current.scrollLeft = potentialPos;
          }
        }
        if (stop) clearInterval(AnimationLoop.current);
        if (count > 100) clearInterval(AnimationLoop.current);
      }, [17]);
    }
  }, [scrollPositionTarget]);

  useEffect(() => {
    return () => {
      clearInterval(AnimationLoop.current);
    };
  }, []);

  return (
    <Container>
      <RowTitle>{title}</RowTitle>
      <ContentContainer>
        {isLoading ? (
          <React.Fragment>
            {!isEarly && (
              <LoadingBlocks>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
              </LoadingBlocks>
            )}
          </React.Fragment>
        ) : (
          <ShopsScrollingContainer
            onScroll={OnScroll}
            row={row}
            ref={ShopsScrollingContainerRef}
          >
            {children}
          </ShopsScrollingContainer>
        )}
        {row && browserWidth > 500 && (
          <OverlayContainer>
            {showLeftArrow && (
              <Arrow
                style={{ left: "-50px" }}
                onClick={() => Scroll(-(browserWidth - browserWidth / 5))}
              >
                {LeftArrowIcon}
              </Arrow>
            )}
            {showRightArrow && (
              <Arrow
                style={{ right: "-50px" }}
                onClick={() => Scroll(browserWidth - browserWidth / 5)}
              >
                {RightArrowIcon}
              </Arrow>
            )}
          </OverlayContainer>
        )}
      </ContentContainer>
    </Container>
  );
});

Slider.propTypes = {
  title: PropTypes.string,
  row: PropTypes.bool,
  isLoading: PropTypes.bool,
  isEarly: PropTypes.bool,
  children: PropTypes.node,
};

export default Slider;

const Container = styled.div`
  margin-bottom: 10px;
  background: white;
  position: relative;
`;

const RowTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  color: rgb(70, 70, 70);
  margin: 10px 20px;
  @media only screen and (max-width: 400px) {
    font-size: 24px;
  }
`;

const ShopsScrollingContainer = styled.div`
  overflow-x: auto;
  padding: 10px 0 10px;
  display: flex;
  flex-direction: row;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 10px;
  ${(props) =>
    !props.row &&
    `
        flex-wrap: wrap;
        overflow-x: reset;
    `}
`;

const LoadingBlocks = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin-left: 10px;
  div {
    flex-shrink: 0;
    height: 175px;
    width: 175px;
    background: linear-gradient(155deg, rgb(220, 220, 220), rgb(180, 180, 180));
    border-radius: 10px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    margin: 10px;
  }
`;

const ContentContainer = styled.div`
  position: relative;
`;

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  height: 100%;
`;

const Arrow = styled.div`
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(100, 100, 100);
  box-shadow: 0px 1.70339px 12.7754px rgb(0 0 0 / 20%);
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #fcfcfc;
  pointer-events: initial;
  cursor: pointer;
  margin: 10px;
  position: absolute;
  &:hover {
    background: #fcfcfc;
  }
`;

const ArrowImg = styled.img`
  width: 16px;
  height: 16px;
`;

const RightArrowIcon = <ArrowImg src={RightArrow} />;
const LeftArrowIcon = <ArrowImg src={LeftArrow} />;

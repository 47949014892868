import * as React from "react";
import Footer from "../../components/Footer";
import { Sections } from "../../components/Home";
import { useMatchesSmDown } from "../../hooks";
import "./styles.css";

export default function Home() {
  const isMobile = useMatchesSmDown();

  return (
    <>
      <Sections.Hero isMobile={isMobile} />

      <div className="home-page__sections__container">
        <Sections.Main isMobile={isMobile} />

        <Footer isHomePage isMobile={isMobile} />
      </div>
    </>
  );
}

import * as React from "react";
import { Box, Link } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export default function TermsAndConditions() {
  return (
    <Box mt={4} display="flex" width="100%" justifyContent="center">
      <StyledLink
        href="https://streamily.com/talent-terms-of-service#referral"
        target="_blank"
        rel="noreferrer"
      >
        Terms And Conditions
      </StyledLink>
    </Box>
  );
}

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontWeight: theme.typography.fontWeightMedium,
  textDecoration: "underline",
  "&:hover": {
    color: theme.palette.grey[800],
  },
}));

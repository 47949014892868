import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Collapse } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { FIRST_STEP } from "../../state";
import { ExpandButton } from "../../../../theme";
import { Money } from "../../../../domain";
import CartTotals from "../../../../domain/cartTotals";

const FeesAndTaxes = React.memo(function FeesAndTaxes({ totals, step }) {
  const [feesOpen, setFeesOpen] = React.useState(false);

  if (step === FIRST_STEP) {
    return (
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            Fees & Taxes
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="body2" color="textSecondary">
            Calculated at Next Step
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item container justifyContent="space-between" alignItems="center">
      <Grid item>
        <ExpandButton
          onClick={() => setFeesOpen((feesOpen) => !feesOpen)}
          expanded={feesOpen}
          iconPosition="end"
          style={{ padding: 0, pointerEvents: "auto", color: "grey" }}
        >
          <Typography variant="body2" color="textSecondary">
            Fees & Taxes
          </Typography>
        </ExpandButton>
      </Grid>

      <Grid item>
        <Typography variant="body2" color="textSecondary">
          {Money.add(totals.tax, totals.serviceFee).formattedPrice}
        </Typography>
      </Grid>

      <Grid item container>
        <StyledCollapse in={feesOpen}>
          <Grid item container justifyContent="space-between" xs={12}>
            <Grid item>
              <Typography variant="caption" color="textSecondary">
                Tax
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="caption" color="textSecondary">
                {totals.tax.formattedPrice}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="caption" color="textSecondary">
                Service Fees
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="caption" color="textSecondary">
                {totals.serviceFee.formattedPrice}
              </Typography>
            </Grid>
          </Grid>
        </StyledCollapse>
      </Grid>
    </Grid>
  );
});

FeesAndTaxes.propTypes = {
  totals: PropTypes.instanceOf(CartTotals),
  step: PropTypes.number,
};

const StyledCollapse = styled(Collapse)(({ theme }) => ({
  flexGrow: 1,
}));

export default FeesAndTaxes;

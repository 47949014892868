import * as React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { Influencer, Product } from "../../../domain";
import { ProductImage, ProductText, ProductButton } from "../../Product";
import InfluencerLinks from "../../InfluencerLinks";

const ProductContainer = React.memo(function ProductContainer({
  product,
  influencer,
  onOpen,
}) {
  const handleOpen = () => {
    if (product.isSoldOut) {
      return;
    }

    onOpen(product);
  };

  return (
    <Grid key={product.productId} container direction="column">
      <ProductButton onClick={handleOpen} disabled={product.isSoldOut}>
        <ProductImage product={product} isStorefront />

        <ProductText product={product} isStorefront />
      </ProductButton>

      {influencer.isGroupShop && (
        <InfluencerLinks influencers={product.fulfillers ?? [influencer]} />
      )}
    </Grid>
  );
});

ProductContainer.propTypes = {
  product: PropTypes.instanceOf(Product),
  influencer: PropTypes.instanceOf(Influencer),
  onOpen: PropTypes.func,
};

export default ProductContainer;

import PropTypes from "prop-types";
import * as React from "react";
import { Influencer, InfluencerEvent } from "../../../domain";
import {
  formatDateRangeReverseTZ,
  formatDateStrTimeReverseTZ,
} from "../../../util";

export default function UpcomingEvent({ influencer }) {
  const dateText = React.useMemo(() => {
    if (influencer.dateType === InfluencerEvent.TYPES.DAY_AND_TIME) {
      return formatDateStrTimeReverseTZ(influencer.nextEventTime);
    }

    if (influencer.dateType === InfluencerEvent.TYPES.RANGE) {
      return formatDateRangeReverseTZ(
        influencer.eventStartDate,
        influencer.eventEndDate,
      );
    }

    return null;
  }, [influencer]);

  return <span className="shop-card__event-text">{dateText}</span>;
}

UpcomingEvent.propTypes = {
  influencer: PropTypes.oneOfType([
    PropTypes.instanceOf(Influencer),
    PropTypes.object,
  ]),
};

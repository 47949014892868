import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ThemeProvider from "./theme";

// global state
import { StateProvider } from "./globalState";
import TrackingProvider from "./services/TrackingProvider";

// services
import UserAuth from "./services/UserAuth";
import Alerts from "./services/Alerts";
import BrowserWidth from "./services/BrowserWidth";
import ProcessInjection from "./services/ProcessInjection";
import Routes from "./Routes";
import GoogleAnalyticsProvider from "./services/GoogleAnalyticsProvider";
import ActiveInfluencer from "./services/ActiveInfluencer";
import NotificationProvider from "./services/Notifications";
import HelpScout from "./services/HelpScout";
// import Optimonk from "./services/Optimonk";
import NoScroll from "./services/NoScroll";
import CookieNotice from "./services/CookieNotice";
import EducationalModal from "./services/EducationalModal";
import Scripts from "./services/Scripts";

export default function App(props) {
  return (
    <HelmetProvider>
      <StateProvider>
        <ThemeProvider>
          <Scripts>
            <GoogleAnalyticsProvider>
              <TrackingProvider>
                <NotificationProvider>
                  <Router>
                    <Routes {...props} />

                    <EducationalModal />
                  </Router>

                  <ActiveInfluencer />

                  <UserAuth />

                  <BrowserWidth />

                  <Alerts />

                  <HelpScout />

                  <ProcessInjection />

                  <CookieNotice />

                  <NoScroll />
                </NotificationProvider>
              </TrackingProvider>
            </GoogleAnalyticsProvider>
          </Scripts>
        </ThemeProvider>
      </StateProvider>
    </HelmetProvider>
  );
}

import { Box, Button, Dialog, IconButton, Typography } from "@material-ui/core";
import { EditRounded, SaveRounded } from "@material-ui/icons";
import { B } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import useSWR from "swr";
import { fetcher, useCurrentUser } from "../../../hooks";
import { Form } from "../../../theme";
import { currencyFormatter } from "../../../util";
import UserSearch from "../../UserSearch";

function useShopSplit(influencerId) {
  const { data, mutate, error } = useSWR(
    `/api/payoutCut/${influencerId}`,
    fetcher,
  );

  const shopSplit = React.useMemo(() => {
    if (!data || !data.payoutCut) {
      return null;
    }

    return data.payoutCut;
  }, [data]);

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    shopSplit,
  };
}

export default function ShopSplit({ influencerId }) {
  const currentUser = useCurrentUser();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isEditing, setIsEditing] = React.useState(false);
  const { isError, isLoading, shopSplit, mutate } = useShopSplit(influencerId);
  const [email, setEmail] = React.useState(shopSplit?.email || "");
  const [confirmModal, setConfirmModal] = React.useState(null);

  const onAddUser = async (
    user,
    { force = false, reassignUserIds = [] } = {},
  ) => {
    try {
      setErrorMessage("");

      if (shopSplit && shopSplit.user_id === user.userId) {
        return;
      }

      mutate({ email: user.email });

      const response = await window.fetch(`/api/payoutCut/${influencerId}`, {
        method: "PUT",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ force, reassignUserIds, userId: user.userId }),
      });

      if (response.ok) {
        setIsEditing(false);
        return mutate();
      }

      if (response.status === 450) {
        const json = await response.json();
        const { message, stats } = json;
        return setConfirmModal({ message, stats, user });
      }

      const json = await response.json();
      const { message } = json;
      throw new Error(message);
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  const onRemoveUser = async () => {
    if (!currentUser.isSuperadmin) {
      setErrorMessage("You do not have permission to remove this user.");
      setEmail(shopSplit?.email || "");
      setIsEditing(false);
      mutate(null);
      return;
    }

    try {
      setErrorMessage("");

      mutate(null);

      const response = await window.fetch(`/api/payoutCut/${influencerId}`, {
        method: "DELETE",
        headers: { "content-type": "application/json" },
      });

      if (!response.ok) {
        const json = await response.json();
        const { message } = json;
        throw new Error(message);
      }

      mutate();
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  const onClick = () => {
    if (isEditing && email && shopSplit?.email && shopSplit?.email !== email) {
      setErrorMessage("Unable to save changes, please select a user.");
      return;
    }

    setIsEditing(B.not);
  };

  React.useEffect(() => {
    setEmail(shopSplit?.email || "");
  }, [shopSplit?.email]);

  if (isError) {
    return (
      <Typography variant="body2" color="error">
        Failed to load shop split
      </Typography>
    );
  }

  return (
    <>
      <Box
        py={2}
        display="flex"
        gridGap="8px"
        alignItems={errorMessage ? "flex-start" : "center"}
        onChange={() => {
          if (errorMessage) {
            setErrorMessage("");
          }
        }}
      >
        <Box flex="1">
          <UserSearch
            fullWidth
            onAddUser={onAddUser}
            onRemoveUser={onRemoveUser}
            user={shopSplit}
            disabled={!isEditing || isLoading}
            label="Shop Split"
            onSearchChange={(newValue) => {
              setEmail(newValue);
            }}
            error={Boolean(errorMessage)}
            helperText={
              errorMessage ? (
                <Typography
                  onClick={() => {
                    setErrorMessage("");
                  }}
                  variant="caption"
                  color="error"
                >
                  {errorMessage}
                </Typography>
              ) : null
            }
          />
        </Box>
        <IconButton title={isEditing ? "Save" : "Edit"} onClick={onClick}>
          {isEditing ? (
            <SaveRounded fontSize="small" />
          ) : (
            <EditRounded fontSize="small" />
          )}
        </IconButton>
      </Box>

      {confirmModal && (
        <ConfirmModal
          user={confirmModal.user}
          stats={confirmModal.stats}
          open={Boolean(confirmModal)}
          onClose={() => {
            setConfirmModal(null);
          }}
          onConfirm={onAddUser}
        />
      )}
    </>
  );
}

ShopSplit.propTypes = {
  influencerId: PropTypes.number.isRequired,
};

function ConfirmModal({ user, stats, open, onClose, onConfirm }) {
  const [reassignUserIds, setReassignUserIds] = React.useState([]);

  return (
    <Dialog open={open} onClose={onClose}>
      <Box p={2} display="flex" flexDirection="column" gridGap="16px">
        <Typography variant="h6">Confirm User Reassignment</Typography>

        {Object.values(stats.users).map((oldUser) => (
          <Box key={oldUser.userId}>
            <Typography gutterBottom>
              <strong>{oldUser.email}</strong> is currently owed{" "}
              <strong>{currencyFormatter.format(oldUser.amount / 100)}</strong>.
            </Typography>

            <Box display="flex" gridGap="8px" alignItems="center">
              <Form.Radio
                checked={!reassignUserIds.includes(oldUser.userId)}
                onChange={() => {
                  setReassignUserIds((prev) =>
                    prev.filter((userId) => userId !== oldUser.userId),
                  );
                }}
                id="payout-keep"
              />
              <Typography component="label" htmlFor="payout-keep">
                Keep the existing payout to the current user{" "}
                <strong>{oldUser.email}</strong>
              </Typography>
            </Box>

            <Box display="flex" gridGap="8px" alignItems="center">
              <Form.Radio
                checked={reassignUserIds.includes(oldUser.userId)}
                onChange={() => {
                  setReassignUserIds((prev) => [...prev, oldUser.userId]);
                }}
                id="payout-reassign"
              />
              <Typography component="label" htmlFor="payout-reassign">
                Reassign this payout to <strong>{user.email}</strong>
              </Typography>
            </Box>
          </Box>
        ))}

        <Box display="flex" justifyContent="flex-end" gridGap="16px" mt={2}>
          <Button onClick={onClose}>Cancel</Button>

          <Button
            style={{ minWidth: 150 }}
            variant="contained"
            color="secondary"
            onClick={() => {
              onConfirm(user, { force: true, reassignUserIds });
              onClose();
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  stats: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

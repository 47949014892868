import * as React from "react";
import PropTypes from "prop-types";
import { debounce, firstTruthy } from "../../../../util";
import { useCart } from "../../../../hooks";
import AddressForm from "../../../AddressForm";
import { useCheckoutAddress } from "../../CheckoutProvider";
import { addressUpdate } from "../../state";
import { Cart, Address } from "../../../../domain";

/*
 * ShippingAddress is a wrapper around the more general AddressForm element,
 * which provides a Google Places API enabled address form. It renders a list of
 * textfields depending on the fields provided.
 */
const ShippingAddress = React.memo(function ShippingAddress({ dispatch }) {
  const { cart, refresh } = useCart();
  const { address } = useCheckoutAddress();

  const onChange = React.useMemo(() => {
    return debounce(320, (newAddress) => {
      dispatch(addressUpdate(newAddress));

      // This is required for shipping rates to be updated
      if (cart?.address?.country !== newAddress.country) {
        Cart.updateAddress({
          country: newAddress.country,
        }).then(() => refresh());
      }
    });
  }, [refresh, cart?.address?.country, dispatch]);

  /*
   * fields must be an object with the keys of the textfields to render
   */
  const fields = React.useMemo(() => {
    if (!cart) {
      return;
    }

    const { address: lastAddress } = cart;

    const isDefaultAddress = Address.isDefaultAddress(lastAddress);

    return {
      firstName: {
        required: true,
        defaultValue:
          firstTruthy(address.firstName, lastAddress?.firstName) ?? "",
      },
      lastName: {
        required: true,
        defaultValue:
          firstTruthy(address.lastName, lastAddress?.lastName) ?? "",
      },
      country: {
        required: true,
        defaultValue:
          firstTruthy(address.country, lastAddress?.country) ?? "US",
      },
      address: {
        required: true,
        defaultValue:
          firstTruthy(
            address.address,
            !isDefaultAddress && lastAddress?.address,
          ) ?? "",
      },
      address2: {
        required: false,
        defaultValue:
          firstTruthy(
            address.address2,
            !isDefaultAddress && lastAddress?.address2,
          ) ?? "",
      },
      city: {
        required: true,
        defaultValue:
          firstTruthy(address.city, !isDefaultAddress && lastAddress?.city) ??
          "",
      },
      state: {
        required: true,
        defaultValue:
          firstTruthy(address.state, !isDefaultAddress && lastAddress?.state) ??
          "",
      },
      zipCode: {
        required: true,
        defaultValue:
          firstTruthy(
            address.zipCode,
            !isDefaultAddress && lastAddress?.zipCode,
          ) ?? "",
      },
      phoneNumber: {
        required: false,
        defaultValue:
          firstTruthy(address.phoneNumber, lastAddress?.phoneNumber) ?? "",
      },
    };
  }, [address, cart]);

  if (!cart) {
    return null;
  }

  return (
    <AddressForm title="Shipping Address" fields={fields} onChange={onChange} />
  );
});

ShippingAddress.propTypes = {
  dispatch: PropTypes.func,
};

export default ShippingAddress;

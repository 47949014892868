import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Hidden } from "@material-ui/core";
import { Logo } from "../../../../theme";
import Stepper from "../Stepper";

/*
 * Header renders the page title and current step description for the checkout
 * form (left hand side of the checkout page in desktop).
 */
const Header = React.memo(function Header({ currentStep }) {
  return (
    <Grid container spacing={4} component="header">
      <Hidden mdDown>
        <Grid item container xs={12} justifyContent="flex-start">
          <Logo height={80} width={172} />
        </Grid>
      </Hidden>

      <Grid item container xs={12}>
        <Stepper currentStep={currentStep} />
      </Grid>
    </Grid>
  );
});

Header.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

export default Header;

import * as React from "react";

const changedArray = (a = [], b = []) => {
  return (
    a.length === b.length || a.some((item, index) => !Object.is(item, b[index]))
  );
};

const initialState = { error: null };

/*
 * ErrorBoundary class is a React Component that wraps a tree of React nodes and
 * stops the entire application from crashing in event of an error.
 */
class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    return { error };
  }

  state = initialState;

  resetErrorBoundary = (...args) => {
    // eslint-disable-next-line no-unused-expressions
    this.props.onReset && this.props.onReset(...args);
    this.reset();
  };

  reset() {
    this.setState(initialState);
  }

  componentDidCatch(error, info) {
    // eslint-disable-next-line no-unused-expressions
    this.props.onError && this.props.onError(error, info);
  }

  componentDidUpdate(prevProps, prevState) {
    const { error } = this.state;
    const { resetKeys } = this.props;

    if (
      error !== null &&
      prevState.error !== null &&
      changedArray(prevProps.resetKeys, resetKeys)
    ) {
      // eslint-disable-next-line no-unused-expressions
      this.props.onResetChange &&
        this.props.onResetChange(prevProps.resetKeys, resetKeys);
      this.reset();
    }
  }

  render() {
    const { error } = this.state;
    const { fallbackRender, FallbackComponent, fallback } = this.props;

    if (error !== null) {
      const props = {
        error,
        resetErrorBoundary: this.resetErrorBoundary,
      };

      if (React.isValidElement(fallback)) {
        return fallback;
      }

      if (typeof fallbackRender === "function") {
        return fallbackRender(props);
      }

      if (FallbackComponent) {
        return <FallbackComponent {...props} />;
      }

      throw new Error(
        "Error boundary requires either a fallback, fallbackRender, or FallbackComponent prop",
      );
    }

    return this.props.children;
  }
}

/*
 * withErrorBoundary wraps a React Component with an ErrorBoundary
 *
 * @param {React.Component} Component
 * @param {object} errorBoundaryProps
 * @returns {React.Component}
 */
function withErrorBoundary(Component, errorBoundaryProps) {
  const Wrapped = (props) => {
    return (
      <ErrorBoundary {...errorBoundaryProps}>
        <Component {...props} />
      </ErrorBoundary>
    );
  };

  const name = Component.displayName || Component.name || "Unknown";
  Wrapped.displayName = `withErrorBoundary(${name})`;

  return Wrapped;
}

/*
 * useErrorHandler hook takes the result of an expression and throws if it is an
 * error
 *
 * @param {null | undefined | error}
 */
function useErrorHandler(givenError) {
  const [error, setError] = React.useState(null);
  if (givenError != null) {
    throw givenError;
  }
  if (error != null) {
    throw error;
  }
  return setError;
}

export { ErrorBoundary, withErrorBoundary, useErrorHandler };

import { Grid, Typography } from "@material-ui/core";
import * as React from "react";
import { useNotification, useReferralLink } from "../../hooks";
import { SoftLargeCard, StreamilyButton } from "../../theme";

const createLink = (code) => {
  try {
    return new URL(
      `${window.location.protocol}${window.location.host}/r/${code}`,
    );
  } catch (err) {
    console.error(err);
  }
};

export default function ReferralLink() {
  const { code } = useReferralLink();
  const notify = useNotification();

  const link = React.useMemo(() => code && createLink(code), [code]);

  const onClick = async () => {
    try {
      await navigator.clipboard.writeText(link);
      notify({
        message: `Copied ${link} to clipboard`,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <SoftLargeCard className="small" style={{ maxWidth: 700 }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs="auto">
          <Typography variant="subtitle2">Your Referral Link</Typography>
          <Typography variant="h6">{link?.toString()}</Typography>
        </Grid>

        <Grid item xs={12} sm="auto">
          <StreamilyButton
            className="blue"
            onClick={onClick}
            style={{ minWidth: "10rem" }}
            variant="contained"
          >
            Copy Link
          </StreamilyButton>
        </Grid>
      </Grid>
    </SoftLargeCard>
  );
}

import React from "react";
import PropTypes from "prop-types";
import SectionWrapper from "../commonComponent/SectionWrapper";
import { FeaturedBlock } from "../commonComponent";

const Featured = React.memo(function Featured({
  data,
  getShops,
  screenWidth,
  isLoading = true,
}) {
  return (
    <SectionWrapper
      data={data}
      screenWidth={screenWidth}
      isLoading={isLoading}
      title={`Featured`}
    >
      {(props) => <FeaturedBlock getShops={getShops} {...props} />}
    </SectionWrapper>
  );
});

Featured.propTypes = {
  data: PropTypes.array,
  getShops: PropTypes.func,
  screenWidth: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default Featured;

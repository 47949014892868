export const initialState = {
  isValid: false,
  firstName: "",
  lastName: "",
  country: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zipCode: "",
  phoneNumber: "",
  email: "",
};

export function createInitialState(fields) {
  return Object.fromEntries(
    Object.entries(initialState).reduce((result, [key]) => {
      if (key in fields) {
        result.push([key, fields[key].defaultValue ?? ""]);
      }

      return result;
    }, []),
  );
}

const SIMPLE_KEY_UPDATE = "simple";
const AGGREGATE_UPDATE = "aggregate";

export function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case SIMPLE_KEY_UPDATE:
      return { ...state, [payload.key]: payload.value };

    case AGGREGATE_UPDATE:
      return { ...state, ...payload };

    default:
      return state;
  }
}

export function simpleUpdate({ key, value }) {
  return { type: SIMPLE_KEY_UPDATE, payload: { key, value } };
}

export function aggregateUpdate(payload) {
  return { type: AGGREGATE_UPDATE, payload };
}

import React from "react";
import styled from "styled-components";
import Hero from "./Hero";

const Banner = ({ isHomePage = false }) => {
  return (
    <>
      <BannerText>FREE Shipping on your first order as a member!</BannerText>
      {isHomePage ? (
        <Hero />
      ) : (
        <Title className="browse-title browse-container">
          Livestreamed Autographs from Their Home to Yours
        </Title>
      )}
    </>
  );
};

export default Banner;

const Title = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #3280dc;
  font-weight: 600;
  background: linear-gradient(270.37deg, #3280dc 36.59%, #345781 99.83%),
    #3b3b3b;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const BannerText = styled.div`
  display: flex;
  justify-content: center;
  background: #3a3a3a;
  color: white;
  font: 11px / 1.5 "Inter";
  font-weight: 600;
  padding: 2px 0;
`;
